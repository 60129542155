/* ---------------------------priti sign up page------------ */
/* .headerblock{margin-top: 47px;margin-bottom: 35px;} */

.headerblock{margin-top: 26px;margin-bottom:26px;}
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@100;300;400;500;700;900&display=swap');


.pece_mkbannerblock{margin-bottom: 40px;}
 .signup_main{background-image: url("https://d37pbainmkhx6o.cloudfront.net/pecemarketinglandingpage/assets/images/pecemarketing_mainbgnew1updateed.webp");background-repeat: no-repeat;background-size: 100% 100%;overflow: hidden;}
.getblackbtnwrp button a:link{color: white;text-decoration: none;}
.getblackbtnwrp button a:active {color: white;text-decoration: none;}
.getblackbtnwrp button a:visited {color: white;text-decoration: none;}
.mknewcontainer{width: 1747px;margin: 0px auto;display: block;}
.pece_mkbannerblock .contentwrapper{display: flex;justify-content: space-between;align-items: stretch;flex-wrap: wrap;}
.pece_mkbannerblock .leftwrp_content{width: 74%;display: flex;justify-content: space-between;align-items: stretch;flex-wrap: wrap;}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection{width: 38%;position: relative;}
.pece_mkbannerblock .tagimg1wrp{position: absolute;right: 16%;top: -3px;z-index: 9;}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper{
  width: 340px;
  border: 1px solid #323f4a;border-radius: 45px;
  padding: 30px 0 30px 30px;
  position: relative;margin-bottom: 28px;
}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  background: rgb(254,243,208);
  background: linear-gradient(180deg, rgba(254,243,208,1) 20%, rgba(194,137,61,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  text-transform: uppercase;
  width:auto; 
  /* padding-right: 20%; */
  font-family: 'Roboto', sans-serif;
  width: 340px;
}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper{
  background-image: url("https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline1.webp"),
  url("https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline2.webp"),
  url("https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline3.webp"),
  url("https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline4.webp"),
  url("https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline5.webp");
  background-color: #021322;
  background-repeat: no-repeat;
  background-position: left bottom,center left, left top, left top, right top;
  background-size: auto,17px 100%,auto auto,180px 16px,auto auto;
}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext{background: rgb(32,164,228);background: linear-gradient(180deg, rgba(32,164,228,1) 0%, rgba(11,63,114,1) 100%);padding: 20px;border-radius: 40px;border: 1px solid #fff;margin-bottom: 60px;position: relative;z-index:9;}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtextmain{position: relative;}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtextmain::after{
  content: '';background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_bannershadow2.webp");background-repeat: no-repeat;
  bottom: -84px;
  left: 27px;
  width: 310px;
  height: 119px;
  position: absolute;
  background-position: left bottom;
}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtextmain::before{
  content: '';background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_bannershadow1.webp");background-repeat: no-repeat;
  top: 13px;
  right: -72px;
  width: 77px;
  height: 180px;
  position: absolute;
  background-position: right top;
}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h2{
    color: #fff;
    font-size: 42px;
    line-height: 45px;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
    text-shadow: 0px 0px 10px #605f5f;
}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h3{
  color: #000;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin: 0;
    background-color: #ffe613;
    border-radius: 20px;
    padding: 11px;
    box-shadow: 0 0 18px 0px #1e1b1b;
    font-family: 'Roboto', 'sans-serif', 'Montserrat';
    position: relative;
    z-index: 9;
}
.pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1{margin-bottom: 20px;}
.pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 h1{
  background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 1%, rgba(154,168,174,1) 100%);
    /* background: rgb(110 105 105);
    background: linear-gradient(180deg, rgb(141 134 134) 1%, rgb(92 100 104) 100%); */
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  font-size: 27px;
  line-height: 32px;
  font-weight: 800;
  margin: 0;
  font-family: 'Roboto', 'sans-serif', 'Montserrat';
}
.pece_mkbannerblock .leftwrp_content .contenttextwrapper h2{color: #ffe613;font-size: 22px;  line-height: 30px;margin: 0;font-weight: 500;font-family: 'Roboto', 'sans-serif', 'Montserrat';}
.pece_mkbannerblock  .mk_innerrightsection .screensortimgwrp{background: #182e3e;padding: 30px;border-radius: 50px;
  background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signuppage_lineborder1_new.webp");
  background-repeat: no-repeat;background-size: 100% 100%;position: relative;z-index: 9;
}
.pece_mkbannerblock  .mk_innerrightsection .screensortimgwrp img{display: block;width: 100%;border-radius: 30px;}
.pece_mkbannerblock .mk_innerrightsection .screensortimgwrp::before{content: '';background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signuppage_silvertopborder1.webp");
  background-repeat: no-repeat;width: 100%;height: 108px;position: absolute;top: 0;left: 0px;background-size: 100% auto;}
.pece_mkbannerblock .mk_innerrightsection .screensortimgwrp::after{content: '';background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signuppage_silverbottomborder2.webp");background-repeat: no-repeat;width: 100%;height: 122px;position: absolute;bottom: 0;left: 0px;background-size: 100% auto;}
.pece_mkbannerblock .rightwrp_forsection{width: 25%;}

.pece_mkbannerblock .rightwrp_forsection .signupform{position: relative;background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signuppage_lineborder1_new.webp");background-repeat: no-repeat;background-size: 100% 100%;}
.pece_mkbannerblock .rightwrp_forsection .signupform::before{content: '';background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signuppage_silvertopborder1.webp");background-repeat: no-repeat;width: 100%;height: 108px;position: absolute;top: 0;left: 0px;background-size: 100% auto;}
.pece_mkbannerblock .rightwrp_forsection .signupform::after{content: '';background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signuppage_silverbottomborder2.webp");background-repeat: no-repeat;width: 100%;height: 122px;position: absolute;bottom: -52px;left: 0px;background-size: 100% auto;}
.pece_mkbannerblock .rightwrp_forsection  .signupform{background-color: #090a0b;color: #76838c;border-radius: 25px;padding: 5px;}
.pece_mkbannerblock .rightwrp_forsection .signupform  .titlewrapper{border: 1px solid #6b757c;display: flex;justify-content: center;text-align: center;background-image: linear-gradient(#495968, #1e2e37);
  /* border-top-left-radius: 25px;border-top-right-radius: 25px; */
  border-top-left-radius: 18px;border-top-right-radius: 18px;

  margin: 3px;
  margin-bottom: 0;
  position: relative;color: #ffff;z-index: 1;}
.pece_mkbannerblock .rightwrp_forsection .signupform  .titlewrapper h1{text-align: center;margin: 0;padding: 16px 5px;font-size: 26px; line-height: 28px;}
.pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper h1 span{

  /* text-shadow: 0px 0px 3px #1e9ad9;
  background: -webkit-linear-gradient(#03a7f2, #0593d4);-webkit-background-clip: text;-webkit-text-fill-color: transparent; */
  background: -webkit-linear-gradient(#7dbddb, #0593d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* filter: drop-shadow(0 0 0.75rem crimson); */
  filter: drop-shadow(0px 3px 7px #000);
  display: block; margin-top: 4px;



}
.pece_mkbannerblock .rightwrp_forsection .signupform .form_grp{
    display: flex;
    flex-direction: column;
    padding: 0 15px 30px 15px;
    text-align: center;
    border: 1px solid #6b757c;
    border-top: none;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top: none;
    width: 93%;
    margin: 0 auto;
    position: relative;
}
.pece_mkbannerblock .rightwrp_forsection  .signupform .form_grp h2{
  font-size: 57px;line-height: 50px;
  margin-top: 10px;
  margin-bottom: -4px;
  /* margin: 18px 0px; */
  padding: 0px;
  background: -webkit-linear-gradient(#fcd962, #edb414);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Roboto', 'sans-serif', 'Montserrat';
}
.signup_main_DarkTheme .rightwrp_formsection .form_grp h2{margin-top: 30px !important;margin-bottom: 10px !important;}
.pece_mkbannerblock .rightwrp_forsection .form_grp  .MuiInputBase-formControl{
  background-color: #20252a;
  /* margin: 8px 0 0 0;
    height: 44px;   */

  color: white;
  border-radius: 0px !important;
  border: 1px solid #4b5156;
  margin: 13px 0 0 0;
  height: 48px;   
  position: relative;
}
.signup_main_DarkTheme .rightwrp_forsection .form_grp .MuiInputBase-formControl{margin: 19px 0 0 0;}

/* .pece_mkbannerblock .rightwrp_forsection .form_grp .MuiInputBase-formControl .css-1wc848c-MuiFormHelperText-root.Mui-error{color: #d32f2f;position: absolute;bottom: 0;} */


.pece_mkbannerblock .rightwrp_forsection .form_grp .termsncondition{margin: 15px  0;text-align: left;}
.pece_mkbannerblock .rightwrp_forsection .form_grp .MuiCheckbox-colorPrimary .PrivateSwitchBase-input .MuiSvgIcon-fontSizeMedium path{color: #5a6b78;}
.pece_mkbannerblock .rightwrp_forsection .form_grp .form_btn{
  background-image: linear-gradient(#0170b0, #002c4c);
  text-transform: uppercase;
  padding: 0 !important;
  color: #fff;
  font-size: 36px;
  border-radius: 35px;
  width: 223px;
  /* height: 68px; */
  height: 66px;
  border: 2px solid #0a3b59;
  margin: 0 auto;
  font-weight: 700;
  box-shadow: none;
  cursor: pointer;
}
.pece_mkbannerblock .rightwrp_forsection .form_grp .MuiInputLabel-formControl{color:#fff;top: 8px;}
/* .pece_mkbannerblock .rightwrp_forsection .form_grp .buttonSection{margin: 10px auto;position: relative;} */
.pece_mkbannerblock .rightwrp_forsection .form_grp .buttonSection{margin: 0px auto;margin-top: 20px;position: relative; cursor: pointer;z-index: 9;}

.pece_mkbannerblock .rightwrp_forsection .form_grp .buttonSection .MuiTouchRipple-root{cursor: pointer;}

.css-j204z7-MuiFormControlLabel-root{  vertical-align: inherit !important;}
.MuiFormControlLabel-labelPlacementEnd{display: flex !important;align-items: flex-start !important;justify-content: space-between !important;}
/* .pece_mkbannerblock .rightwrp_formsection .css-1u3bzj6-MuiFormControl-root-MuiTextField-root,.css-16awh2u-MuiAutocomplete-root{margin-bottom: 8px;} */
.pece_mkbannerblock .rightwrp_forsection .signupform{ position: relative; }
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{  display: block;  color: #76838c !important;}

.signup_bannerarrow{
  display: block;
  background-image: url("https://d37pbainmkhx6o.cloudfront.net/pecemarketinglandingpage/assets/images/pecesignuppage_bannerarrow1.webp");
  background-repeat: no-repeat;
  background-position: right -22px center;
  color: #fff;
  font-weight: bold;
  padding: 90px 30px 90px 20px;
  position: relative;
  margin-top: -30px;
  z-index: 9;
  width: 100%;
}
.landingnew_wrpr .signup_bannerarrow{margin-top: -40px;}
.signup_bannerarrow h2{  
  background: -webkit-linear-gradient(#ffffff 0,#ffffff 56%,#d7d7d7 56%,#d7d7d7 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  position: relative; 
  z-index: 2;
  position: relative;
  margin: 0;
  letter-spacing: -1px;
  white-space: normal; font-size: 50px;
  line-height: 62px;
  font-family: 'Roboto';
  font-weight: 900;
  filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.3));
}
.pece_mkbannerblock .signup_bannerarrow:after{content: '';background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/pecesignuppage_arrowshadowimg1.png");background-repeat: no-repeat;background-position: left top;width: 245px;height: 87px;position: absolute;left: 0px;top: -17px;}
.pece_mkbannerblock .pecemk_bannerpara{width: 1069px;}
.pece_mkbannerblock .pecemk_bannerpara p{font-size: 22px;line-height: 30px;  color: #b9c5c6;margin: 0;font-weight: 500;margin-top: -40px;}
.pece_mkbannerblock .pecemk_bannerpara p .brtext span{color: #ffe613;}
.pece_mkbannerblock .leftwrp_content .pecemk_bannerpara p .brtext{max-width: 100%;display: block;}
.pece_mkbannerblock .pece_mkbannergirlimg{position: absolute;right: -46px;top: 33px;}
.pece_mkbannerblock .rightwrp_formsection .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{padding: 0 9px;}
.pece_mkbannerblock .rightwrp_formsection .css-vubbuv{fill: #5b6b78;}
.pece_mkbannerblock .rightwrp_forsection .signupform .form_grp .MuiFormControl-root label .MuiTypography-root{margin: 0;font-family: "Roboto","Helvetica","Arial",sans-serif;font-weight: 400;font-size: 15px;line-height: 20px;}

/* .pece_mkbannerblock .rightwrp_formsection .css-i44wyl, .pece_mkbannerblock .rightwrp_formsection .css-feqhe6{margin-bottom: 8px !important;} */
/* .signup2_main .rightwrp_formsection .MuiTextField-root{margin-bottom: 0 !important;} */
.pece_mkbannerblock .rightwrp_forsection .form_grp .MuiInputBase-formControl:nth-child(1){margin-top: 0;}
.pece_mkbannerblock .rightwrp_formsection .css-v7esy.Mui-error{position: absolute !important;bottom: 0 !important;}
/* .pece_mkbannerblock .rightwrp_forsection .form_grp .MuiInputLabel-formControl,
 .pece_mkbannerblock .rightwrp_formsection .css-i44wyl label, .pece_mkbannerblock .rightwrp_forsection .form_grp .MuiInputLabel-formControl .css-p0rm37 {  top: 3px; } */

 .signup2_main .rightwrp_formsection .form_grp .gender_dropdown .MuiFormHelperText-root{margin-bottom: 0 !important;margin-top: 0 !important;}
/* ---------------------block1----------- */
/* https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/signuppage_block1_mainbg.png */
.signupblockone{background-image: url("https://d37pbainmkhx6o.cloudfront.net/pecemarketinglandingpage/assets/images/signuppage_block1_mainbg.webp");background-repeat: no-repeat;background-position: left 18% top;position: relative;}
.subwrapper1{position: relative;display: block;padding-left: 18%;padding-bottom: 30px;}
.subtitlemainheader{
  background: rgb(116,130,137);
  background: linear-gradient(180deg, rgba(116,130,137,1) 0%, rgba(35,40,43,1) 100%);
  padding: 20px 16px 20px 30px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin-bottom: 30px !important;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 50%) inset;
    -webkit-box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 50%) inset;
    -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5) inset;
    z-index: 99;
    display: block;
    border: 1px solid #fff;
}
.subtitlemainheader h3 {
  color: inherit;
  text-transform: initial;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 1%, rgba(155,177,187,1) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  font-size: 50px;
  line-height: 50px;
  margin: 0;
  font-family: 'Roboto', 'sans-serif', 'Montserrat';
  text-transform: uppercase;
  filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.3));
}
.signupblockone .signup_blk1leftimg{position: absolute;top: -22px;left: -55px;}
.listwrapper{margin-left: 34%;background-color: #d9e2e7;padding: 9px;border-radius: 8px;position: relative;z-index: 9;}
.listwrapper::after{content: '';position: absolute;left: -2px;top: -49px;background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signuppage_blk1rightbox_leftshadowimg1.webp");width: 182px;height: 50px;background-repeat: no-repeat;}
.listwrapper::before{content: '';position:absolute;right: 8px;top: -51px;background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signuppage_blk1rightbox_rightshadowimg1.webp");width: 182px;height: 50px;background-repeat: no-repeat;}
.listsubwrapperbg{background: rgb(1,138,251);background: linear-gradient(90deg, rgba(1,138,251,1) 0%, rgba(56,202,250,1) 100%);border-radius: 8px;}
.listsubwrapper{display: block;column-count: 2;padding: 20px 25px;}
.listwrapper p{font-size: 20px;font-family: 'Roboto', 'sans-serif', 'Montserrat';color: #fff;padding: 7px 0;padding-left: 44px;background-image: url("https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signuppage_bulletpoint.webp");background-repeat: no-repeat;background-position: left 3px;margin: 0;font-weight: 500;text-shadow: 0 0 5px rgb(0 0 0 / 40%);}
.signupblockone .listing_righttext{margin-left: -30%;}
.smrighttxt{font-size: 16px;line-height: normal;}
.blk1bottomtxt{background-color: #303538;  padding: 20px;border-radius: 0 0 8px 8px;display: flex;justify-content: space-between;align-items: center;}
.blk1bottomtxt h1{background: rgb(255,255,255);background: linear-gradient(180deg, rgba(255,255,255,1) 1%, rgba(155,177,187,1) 100%);-webkit-text-fill-color: transparent;-webkit-background-clip: text; -moz-background-clip: text;background-clip: text;
  font-size: 38px;line-height: 38px;margin: 0;font-family: 'Roboto', 'sans-serif', 'Montserrat';filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.3));text-align: left;}


.blk1bottomtxt h1 span{background: #eaff00; -webkit-text-fill-color: transparent;-webkit-background-clip: text;}


/* -------------------------BLOCK2------------------ */

.signupblocktwo {position: relative;}
.signupblocktwo .subtitlemainheader  {margin-right: 9%;}
.signupblocktwo .subwrapper1{padding-left:10px;}
.signupblocktwo .signup_blk1leftimg {position: absolute;top: 110px;right:-62px;}
.signupblocktwo .listwrapper{margin-left: 0;margin-right: 28%;margin-bottom: 30px;}
.signupblocktwo .listsubwrapper{column-count: 3;display: block;}
.signupblocktwo .listsubwrapperbg{box-shadow: inset 0 0 10px rgb(0 0 0 / 30%);position: relative;z-index: 9;}
.signupblocktwo .listsubwrapperbg{background: rgb(1,138,251);background: linear-gradient(90deg, rgba(1,138,251,1) 0%, rgba(3,169,245,1) 100%);}
.signupblocktwo .contentimgblkwrpmain{position: relative;}
/* .signupblocktwo .block2_docleftimg{position: absolute;left: -54px;top: -20px;} */
.signupblocktwo .block2_docleftimg{position: absolute;left: -54px;top: -47px;}

.contentimgblkwrp{width: 1062px;display: block;position: relative;z-index: 9;}
.contentimgblkwrp {width: 100%;display: block;position: relative;z-index: 9;margin-left: 31%;}
.lgtblock2contentwhbg{background-color: #fff;padding: 10px;border-radius: 8px;width: 1210px;}
.lgtblock2contentgrybg{background: rgb(47,60,76);background: linear-gradient(90deg, rgba(47,60,76,1) 11%, rgba(19,30,48,1) 100%);border-radius: 8px;}
.signupblocktwo .lgtblock2contentgrybg h1 {margin: 0;color: #e8e9e9;font-family: 'Roboto', 'sans-serif', 'Montserrat';font-weight: 400;padding: 20px 20px 22px 38px;font-size: 30px;line-height: 36px;}
.signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{margin: 0;font-size: 50px;line-height: 50px;padding: 20px 10px 20px 30px;color: #272d30;display: inline-block;text-shadow: 0 0 3px #91f837;width: 100%;font-family: 'Roboto', 'sans-serif', 'Montserrat';}
.signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper .blk2greenbgmainwrp {background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/signuppage_block2_yellowimg.png),url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/signuppage_block2yellowbgimgmain.png);background-repeat: no-repeat;background-position: right top,left top;background-size: auto 100%,91% 100%;width: 90%;border-radius: 0 0 0 8px;}
.signupblocktwo .lgtblock2contentgrybg h1 span a{color: #feed01;cursor: pointer;text-decoration: none;}
.signupblocktwo .lgtblk2flexwrapper .pointerimgwrp {position: absolute;right: 30px;top: -25px;cursor: pointer;}
.lgtblk2flexwrapper{display: flex;justify-content: space-between;position: relative; }

/* -------------------------BLOCK3------------------ */

.signupblockthree{margin-bottom: 30px;position: relative;}
.signupblockthree .subwrapper1{padding-left: 0;padding-bottom: 0;}
.signupblockthree .subtitlemainheader{padding: 25px 16px 30px 16px;}
.signupblockthree .subtitlemainheader h3{font-size: 37px;line-height: 40px;font-weight: 600;}
.blk3humanbodymainbg{background-image: url("https://d37pbainmkhx6o.cloudfront.net/pecemarketinglandingpage/assets/images/signuppage_block3_bg.webp");z-index: 9;background-size: cover; background-position: center top; border-radius: 10px;border: 1px solid #666767;padding-top: 60px;}
.inner_blk3bg{  width: 1562px;margin: 0px auto;display: block;}
.nmsfunneldoctor_block3{position: relative;margin-bottom: 0;}
.nmsfunneldoctor_block3 .nmsfunneldoctor_block_wrapper{padding: 1px 20px;border-radius: 10px;}
.nmsfunneldoctor_block3 ol {
  -moz-column-count: 2;
  column-count: 2;
  -moz-column-gap: 40px;
  column-gap: 40px;
  list-style: decimal!important;
  counter-reset: item;
  background: url(https://d37pbainmkhx6o.cloudfront.net/pecemarketinglandingpage/assets/images/block3imgmiddle_new.webp) top/contain no-repeat;
  
  padding-left: 0;
  margin-bottom: 0;
}
.libase {position: relative;}
.nmsfunneldoctor_block3 ol li {
  position: relative;display: block;min-height: 130px;padding: 26px 0 58px 0;
  overflow: hidden;
  clear: both;
}
.nmsfunneldoctor_block3 ol li:before {
  content: counters(item, '.') " ";
  counter-increment: item;
  font-size: 50px;
  position: absolute;
  /* left: -15px; */
  /* left: 14px; */
  left: 0px;
  top:0px;
  z-index: 9;
  color: #932b99;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  height: 64px;
  width: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 7px 5px 10px rgb(0 0 0 / 30%);
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/tsicon1image.webp);
}
.listwarppercls {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(231,231,231,1) 99%); 
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  width: 415px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  min-height: 100px;
  box-shadow: inset 0 0 10px #00000070;
}
.listwarppercls h4 {
  padding: 0 0 0 22px;margin: 0;color: #000000;font-family: 'Roboto', sans-serif;font-weight: 500;font-size: 22px;line-height: 25px;text-align: left;width: 57%;}
.li1 .imgwrapperblock3, .li2 .imgwrapperblock3, .li3 .imgwrapperblock3, .li4 .imgwrapperblock3 {position: absolute;top: 58%;right: -80px;transform: translateY(-50%);}
.li1:after {content: "";position: absolute;right: 13px;top: 42%;background-image: url(https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_linedesign1.webp);height: 100px;width: 300px;transform: rotate(4deg);background-repeat: no-repeat;}
.li2:after {content: '';background-image: url(https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_linedesign2.webp);background-repeat: no-repeat;position: absolute;right: 16px;top: 26%;height: 100px;width: 300px;transform: rotate(-5deg);}
.li3:after {content: "";background-image: url(https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_linedesign3.webp);background-repeat: no-repeat;position: absolute;right: 12px;top: -15%;height: 130px;width: 300px;transform: rotate(-2deg);}
.li4:after {content: "";background-image: url(https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_linedesign4.webp);background-repeat: no-repeat;position: absolute;right: 72px;top: 21%;height: 60px;width: 300px;transform: rotate(-2deg);}
.li5:after {content: "";background-image: url(https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_linedesign5.webp);background-repeat: no-repeat;height: 60px;width: 226px;position: absolute;left: 29px;top: 39%;transform: rotate(0);}
.li6:after {content: "";background-image: url(https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_linedesign6.webp);background-repeat: no-repeat;height: 100px;width: 264px;position: absolute;left: 0px;top: 2%;transform: rotate(0);}
.li7:after {content: "";background-image: url(https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_linedesign7.webp);background-repeat: no-repeat;height: 22px;width: 237px;position: absolute;left: 24px;top: 36%;transform: rotate(0);}
.li8:after {content: "";background-image: url(https://all-frontend-assets.s3.amazonaws.com/Pece+marketing+landing/assets/images/signup_linedesign8.webp);background-repeat: no-repeat;height: 100px;width: 264px;position: absolute;left: 27px;top: 10%;transform: rotate(0);}
.nmsfunneldoctor_block3 ol li:first-child:last-child,
.nmsfunneldoctor_block3  ol li:nth-child(n+10):nth-last-child(-n+10), 
.nmsfunneldoctor_block3 ol li:nth-child(n+2):nth-last-child(-n+2), .nmsfunneldoctor_block3 ol li:nth-child(n+3):nth-last-child(-n+3),
.nmsfunneldoctor_block3 ol li:nth-child(n+4):nth-last-child(-n+4), .nmsfunneldoctor_block3 ol li:nth-child(n+5):nth-last-child(-n+5), 
.nmsfunneldoctor_block3 ol li:nth-child(n+6):nth-last-child(-n+6), .nmsfunneldoctor_block3 ol li:nth-child(n+7):nth-last-child(-n+7), 
.nmsfunneldoctor_block3 ol li:nth-child(n+8):nth-last-child(-n+8), .nmsfunneldoctor_block3 ol li:nth-child(n+9):nth-last-child(-n+9) {direction: rtl;}

.li5 .imgwrapperblock3, .li6 .imgwrapperblock3, .li7 .imgwrapperblock3, .li8 .imgwrapperblock3{position: relative;}
.nmsfunneldoctor_block3 ol li:first-child:last-child:before, .nmsfunneldoctor_block3 ol li:nth-child(n+10):nth-last-child(-n+10):before,
.nmsfunneldoctor_block3 ol li:nth-child(n+2):nth-last-child(-n+2):before, .nmsfunneldoctor_block3 ol li:nth-child(n+3):nth-last-child(-n+3):before, 
.nmsfunneldoctor_block3 ol li:nth-child(n+4):nth-last-child(-n+4):before, .nmsfunneldoctor_block3 ol li:nth-child(n+5):nth-last-child(-n+5):before,
.nmsfunneldoctor_block3 ol li:nth-child(n+6):nth-last-child(-n+6):before, .nmsfunneldoctor_block3 ol li:nth-child(n+7):nth-last-child(-n+7):before,
.nmsfunneldoctor_block3 ol li:nth-child(n+8):nth-last-child(-n+8):before, .nmsfunneldoctor_block3 ol li:nth-child(n+9):nth-last-child(-n+9):before {
  font-size: 50px;position: absolute;right: 0px;top:0px;z-index: 9;left: inherit;box-shadow: 2px 5px 10px rgb(0 0 0 / 30%);
}

.imgwrapperblock3 img {max-width: 100%;-o-object-fit: cover;object-fit: cover;min-height: 100%;display: block;width: 100%;}
/* .li5 .imgwrapperblock3, .li6 .imgwrapperblock3, .li7 .imgwrapperblock3, .li8 .imgwrapperblock3{  position: relative;} */
.li5 .imgwrapperblock3, .li6 .imgwrapperblock3, .li7 .imgwrapperblock3, .li8 .imgwrapperblock3 {position: absolute;top: 58%;left: -80px;transform: translateY(-50%);}
.nmsfunneldoctor_block3 ol li:nth-child(2)::before{color: #69188e;}
.nmsfunneldoctor_block3 ol li:nth-child(3)::before{color: #532794;}
.nmsfunneldoctor_block3 ol li:nth-child(4)::before{color: #4b1e91;}
.nmsfunneldoctor_block3 ol li:nth-child(5)::before{color: #f63145;}
.nmsfunneldoctor_block3 ol li:nth-child(6)::before{color: #ff5a1b;}
.nmsfunneldoctor_block3 ol li:nth-child(7)::before{color: #fe9303;}
.nmsfunneldoctor_block3 ol li:nth-child(8)::before{color: #f9a112;}
.signupblockthree .getblackbtnwrp{margin: 0 40px 40px 0;}
.signupblockthree .getblackbtnwrp button{cursor: pointer;border: 1px solid #f8d512;}
/* -------------------------BLOCK4------------------ */
.signupblockfour .subtitlemainheader h3{font-size: 37px;line-height: 46px;}
.signupblockfour .subtitlemainheader{margin-bottom: 0 !important;}
.signupblockfour .subtitlemainheader{margin-bottom: 30px !important;}
.signupblockfour{position: relative;}
.signupblockfour .blk4content1main .content1 img {position: relative;z-index:9;}
.signupblockfour .innercontentwrpmain .righttimg1{position: absolute;right: -98px;top:-94px;}
.blk4content1main{  margin-bottom: 30px;position: relative;}
.blk4content1main2{margin-bottom: 30px;}
.signupblockfour .blk4content1main2{margin-left: 28%;}
.blk4content1main3 {margin-bottom: 30px;}
.blk4content1main4 {margin-bottom: 30px;position: relative;}
.blk4content1main4 .content1{margin-left: 28%;}
.blk4content1main3 .content1 {  margin-right: 28%;}
.blk4content1main3{position: relative;}
.blk4content1main4 .blk4content1main4{position: relative;}
.signupblockfour .innercontentwrpmain .blk4content1main4 .righttimg1{position: absolute;left:-97px;bottom: -143px;right: inherit;top: inherit;}
.signupblockfour .blk4content1main3 .content1 img{position: relative;z-index: 9;}
.signupblockfour .blk4content1main4 .content1 img{position: relative;z-index: 9;}
.signupblockfour .innercontentwrpmain .blk4content1main3 .righttimg1 {position: absolute;right: -116px;bottom: -35px;top: inherit;}
.signupblockfour .blk4content1main2{position: relative;}
.signupblockfour .blk4content1main2 .content1  {position: relative;z-index: 9;}
.signupblockfour .innercontentwrpmain .blk4content1main2 .righttimg1{position: absolute;left: -47%;bottom: -217px;right: inherit;top: inherit;}
.copyrighttext{padding: 30px 0;padding-top:15px;}
.copyrighttext p{ color: #97a3aa;text-align: center;font-family: 'Roboto', 'sans-serif', 'Montserrat';font-size: 16px;line-height: 18px;font-weight: 500;margin:0}
.headerblock img{max-width: 100%;}
.signupblocktwo .contentimgblkwrp img {  max-width: 100%;}
.contentimgblkwrp{margin: 0 0 0 auto;width: 100%;display: block;position: relative;z-index: 9;margin-left: 29%;}
.signupblockthree img{max-width: 100%;}
.signupblockfour .blk4content1main .content1 img,.signupblockfour .blk4content1main2 .content1,
.signupblockfour .blk4content1main3 .content1 img,.signupblockfour .blk4content1main4 .content1 img{max-width: 100%;}
.footersectionwrapper img{max-width: 100%;z-index: 9;position: relative;}

/* ---------------block4-textblock1---------- */
.signupblockfour .blk4content1main .content1{  margin-right: 28%;}
.signupblockfour .blk4content1main2 {position: relative;}
.signupblockfour .content1mainblock{background: rgb(200,212,218);background: linear-gradient(180deg, rgba(200,212,218,1) 0%, rgba(217,226,231,1) 99%);padding: 12px;border: 1px solid #fff;border-radius: 8px;z-index: 9;position: relative;}
.signupblockfour .contentblkblue{background: rgb(7,141,251);background: linear-gradient(90deg, rgba(7,141,251,1) 61%, rgba(3,169,245,1) 99%);padding: 20px;box-shadow: inset 0 0 10px rgb(0 0 0 / 30%);border-radius: 8px;}
.signupblockfour .innercontentwrpmain .contentblkblue .yellowbar h1{text-shadow: 0 1px 1px #ffffff;font-size: 32px;line-height: 32px;font-weight: 600;font-family: 'Roboto', 'sans-serif', 'Montserrat';margin: 0;}
.signupblockfour .contentblkblue p{font-size: 22px;line-height: 26px;color: #fff;font-weight: 500;font-family: 'Roboto', 'sans-serif', 'Montserrat';margin: 0;margin-top: 16px;margin-bottom: 20px;}
.getblackbtnwrp button{background: rgb(58,59,59);background: linear-gradient(180deg, rgba(58,59,59,1) 0%, rgba(6,17,22,1) 99%);margin: 0 0 0 auto;display: block;color: #fff;padding: 10px 30px;border-radius: 50px;border: 1px solid #66c5f5;text-transform: uppercase;font-size: 24px;font-family: 'Roboto', 'sans-serif', 'Montserrat';font-weight: 500;}
.signupblockfour .innercontentwrpmain .contentblkblue .textwrappermain .yellowbar{background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/lighttheme_titlebarlineimg1.png);background-repeat: no-repeat;background-position: right bottom;padding: 9px 10px 10px 16px;background-color: #ffe613;color: #000;}
.signupblockfour .innercontentwrpmain .contentblkblue .textwrappermain:nth-child(2) .yellowbar{background-position: right 190px bottom;}
.signupblockfour .blk4content1main2 .contentblkblue .textwrappermain .yellowbar{background-position: right 140px bottom;}
/* .mobileviewlist {display: none;} */

/* ------------------------------------------RESPONSIVE  START------------------------------- */

@media  only screen and (max-width: 1840px){
  .content1 img{max-width: 100%;}
  .mknewcontainer{width: 96%;}
}
@media only screen and (max-width: 1820px){
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 h1{font-size: 26px;}
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 45px;}
  .subtitlemainheader h3{font-size: 48px;}
  .lgtblock2contentwhbg{width: auto;}
  .contentimgblkwrp{margin-left: 26%;}
  .signupblocktwo .lgtblock2contentgrybg h1 br{display: none;}
  .contentimgblkwrp{width: auto;}
}
@media only screen and (max-width: 1800px){
  .pece_mkbannerblock .tagimg1wrp img{display: block;
  width: 66%;
}
.pece_mkbannerblock .tagimg1wrp{
  right: 14%;
  top: -31px;
}
}
@media only screen and (max-width: 1780px){
  .contentimgblkwrp{margin-left: 28%;width:auto;}
  .signupblockthree .subtitlemainheader h3{font-size: 34px;}
  .signupblockfour .contentblkblue p br{display: none;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper h1{font-size: 24px;line-height: 28px;}
}
@media only screen and (max-width: 1760px){
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 h1{font-size: 24px;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2{font-size: 20px;}
  /* .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 41px;} */
  .subtitlemainheader h3 {font-size: 46px;}
  .signupblockfour .subtitlemainheader h3{display: inline;}
}
@media only screen and (max-width: 1740px){
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h2{  font-size: 34px;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{font-size: 46px;line-height: 46px;}
  .listwrapper p{font-size: 19px;}
}
@media only screen and (max-width: 1720px){
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection{  width: 35%;}
  .pece_mkbannerblock .tagimg1wrp{right: 10%;
    /* width: 50px; */
    top: -30px;  }
  /* .pece_mkbannerblock .tagimg1wrp img{ width: 100%;display: block;  } */
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {font-size: 18px;}
  .listwrapper p{font-size: 17px;line-height: 22px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper{width: 300px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 24px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h2{font-size: 36px;line-height: 42px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp h2{font-size: 45px;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{font-size: 45px;line-height: 46px;}
  .blk1bottomtxt h1{font-size: 34px;}

  /* .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {
    font-size: 30px;
    line-height: 34px;} */
}
@media only screen and (max-width: 1700px){
  /* .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {font-size: 37px;line-height: 40px;} */
  .pece_mkbannerblock .pece_mkbannergirlimg img{width: 100%;display: block;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {width: 278px;}
  .pece_mkbannerblock .tagimg1wrp {right: 9%;
    /* width: 75px; */
    top: -34px;}
  /* .pece_mkbannerblock .tagimg1wrp img{display: block;width: 100%;} */
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {width: 280px;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 h1 {font-size: 22px;}  
  .pece_mkbannerblock .rightwrp_forsection .signupform::after{bottom: -57px;}
  .inner_blk3bg{width: 96%;}
}
@media only screen and (max-width: 1680px){
  /* .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 33px;} */
  /* .pece_mkbannerblock .tagimg1wrp{right: 18%;} */
  .subtitlemainheader h3 {font-size: 40px;}
  .signupblockfour .innercontentwrpmain .righttimg1{right: -74px;}

  .signupblocktwo .block2_docleftimg{left: -106px;}
}
@media only screen and (max-width: 1660px){
  .listwrapper p{background-position: left top;}
}
@media only screen and (max-width: 1640px){
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection{width: 32%;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {width: 240px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {font-size: 20px;line-height: 26px;}
  .pece_mkbannerblock .tagimg1wrp {right: 21%;width: 53px;top: -27px;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 h1 {font-size: 20px;line-height: 28px;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2{line-height: 22px;}
  .pece_mkbannerblock .leftwrp_content .pecemk_bannerpara p .brtext{display: inline;}  
  .subtitlemainheader h3 {font-size: 42px;}
  .signupblockthree .subtitlemainheader h3 {font-size: 32px;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{font-size: 42px;}
}

@media only screen and (max-width: 1600px){
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 43px;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {font-size: 16px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform::after{bottom: -61px;}
  .pece_mkbannerblock .pecemk_bannerpara p{font-size: 20px;line-height: 26px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper h1 {font-size: 22px;}
  .blk1bottomtxt h1 {font-size: 30px;}

  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp{padding: 0 12px 30px;}
}
@media only screen and (max-width: 1580px){
  .pece_mkbannerblock .leftwrp_content .mk_innerrightsection {width: 64%;}
  .subtitlemainheader h3 {font-size: 36px;}
}
@media only screen and (max-width: 1560px){
  .pece_mkbannerblock .tagimg1wrp{right: 20%;
    width: 50px;
    /* top: 5px; */
  }
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {width: 242px;}
  .pece_mkbannerblock .mk_innerrightsection .screensortimgwrp::after{bottom: -13px;}
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 40px;}
  .signupblockthree .subtitlemainheader h3 {font-size: 28px;}
  .listwarppercls h4{font-size: 20px;}
  .listwarppercls {    width: 375px;}
  .signupblockfour .contentblkblue p{font-size: 20px;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{font-size: 40px;line-height: 40px;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper .blk2greenbgmainwrp{width: 88%;}
  .signupblockfour .subtitlemainheader h3 {font-size: 32px;line-height: 40px;}
}
@media only screen and (max-width: 1540px){
  .signupblocktwo .lgtblock2contentgrybg h1{  font-size: 26px;}
}
@media only screen and (max-width: 1520px){
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 41px;}
  .subtitlemainheader h3 {font-size: 38px;}
  .signupblockfour .innercontentwrpmain .contentblkblue .yellowbar h1{font-size: 24px;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{font-size:36px;}
  .signupblocktwo .block2_docleftimg{left: -142px;}
  .listwrapper p{font-size: 16px;}
}
@media only screen and (max-width: 1460px){
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 h1{display: inline;}
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 40px;}
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 37px;}
  .signupblockone .subtitlemainheader{margin-left: 20%;}
  .subwrapper1{padding-left: 0;}
  .signupblockone{background-position: left -7% top;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 h1{  font-size: 18px;}
  .pece_mkbannerblock .pece_mkbannergirlimg{  display: none;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {width: auto;}
  .pece_mkbannerblock .tagimg1wrp {right: -9px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {
    font-size: 26px;
    /* width: auto; */
    /* padding-right: 20%; */
  } 
}
@media only screen and (max-width: 1440px){
.pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper h1 {font-size: 18px;line-height: 22px;}
.signupblockfour .innercontentwrpmain .blk4content1main3 .righttimg1{right: -175px;bottom: -39px;}
.signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{font-size: 36px;line-height: 36px;}
.signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper .blk2greenbgmainwrp {width: 85%;}
.pece_mkbannerblock .tagimg1wrp{     width: 16%;
  right: -15px;
}
.li1 .imgwrapperblock3, .li2 .imgwrapperblock3, .li3 .imgwrapperblock3, 
.li4 .imgwrapperblock3, .li5 .imgwrapperblock3, .li6 .imgwrapperblock3,
.li7 .imgwrapperblock3, .li8 .imgwrapperblock3 {    width: 200px;}
.li5 .imgwrapperblock3, .li6 .imgwrapperblock3, .li7 .imgwrapperblock3, .li8 .imgwrapperblock3{  left: -56px;}
.listwrapper p{  font-size: 17px;}
.pece_mkbannerblock .rightwrp_forsection .signupform::after {bottom: -65px;}
.pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper{margin:0;}
}
@media only screen and (max-width: 1400px){
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {font-size: 23px;}
  .signupblockfour .subtitlemainheader h3 {font-size: 28px;line-height: 32px;}
  .li1 .imgwrapperblock3, .li2 .imgwrapperblock3, .li3 .imgwrapperblock3, 
  .li4 .imgwrapperblock3, .li5 .imgwrapperblock3, .li6 .imgwrapperblock3,
  .li7 .imgwrapperblock3, .li8 .imgwrapperblock3 {    width: 200px;}
  .listwarppercls {width: 315px;}
  .listwarppercls h4{font-size: 16px;line-height: 20px;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {font-size: 15px;}
  .signupblockthree .subtitlemainheader h3{font-size: 26px;}
  .pece_mkbannerblock .rightwrp_forsection .form_grp .MuiInputBase-formControl{ height: 43px;}
.subtitlemainheader h3 {font-size: 36px;}
}
@media only screen and (max-width: 1380px){
.subtitlemainheader h3 {font-size: 34px;}
.signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper .blk2greenbgmainwrp {background-size: auto 100%,90% 100%;}
.pece_mkbannerblock .rightwrp_forsection .signupform .form_grp h2 {font-size: 42px;}
.signupblocktwo .block2_docleftimg {  left: -163px;}
}
@media only screen and (max-width: 1360px){
  .signupblockfour .contentblkblue p{font-size: 19px;line-height: 22px;}
  .signupblockfour .innercontentwrpmain .blk4content1main4 .righttimg1{left: -83px;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{font-size: 34px;line-height: 38px;}
  .pece_mkbannerblock .mk_innerrightsection .screensortimgwrp::after {bottom: -21px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper h1 {font-size: 18px;line-height: 24px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp h2 {font-size: 36px;line-height: 36px;}
}
@media only screen and (max-width: 1340px){
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 34px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h3 {font-size: 22px;}
  .signupblockfour .contentblkblue p{font-size: 18px;line-height: 22px;}
  .signupblockfour .innercontentwrpmain .righttimg1 {right: -114px;}
}
@media only screen and (max-width: 1320px){
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h3 {font-size: 22px;line-height: 26px;}
  .css-ahj2mt-MuiTypography-root {font-size: 12px !important;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h3 {font-size: 20px;}
  .signupblockfour .innercontentwrpmain .blk4content1main2 .righttimg1{left: -54%;}
  .signupblocktwo .signup_blk1leftimg{top: 164px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h3 {font-size: 18px;line-height: 24px;}
.pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 {margin-bottom: 10px;}
.pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 h1 {font-size: 16px;line-height: 24px;}
}
@media only screen and (max-width: 1300px){
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 34px;}
  .subtitlemainheader h3 {font-size: 32px;}
  .signupblockone .subtitlemainheader {margin-left: 22%;}
  .signupblockthree .subtitlemainheader h3{font-size: 24px;}
}
@media only screen and (max-width: 1280px){
  .li1 .imgwrapperblock3, .li2 .imgwrapperblock3, .li3 .imgwrapperblock3, 
  .li4 .imgwrapperblock3, .li5 .imgwrapperblock3, .li6 .imgwrapperblock3,
  .li7 .imgwrapperblock3, .li8 .imgwrapperblock3{width: 160px;right: -50px;}
  .li5 .imgwrapperblock3, .li6 .imgwrapperblock3, .li7 .imgwrapperblock3, .li8 .imgwrapperblock3 {width: 160px;left: -50px;right: inherit;}
  .signupblockfour .innercontentwrpmain .righttimg1 {right: -158px;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2 {font-size: 30px;line-height: 32px;}
  .signupblockfour .subtitlemainheader h3 {font-size: 26px;line-height: 32px;}
  .listwrapper p {    font-size: 15px;}
}
@media only screen and (max-width: 1240px){
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h2 {font-size: 22px;}
  .signupblocktwo .block2_docleftimg {    left: -176px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp {padding: 0 6px 30px;}
}
@media only screen and (max-width: 1260px){
  /* .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {font-size: 26px;line-height: 36px;} */
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 32px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h3 {    font-size: 22px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {
    font-size: 22px;
}
}
@media only screen and (max-width: 1199px){
  .websize-footerbanner {
    display: none;
}
  .nmsfunneldoctor_block3 ol li:nth-child(n+4):nth-last-child(-n+4):before, .nmsfunneldoctor_block3 ol li:nth-child(n+5):nth-last-child(-n+5):before{
    box-shadow: -4px 5px 10px rgb(0 0 0 / 30%);
  }
  .subtitlemainheader h3{ 
    background: inherit!important;
     color: #fff!important; -webkit-text-fill-color: inherit;
    -webkit-background-clip: inherit;}
  .pece_mkbannerblock .rightwrp_forsection .form_grp .MuiInputLabel-formControl{top: -6px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper {margin: 3px; margin-bottom: 0px;}

  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper{background-size: auto,17px 100%,auto auto,83% 16px,196px auto;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp {width: 97.6%;}
  .pece_mkbannerblock .rightwrp_forsection .form_grp  .MuiInputBase-formControl:nth-of-type(1){margin-top:0 !important;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp h2{margin: 18px 0;}
  .pece_mkbannerblock .rightwrp_forsection .form_grp .MuiInputBase-formControl{height: 52px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp h2 {font-size: 32px;line-height: 32px;}
  .pece_mkbannerblock .rightwrp_forsection .form_grp .form_btn{  font-size: 30px;width: 200px;height: 60px;}
  .pece_mkbannerblock {  margin-bottom: 30px;}
  .signup_main{  background-size: cover;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper{width: auto;text-align: center;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 48px;line-height: 58px;width: auto;display: inline;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {width: 72%;padding-right: 4%;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtextmain::after{display: none;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext{margin-bottom: 25px;}
  .blk1bottomtxt h1 {    font-size: 38px;}
  .blk4content1main3 .content1 {margin-right: 0;}
  .signupblockfour .blk4content1main .content1{  margin-right: 0;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection {  width: 100%;}
  .pece_mkbannerblock .pece_mkbannergirlimg{left: inherit;top: inherit;right: 0px;bottom: 0px;display: none;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper{width: 79%;}
  .signup_bannerarrow{background-image: none;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2{text-align: center;}
  .pece_mkbannerblock .mk_innerrightsection .screensortimgwrp::after{bottom: 0px;background-size: 100% 100%;}

  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {width: auto;padding-right: 10%;}

  .pece_mkbannerblock .tagimg1wrp {right: -1%;width: 10%;top: 2px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper h1{font-size: 26px;line-height: 28px;}

  .pece_mkbannerblock .signup_bannerarrow:after{display: none;}
  .signup_bannerarrow{margin-top: 0;padding: 30px 20px;margin: 20px 0;}
  .pece_mkbannerblock .signup_bannerarrow h2{font-size: 42px;font-weight: 900;text-align: center;}
  .pece_mkbannerblock .leftwrp_content .mk_innerrightsection{margin-bottom: 30px;}
  /* .signup_bannerarrow{padding: 30px 20px;margin: 20px 0;background-image: url(https://d37pbainmkhx6o.cloudfront.net/pecemarketinglandingpage/assets/images/lighthmeme_bannerarorgbg.png);background-size: auto 100%;padding: 30px 20px;} */
  .signup_bannerarrow{        display: none;}
  
  .signup_bannerarrow h2{-webkit-text-fill-color: inherit;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper .title1 h1 {font-size: 28px;line-height: 32px;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {font-size: 20px;line-height: 28px;margin-bottom: 40px;}
  .pece_mkbannerblock .mk_innerrightsection .screensortimgwrp::before{top: -14px;}
  .signupblockone .subtitlemainheader {margin-left: 0;}
  .listwrapper{margin-left: 0;}
  .signupblockone {background-position: left 43% center;background-size: cover;background-image: none;}
  .signupblockone .signup_blk1leftimg {position: inherit;top: 0;left: 0;display: none;}
  
  .listwrapper::before,.listwrapper::after{display: none;}
  .signupblocktwo .subtitlemainheader {margin-right: 0;}
  .signupblocktwo .signup_blk1leftimg {position: inherit;top: 0;right: 0;display: block;text-align: center;display: none;}
  .signupblocktwo .listwrapper{margin-right: 0;}
  .signupblocktwo .subwrapper1{padding-left: 0;}
  .signupblocktwo .block2_docleftimg {position: inherit;left: 0;top: 0;display: none;text-align: center;}
  .signupblockthree .subtitlemainheader h3 {font-size: 32px;line-height: normal;}
  .contentimgblkwrp{margin-left: 0;}
  .signupblocktwo .block2_docleftimg img{  width: 100%;display: block;}
  .signupblocktwo .signup_blk1leftimg img{  width: 100%;display: block;}
  .signupblockfour .content1 {width: auto;}
  .signupblockfour .innercontentwrpmain .righttimg1 {position: inherit;right: 0;top: 0;display: none;text-align: center;}
  .signupblockfour .innercontentwrpmain .righttimg1 img{width: 107%;display: block;margin-left: -50px;margin-top: -10%;}
  .signupblockfour .blk4content1main2 {margin-left: 0;}
  .signupblockfour .innercontentwrpmain .blk4content1main2 .righttimg1 {position: inherit;left: 0;bottom: 0;right: inherit;top: inherit;display: block;text-align: center;display: none;}
   
  .signupblockfour .innercontentwrpmain .blk4content1main3 .righttimg1 {position: inherit;right: 0;top: 0;}
  .blk4content1main4 .content1 {margin-left: 0;}
  .signupblockfour .innercontentwrpmain .blk4content1main4 .righttimg1{position: inherit;left: 0;bottom: 0;}
  .listwarppercls h4{font-size: 14px;line-height: 20px;}
  .listwarppercls{width: 279px;min-height: 80px;}
  .nmsfunneldoctor_block3 ol li:before {height: 43px;width: 43px;background-size: 100% 100%;font-size: 30px!important;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{font-size: 36px;line-height: 46px;}
  .getblackbtnwrp button{margin: 0 auto;}
  .MuiFormControlLabel-labelPlacementEnd{  justify-content: flex-start !important;align-items: center !important;}
  .signupblockthree .getblackbtnwrp{margin: 20px 0 40px 0;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp{padding: 0 10px 10px 10px;}

  .pece_mkbannerblock .rightwrp_forsection .signupform { 
    border: solid 1px #76838c;
  }

  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp{ border: none;}
  /* .pece_mkbannerblock .rightwrp_formsection .css-1u3bzj6-MuiFormControl-root-MuiTextField-root,.css-16awh2u-MuiAutocomplete-root{margin-bottom: 17px;} */
  .pece_mkbannerblock .rightwrp_formsection .MuiFormControl-root{margin-bottom: 10px !important;}
  .pece_mkbannerblock .contentwrapper{flex-direction: column-reverse;}
  .headerblock{display: none;}
}
@media only screen and (max-width: 1100px){
  .pece_mkbannerblock .signup_bannerarrow h2{font-size: 36px;}
}
@media only screen and (max-width: 1160px){
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp {width: 97.5%;}
}
@media only screen and (max-width: 1080px){
  .li1 .imgwrapperblock3, .li2 .imgwrapperblock3, .li3 .imgwrapperblock3, .li4 .imgwrapperblock3{    width: 140px;right: -40px;}
  .li5 .imgwrapperblock3,
   .li6 .imgwrapperblock3, .li7 .imgwrapperblock3, .li8 .imgwrapperblock3{ width: 140px;}
}
@media only screen and (max-width: 1060px){
  .pece_mkbannerblock .signup_bannerarrow h2{font-size: 34px;}
  .signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2{font-size: 36px;line-height: 36px;}
  .subtitlemainheader h3 {font-size: 28px;line-height: normal;}
}
@media only screen and (max-width: 1040px){
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 48px;}
}
@media only screen and (max-width: 1024px){
  .nmsfunneldoctor_block3 ol{
    moz-column-count: 1;column-count: 1;-moz-column-gap: 40px;column-gap: 0;list-style: decimal!important;counter-reset: item;
    display: flex;flex-wrap: wrap;justify-content: space-between;padding-top: 115%;background-size: 100%;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/block3midleimg1.webp);
    margin: 0;
  }
  .nmsfunneldoctor_block3 ol li:before {left: -12px!important;top: 50%!important;right: inherit!important;transform: translateY(-50%);}
.listwarppercls {min-height: 80px;min-height: 80px;width: auto;flex-direction: row;}
.blk3humanbodymainbg{background-image: url(https://d37pbainmkhx6o.cloudfront.net/pecemarketinglandingpage/assets/images/lighttheme_block3mainbg.webp);background-size: cover;background-position: center top;border: none;box-shadow: inset 0 0 10px rgb(0 0 0 / 40%);padding-bottom: 25px;}
.signupblockthree .getblackbtnwrp {margin: 0;margin-right: 20px;}
.listwarppercls h4 {font-size: 28px;line-height: 30px;width: auto;text-align: left;padding: 0;margin-left: 50px;}
.nmsfunneldoctor_block3 ol li{padding: 0;width: 100%; margin: 0 auto;}
.li1 .imgwrapperblock3, .li2 .imgwrapperblock3, .li3 .imgwrapperblock3,
.li4 .imgwrapperblock3, .li5 .imgwrapperblock3, .li6 .imgwrapperblock3,
.li7 .imgwrapperblock3, .li8 .imgwrapperblock3 {right: inherit;position: inherit;top: inherit;display: block;margin: 0;transform: inherit;left: inherit;width: 120px;background: #fff;}
.imgwrapperblock3 img{position: relative;right: -4px;bottom: -6px;}
.nmsfunneldoctor_block3 .nmsfunneldoctor_block_wrapper{  padding: 1px 0px;margin: 0;}
.li1:after,.li2:after,.li3:after,.li4:after,.li5:after,.li6:after,.li7:after,.li8:after{  display: none;}
.nmsfunneldoctor_block3 ol li:nth-child(n+4):nth-last-child(-n+4), .nmsfunneldoctor_block3 ol li:nth-child(n+5):nth-last-child(-n+5) {direction: inherit;}
.signupblockfour .innercontentwrpmain .contentblkblue .yellowbar h1{font-size: 24px;}
.nmsfunneldoctor_block3 ol li{  overflow: initial;}
}
.mknewcontainer .mobiban{display: flex;justify-content: center;align-items: center;}
@media only screen and (max-width: 1020px){
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp {width: 97.2%;}
  .blk3humanbodymainbg{  padding-bottom: 10px;}
}
@media only screen and (max-width: 1000px){
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 46px;}
}
@media only screen and (max-width: 991px){
.signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2 {font-size: 26px;  line-height: 32px;}
.signupblocktwo .lgtblk2flexwrapper .pointerimgwrp{position: inherit;right: 0;top: 0;width: 50px;margin-right: 20px;}
.signupblocktwo .lgtblock2contentgrybg h1{font-size: 24px;line-height: 28px;padding: 20px;}
.listwrapper p{  line-height: 21px;}

}
@media only screen and (max-width: 960px){
  .signupblockfour .innercontentwrpmain .contentblkblue .yellowbar h1{font-size: 20px;line-height: 24px;}
  .blk1bottomtxt h1 {    font-size: 28px;}
}
@media only screen and (max-width: 880px){
  .pece_mkbannerblock .signup_bannerarrow h2 {font-size: 30px;line-height: normal;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp {width: 96.5%;}
}
@media only screen and (max-width: 840px){
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 38px;line-height: 42px;}
}
@media only screen and (max-width: 820px){
  .listsubwrapper{padding: 10px;}
  .signupblockfour .innercontentwrpmain .righttimg1 img{width: 112%; }
  .signupblockfour .innercontentwrpmain .contentblkblue .textwrappermain .yellowbar{background-image:none;}
}
@media only screen and (max-width: 780px){
  .listwrapper p{font-size: 14px;}
}
@media only screen and (max-width: 768px){
.listwarppercls h4 {font-size: 20px;line-height: 22px;}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 33px;line-height: 42px;}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {width: 67%;}
.pece_mkbannerblock .pece_mkbannergirlimg{display: none;}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {width: initial;padding-right: 13%;display: block;}
.pece_mkbannerblock .tagimg1wrp {right: -2%;}
.lgtblk2flexwrapper{background-color: #ffe613;border-radius: 0 0 8px 8px;}
.signupblocktwo .lgtblk2flexwrapper .pointerimgwrp{display: none;}
.signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper .blk2greenbgmainwrp{background-image: none;}
.blk1bottomtxt{justify-content: center;flex-wrap: wrap;}
.blk1bottomtxt h1 {font-size: 28px;line-height: normal;width: 100%;text-align: center;margin-bottom: 10px;}
.pece_mkbannerblock .signup_bannerarrow h2 {font-size: 29px;line-height: normal;font-weight: 700;}
.pece_mkbannerblock .rightwrp_forsection .signupform .form_grp {  width: 95.9%;}
.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper{padding-right: 10%;}
.pece_mkbannerblock .leftwrp_content .contenttextwrapper h2{  font-size: 22px;}
.subtitlemainheader h3{  font-size: 26px;}
.signupblocktwo .subtitlemainheader h3{  font-size: 30px;}
.signupblockthree .subtitlemainheader h3{  font-size: 28px;}
.listwrapper p{font-size: 13px;}

.nmsfunneldoctor_block3 ol li:before{left: 10px!important;height: 33px;width: 33px;}
  .listwarppercls h4{margin-left: 46px;}
  .blk3humanbodymainbg{padding-top: 30px;}
  .nmsfunneldoctor_block3 ol li:before{font-size: 22px!important;}


.pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {
  padding-right: 14%;
}

}
@media only screen and (max-width: 740px){
  .listsubwrapper{column-count: 1;}
  .signupblocktwo .listsubwrapper{column-count: 1;}
  .signupblockone .listing_righttext {margin-left: 0;}
  .listwrapper p{font-size: 16px;}
}
@media only screen and (max-width: 720px){
  .signupblockfour .innercontentwrpmain .contentblkblue .yellowbar{background-size: auto 100%;}
}
@media only screen and (max-width: 660px){
.signupblocktwo .lgtblock2contentwhbg .lgtblock2contentgrybg .lgtblk2flexwrapper h2 {font-size: 22px;line-height: 24px;}
}
@media only screen and (max-width: 640px){
  .listwarppercls h4 {font-size: 18px;line-height: 20px;
    /* margin-left: 35px; */
    width: 60%;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp {width: 94.9%;}
}
@media only screen and (max-width: 580px){
  .subtitlemainheader h3 {font-size: 26px;line-height: normal;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp {width: 94%;}
  .signupblockthree .subtitlemainheader h3{font-size: 26px;}
  .signupblockfour .innercontentwrpmain .righttimg1 img {width: 119%;}
  .blk1bottomtxt h1{font-size: 24px;  }
  .getblackbtnwrp button{font-size: 18px;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 30px;}
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2{margin-bottom: 20px;}
}
@media only screen and (max-width: 540px){
  .signupblocktwo .lgtblock2contentgrybg h1{font-size: 18.8px;line-height: 24px;}
  .pece_mkbannerblock .tagimg1wrp{top: 27px;}
  .pece_mkbannerblock .mk_innerrightsection .screensortimgwrp{background-image: none;padding: 10px;border: 8px solid #e3dada;}
  .pece_mkbannerblock .mk_innerrightsection .screensortimgwrp::before,.pece_mkbannerblock .mk_innerrightsection .screensortimgwrp::after{background-image: none;}
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {font-size: 30px;line-height: 30px;}
}
@media only screen and (max-width: 500px){
  .listwarppercls h4 {
    font-size: 16px;
    line-height: 20px;
    width: 55%;
    margin-left: 40px;
}
.nmsfunneldoctor_block3 ol li:before {
  left: 9px!important;
  height: 30px;
  width: 30px;
}
}
@media only screen and (max-width: 445px){
  .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2{display: none;}
  .blk1bottomtxt{padding: 10px;}
  .listwrapper p{font-size: 19.5px;}
  .listwrapper p:nth-child(5){
    background-image: none;
  }
  .listwrapper h6{
    font-size: 20px;
    color: #ffffff;
  }
/* .mobileviewlist {display: block; margin-bottom: 14px;} */
}
@media only screen and (max-width: 425px){
  .pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper h1{font-size: 22px;}
  
}
@media only screen and (max-width: 420px){  
  .pece_mkbannerblock .tagimg1wrp {right: -1%;width: 12%;top: 20px;}
  .li1 .imgwrapperblock3, .li2 .imgwrapperblock3, .li3 .imgwrapperblock3,
.li4 .imgwrapperblock3, .li5 .imgwrapperblock3, .li6 .imgwrapperblock3,
.li7 .imgwrapperblock3, .li8 .imgwrapperblock3 {width: 90px;}
}
@media only screen and (max-width: 380px){
  .pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper h1 {font-size: 18px;line-height: 22px;padding: 10px 5px;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp{width: 90%;}
  .signupblockthree .subtitlemainheader h3 {font-size: 22px;line-height: 28px;}
  .subtitlemainheader h3 {font-size: 22px;}
  .signupblockfour .subtitlemainheader h3{font-size: 22px;line-height: normal;}
  .blk1bottomtxt h1 {font-size: 26px;line-height: normal;}
  .pece_mkbannerblock .tagimg1wrp{width: 14%;}
  .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp h2{font-size: 28px;}
  .signupblockfour .innercontentwrpmain .contentblkblue .yellowbar h1{font-size: 16px;  }
  .signupblocktwo .subtitlemainheader h3{font-size: 22px;  }
  .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper {padding-right: 16%;}
  .listwarppercls h4 {
      font-size: 15px;
    }
  /* .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {font-size: 30px;line-height: 30px;} */
}
@media only screen and (max-width: 362px){
  /* .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1{font-size: 17px;} */
}
@media only screen and (max-width: 280px){
  .pece_mkbannerblock .rightwrp_forsection .signupform .titlewrapper h1{font-size: 16px;}
}
/* ---------------------END------------------- */