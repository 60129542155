@font-face {
    font-family: "avenir-medium";
    src: url(../font/Avenir\ Medium.ttf);
}

@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
.modalPop .modalContent .heading h5 {
    font-size: 1.2rem;
}

.insurancename .formFeild {
    width: 100% !important;
}

.darktheme .insurancename .formFeild .MuiInputBase-formControl {
    color: white !important;
}

.hide {
    display: none !important;
}

.mainContent {
    position: relative;
    z-index: 1;
}

.lighttheme-quickintake-mobban {
    background: #50c0e8 !important;
}

.errorMsg {
    color: red;
}

.signFormat {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 47%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    overflow: auto;
}

.groupfld {
    /* border: 1px solid #747474; */
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    width: 32%;
    margin: 5px 0.5% !important;
    border-radius: 0px !important;
    height: auto;
    display: flex !important;
    /* justify-content: left;
     */
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.groupfld .errortextft {
    position: absolute;
    left: 136px;
    top: 33px;
}

.groupfld .errortextinch {
    position: absolute;
    right: 37px;
    top: 33px;
}

.groupfld label {
    padding: 0 14px;
    height: 100%;
    align-items: center;
    display: flex;
}

.groupfld div label {
    padding-left: 0;
    border-right: none !important;
    height: auto;
    display: inherit;
}

.groupfld .formFeildinside {
    margin-left: 6px;
    width: 30%;
}

.groupfld div * {
    color: #fff;
}

.darktheme .groupfld .MuiOutlinedInput-root fieldset {
    border: solid 1px #7c7c7c !important;
}

.lighttheme .groupfld div * {
    color: rgba(0, 0, 0, 0.87);
}

.lighttheme .groupfld {
    border: none;
}

.lighttheme .groupfld .formFeildinside {
    border-left: none !important;
}

.iconedit svg {
    font-size: 15px;
}

.intake_form .patientIntakeFormheder .First-form .heading {
    color: #0ac3a2;
    font-size: 20px;
    margin: 10px auto;
    text-transform: capitalize;
    width: 100%;
}

.intake_form .patientIntakeFormheder .First-form .heading h3 {
    color: #0ac3a2;
    font-size: 20px;
    margin: 0;
    text-transform: capitalize;
    width: 100%;
}

.buttonSection {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    justify-content: right;
}

.intake_form .form .quick-symptom-section .heading {
    color: #0ac3a2;
    font-size: 20px;
    margin: 0;
    text-transform: capitalize;
}

.intake_form .form .quick-symptom-section .heading p {
    margin: 0 0 10px 0;
}

.darktheme .intake_form .form .quick-symptom-section .heading h3 {
    color: #0ac3a2;
    font-size: 20px;
    margin: 0;
    text-transform: uppercase;
}

.lighttheme .intake_form .form .quick-symptom-section .heading h3 {
    color: #1dbce9;
    font-size: 20px;
    margin: 0;
    text-transform: uppercase;
}

.darktheme .intake_form .form .quick-symptom-section .heading p {
    color: #0ac3a2;
    font-size: 17px;
    margin: 0;
    text-transform: capitalize;
}

.lightheme .intake_form .form .quick-symptom-section .heading p {
    color: #009ded !important;
    font-size: 17px;
    margin: 0 !important;
    text-transform: capitalize;
}

.intake_form .form .otherDetails-blocks .box {
    display: flex;
    align-items: center;
}

.intake_form .form .otherDetails-blocks .box .form-radio-input {
    flex-direction: row !important;
    align-items: center !important;
    padding-left: 10px;
}

.intake_form .form .otherDetails-blocks .box .MuiFormControlLabel-labelPlacementEnd {
    align-items: center !important;
}

.authNsign .practiceinfo {
    font-weight: bold;
}

.authNsign .practiceinfo span.emailspan {
    word-break: break-all;
}

.signSec .Signbox .MuiInputBase-formControl {
    color: rgb(248, 248, 248);
    -webkit-text-fill-color: white;
    font-family: Parisienne, cursive;
    top: 4px;
    position: relative;
    font-size: 21px;
}

.note {
    padding: 10px;
    background: #1185bd;
    color: white;
    margin: 0 auto;
    text-align: center;
}

.authfeilds .signSec .Signbox {
    /* width: 75%; */
    width: 25%;
    /* border: solid 1px #e0e0e0 !important; */
}

.First-form .wrapper {
    position: relative;
}

.First-form .wrapper .errortext {
    position: absolute;
    top: 39px;
    left: 22px;
}

.signFormat {
    font-family: Parisienne, cursive;
    font-size: 22px;
    /* margin: 10px; */
    /* width: 300px; */
    border: 1px dotted;
    height: 34px;
    background: #e8f0fe;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    width: 100%;
    overflow: hidden;
    margin: 10px auto;
    padding: 10px 0px;
}

.termsNcond {
    min-height: auto;
    max-height: 40Vh;
    overflow: scroll;
    overflow-x: hidden;
}

.SignPart {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    /* margin-top: 10px; */
    background: #e1e1e1;
    padding: 10px;
    padding-top: 15px;
}

.SignPart .signFeilds {
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.SignPart .signFeilds>.name_type {
    width: 100%;
}

.SignPart .signFeilds>.name_type label {
    font-size: 20px;
    line-height: 27px;
}

.SignPart .signFeilds>.name_type input {
    padding: 0px 10px;
    height: 57px;
}

.sign .modalContent {
    background-color: white;
    padding: 25px;
    /* min-height: auto !important;
    max-height: 80vh !important;
    overflow: hidden !important; */
    position: relative;
}

.sign .btngrp {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0px;
}

.sign .btngrp button {
    /* height: 53px !important; */
    margin: 5px;
}

.css-gepadz-MuiTypography-root {
    font-size: 30px !important;
}

.dobBlock {
    position: relative;
}

.dobBlock .errortext {
    position: absolute;
    top: 40px;
    left: 21px;
}

.btnpart {
    width: 100%;
    display: flex;
    justify-content: center;
}

.btnpart .btnmdl {
    margin: 10px;
}


/* ###########  Modal Section goes here ########### */

.modalPop .modalContent {
    border: 8px solid #1185bd;
    padding: 0px !important;
}

.modalPop .modalContent .practiceinfo {
    font-weight: bold;
}

.privacyPop .btngrp {
    width: 100%;
    display: flex;
    margin: 10px 0;
}

.privacyPop .modalContent .heading {
    position: sticky;
    top: 0;
    z-index: 1;
}

.privacyPop .modalContent {
    min-height: 90vh !important;
    max-height: 90vh !important;
}

.modalPop .modalContent .box {
    padding: 24px;
    width: auto;
}

.modalPop .modalContent .heading {
    text-align: center;
}

.modalPop .modalContent .heading {
    background: #1185bd;
    color: white;
    padding: 10px;
}

.modalPop .modalContent .textareaexplain {
    border: 1px solid #d9d9d9;
    padding: 1%;
    width: 98%;
    height: 100px;
    resize: none;
    outline: none;
}

.modalPop .modalContent .errormsg {
    color: red;
    padding: 11px;
}

.modalPop .modalContent .heading .crossBtn {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #00acff;
    padding: 10px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 20px;
}

.signature .formFeild {
    margin: 22px 0;
    width: 100%;
}

.modalContent::-webkit-scrollbar {
    width: 10px;
    z-index: -1;
}


/* Track */

.modalContent::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

.modalContent::-webkit-scrollbar-thumb {
    background: #888;
}


/* Handle on hover */

.modalContent::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* ########## Modal Section goes here ############ */


/* {/*############################## light theme ###############  }*/

.lighttheme {
    margin: 0;
    padding: 20px;
}

.lighttheme .intake_form {
    width: 1400px;
    margin: 20px auto;
    background: #f3f3f3;
    padding: 16px 10px 50px;
    border: 1px solid #91e7ff;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.lighttheme .intake_form .form .quick-symptom-section .heading {
    color: #1dbce9;
    font-size: 20px;
    margin: 0;
    text-transform: capitalize;
}

.lighttheme .intake_form .patientIntakeFormheder .First-form .heading h3 {
    color: #1dbce9;
    font-size: 20px;
    margin: 0;
    text-transform: capitalize;
    width: 100%;
}

.lighttheme .intake_form .heading {
    font-family: avenir-medium;
    text-transform: uppercase;
    font-size: 24px;
    padding: 6px;
    color: #1dbce9;
    background: #f7f7f7;
    width: 98%;
}

.lighttheme .authNsign .heading {
    font-family: avenir-medium;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
    padding: 0 6px;
    color: #1dbce9;
    background: #f7f7f7;
    width: 98%;
}

.lighttheme .intake_form .heading h3 {
    margin: 0;
    padding: 5px;
}

.lighttheme .intake_form .heading .bannerimg {
    width: 100%;
}

.lighttheme .intake_form .patientIntakeFormheder {
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    background: #fff;
    color: rgba(0, 0, 0, .87);
}

.lighttheme .intake_form .patientIntakeFormheder {
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    background: #fff;
    color: rgba(0, 0, 0, .87);
}

.lighttheme .intake_form .patientIntakeFormheder .First-form {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #e0e0e0;
    width: 100%;
}

.formFeildDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    position: relative;
    background: #fff;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    width: 32%;
    margin: 5px;
    height: auto;
    padding: 16.5px 0px;
}

.formFeildDiv lable {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0 5px;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;
    left: 12px;
    top: -8px;
    background: #fff;
}

.formFeildDiv p {
    width: calc( 100% - 12px);
    margin: 0px;
    margin-left: 12px;
}

.formFeildDiv span {
    margin-right: 10px;
    cursor: pointer;
}

.lighttheme .intake_form .patientIntakeFormheder .formFeild {
    border-radius: 4px 4px 0 0;
    background: #fff;
    border: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    width: 32%;
    margin: 5px;
    height: auto;
    position: relative;
}

.lighttheme .intake_form .patientIntakeFormheder .formFeild::placeholder {
    margin: 25px;
}

.lighttheme .intake_form .patientIntakeFormheder .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.lighttheme .intake_form .patientIntakeFormheder .insurance-info {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #e0e0e0;
    display: block;
    width: 99%;
}

.lighttheme .intake_form .patientIntakeFormheder .insurance-info h3 {
    font-family: avenir-medium;
    margin: 0;
    font-size: 18px;
    color: #1dbce9;
    text-transform: uppercase;
}

.lighttheme .intake_form .patientIntakeFormheder .insurance-info h3 .bold {
    font-weight: bold;
    background: #12a0e5;
    padding: 3px;
}

.lighttheme .intake_form .patientIntakeFormheder .practice-info {
    margin: 10px 0;
}

.lighttheme .intake_form .patientIntakeFormheder .practice-info h3 {
    font-family: "AvenirMedium";
    margin: 0;
    font-size: 20px;
    color: #0ac3a2;
    text-transform: uppercase;
}

.lighttheme .modalPop .modalContent {
    border: 5px solid #1976d2;
}

.lighttheme .intake_form .patientIntakeFormheder .appointment-sched {
    margin: 10px 0;
}

.lighttheme .intake_form .patientIntakeFormheder .appointment-sched h3 {
    font-family: "AvenirMedium";
    margin: 0;
    font-size: 20px;
    color: #0ac3a2;
    text-transform: uppercase;
}

.lighttheme .intake_form .quick-symptom-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks {
    width: 100%;
    border: 1px solid #dadada;
    margin: .5%;
    box-sizing: border-box;
    padding: 10px 0;
    column-count: 2;
}

.lighttheme .intake_form .quick-symptom-section .quickintake3row {
    column-count: inherit;
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.lighttheme .intake_form .quick-symptom-section .quickintake3row .fields {
    width: calc(33% - 10px);
    margin: 5px!important;
}


/* .lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields .title {
    width: 71%;
} */

.lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .heading {
    font-family: "AvenirMedium";
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    background: linear-gradient(45deg, #12a0e5, #64ceff);
    padding: 1px 14px;
}

.lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .heading h3 {
    margin: 10px;
}

.lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #e1e1e1;
    padding: 5px;
}

.lighttheme .intake_form .quick-symptom-section .authNsign {
    width: 100%;
    border: 1px solid #dadada;
    margin: .5%;
    box-sizing: border-box;
    padding: 10px;
}

.lighttheme .intake_form .quick-symptom-section .authNsign .heading {
    font-family: avenir-medium;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    padding: 6px 6px;
    background-color: #1dbce9;
    color: white;
    margin: 0px;
}

.lighttheme .intake_form .authNsign .formFeild {
    /* width: 429px; */
    /* width: 98%; */
    /* border: solid 1px #e0e0e0 !important; */
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.lighttheme .intake_form .authNsign .formFeild fieldset {
    border: none !important;
}

.lighttheme .intake_form .authNsign .signSec {
    position: relative;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
}

.lighttheme .intake_form .authNsign .authfeilds {
    display: flex;
}

.lighttheme .intake_form .authNsign .signbtn {
    font-size: 1.5em;
    padding: 19px;
    border-radius: 38px;
    /* right: 16px; */
    color: #fff;
    background: #2484e6;
}

.authNsign.rewardPartnerSec {
    width: 100%;
}

.authNsign.rewardPartnerSec .content input {
    margin-right: 8px;
    vertical-align: middle;
}

.lighttheme .intake_form .quick-symptom-section .buttonSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.lighttheme .intake_form .quick-symptom-section .buttonSection button:nth-child(1) {
    background: linear-gradient(45deg, #0676fe, #05a2f7);
    margin: 5px;
}

.lighttheme .intake_form .quick-symptom-section .buttonSection button:nth-child(2) {
    background: linear-gradient(65deg, #0676fe, #05a2f7);
    margin: 5px;
}

.lighttheme .intake_form .patientIntakeFormheder .insurance-info span {
    /* font-weight: bold;
    color: rgb(29 188 233); */
    color: #000;
    font-weight: normal;
    padding: 3px;
    border: 2px solid white;
}

.lighttheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lighttheme .intake_form .otherDetails-blocks {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #e0e0e0;
}

.lighttheme .intake_form .otherDetails-blocks .heading {
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.lighttheme .intake_form .otherDetails-blocks .heading h3 {
    font-size: 18px;
    margin: 0px;
}

.lighttheme .intake_form .otherDetails-blocks .fields {
    width: auto;
    margin: 5px 0.5%;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    padding: 7px;
}

.lighttheme .intake_form .otherDetails-blocks .form-check-input {
    cursor: pointer;
}

.lighttheme .intake_form .quick-symptom-section .TermsNConditions {
    width: 100%;
    border: 1px solid #dadada;
    margin: .5%;
    box-sizing: border-box;
    padding: 10px;
    color: rgb(0, 0, 0);
    display: flex;
}

.lighttheme .intake_form .quick-symptom-section .TermsNConditions .heading {
    margin: 0px;
}

.lighttheme .intake_form .quick-symptom-section .TermsNConditions .heading h3 {
    color: rgb(2, 2, 2);
    margin: 0px;
}

.lighttheme .intake_form .quick-symptom-section .TermsNConditions .termsncondition .MuiFormControlLabel-root {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.lighttheme .intake_form .quick-symptom-section .TermsNConditions .policy .MuiFormControlLabel-root {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.lighttheme .intake_form .patientIntakeFormheder .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields:hover {
    background: #a3e9fe;
    color: #1185bd;
    cursor: pointer;
}

.lighttheme .intake_form .authNsign {
    /* width: 100%; */
    border: 1px solid #e0e0e0;
    /* margin: .5%; */
    box-sizing: border-box;
    padding: 10px;
}

.footerBanner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerBanner .banner {
    width: 100%;
}


/* ------- toggle hide repeated symptomps -------- */

.lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .hide {
    display: none !important;
}


/* ----------------------------------------------- */


/* ############################################################################### */


/* {/*################*################################/*################ */


/* ############################ Dark Theme ########################## */

.darktheme {
    background: #181818;
    margin: 0;
    padding: 20px;
}

.darktheme .intake_form {
    width: 1400px;
    margin: 20px auto;
    background: #181818;
    padding: 16px 10px 50px;
    border: 1px solid #3d3d3d;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.darktheme .intake_form .heading {
    font-family: avenir-medium;
    text-transform: uppercase;
    font-size: 24px;
    padding: 0;
    color: #181818;
}

.darktheme .intake_form .heading .bannerimg {
    width: 100%;
}

.darktheme .intake_form .patientIntakeFormheder {
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    background: rgb(52, 52, 52);
    color: rgba(255, 255, 255, 0.87);
}

.darktheme .intake_form .patientIntakeFormheder {
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    background: rgb(55, 55, 55);
    color: rgba(255, 255, 255, 0.87);
}

.darktheme .intake_form .patientIntakeFormheder .First-form {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #505050;
}

.darktheme .intake_form .patientIntakeFormheder .formFeild {
    border-radius: 4px 4px 0 0;
    border: 1px solid #7c7c7c;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    width: 32%;
    margin: 5px 0.5% !important;
    border-radius: 0px !important;
    height: auto;
    position: relative;
}

.darktheme .intake_form .patientIntakeFormheder .formFeild label {
    color: white;
}

.darktheme .intake_form .patientIntakeFormheder .formFeild::placeholder {
    margin: 25px;
}

.darktheme .intake_form .patientIntakeFormheder .insurance-info {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #505050;
}

.darktheme .intake_form .patientIntakeFormheder .insurance-info h3 {
    font-family: avenir-medium;
    margin: 0;
    font-size: 20px;
    color: #0ac3a2;
    text-transform: uppercase;
}

.darktheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.darktheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field .MuiInputBase-input {
    color: white;
}

.darktheme .intake_form .patientIntakeFormheder .practice-info {
    margin: 10px 0;
}

.darktheme .intake_form .patientIntakeFormheder .practice-info h3 {
    /* font-family: avenir-medium; */
    margin: 0;
    font-size: 20px;
    color: #0ac3a2;
    text-transform: uppercase;
}

.darktheme .intake_form .patientIntakeFormheder .appointment-sched {
    margin: 10px 0;
}

.darktheme .intake_form .patientIntakeFormheder .appointment-sched h3 {
    /* font-family: avenir-medium; */
    margin: 0;
    font-size: 20px;
    color: #0ac3a2;
    text-transform: uppercase;
}

.darktheme .intake_form .otherDetails-blocks {
    width: 98%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #505050;
}

.darktheme .intake_form .otherDetails-blocks .heading {
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.darktheme .intake_form .otherDetails-blocks .heading h3 {
    color: #0ac3a2;
    font-size: 20px;
    margin: 0px;
}

.darktheme .intake_form .otherDetails-blocks .fields {
    width: auto;
    margin: 5px 0.5%;
    cursor: pointer;
    border: 1px solid #7c7c7c;
    padding: 7px;
}

.darktheme .intake_form .otherDetails-blocks .form-check-input {
    cursor: pointer;
}

.darktheme .intake_form .quick-symptom-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.darktheme .intake_form .quick-symptom-section .quick-symptom-blocks {
    width: 99.5%;
    border: 1px solid #505050;
    margin: .5% 0;
    box-sizing: border-box;
    padding: 10px 0.5%;
    column-count: 2;
}

.darktheme .intake_form .quick-symptom-section .form-check-input {
    cursor: pointer;
}

.darktheme .intake_form .quick-symptom-section .symptom-blocks .heading {
    font-family: "AvenirMedium";
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    background: #181818;
    padding: 1px 14px;
}

.darktheme .intake_form .quick-symptom-section .quick-symptom-blocks .heading h3 {
    font-family: avenir-medium;
    margin: 10px;
}

.darktheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #6a6a6a;
    padding: 5px;
    align-items: center;
}

.darktheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields:hover {
    background: rgb(174, 174, 174);
    color: #181818;
    cursor: pointer;
}


/* .darktheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields .title {
    width: 50% !important;
} */

.darktheme .intake_form .authNsign {
    /* width: 100%; */
    border: 1px solid #505050;
    /* margin: .5%; */
    box-sizing: border-box;
    padding: 10px;
}

.darktheme .intake_form .authNsign .heading {
    font-family: avenir-medium;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
    padding: 5px 6px;
    background-color: #504f4f;
    color: white;
    margin: 0px;
}

.darktheme .intake_form .authNsign .heading h3 {
    margin: 0;
    color: #0ac3a2;
}

.darktheme .intake_form .First-form .MuiInputBase-formControl {
    background: #373737;
    color: white;
    border-radius: 0px !important;
}

.darktheme .intake_form .quick-symptom-section .buttonSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.darktheme .intake_form .quick-symptom-section .buttonSection button:nth-child(1) {
    background: linear-gradient(45deg, #0676fe, #05a2f7);
    margin: 5px;
}

.darktheme .intake_form .quick-symptom-section .buttonSection button:nth-child(2) {
    background: linear-gradient(65deg, #0676fe, #05a2f7);
    margin: 5px;
}

.darktheme .intake_form .MuiInputLabel-shrink {
    font-size: 19px;
    background: transparent;
    color: rgb(255, 255, 255);
    top: 10px;
}

.darktheme .intake_form .quick-symptom-section .TermsNConditions {
    width: 100%;
    border: 1px solid #505050;
    margin: .5%;
    box-sizing: border-box;
    padding: 10px;
    color: white;
    display: flex;
}

.darktheme .intake_form .quick-symptom-section .TermsNConditions .heading {
    margin: 0px;
}

.darktheme .intake_form .quick-symptom-section .TermsNConditions .heading h3 {
    color: white;
    margin: 0px;
}

.darktheme .intake_form .quick-symptom-section .TermsNConditions .termsncondition .MuiFormControlLabel-root {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.darktheme .intake_form .quick-symptom-section .TermsNConditions .policy .MuiFormControlLabel-root {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.darktheme .intake_form .authNsign .formFeild {
    width: 429px;
}

.darktheme .authNsign .MuiInputBase-input {
    color: rgb(248, 248, 248);
    -webkit-text-fill-color: white;
}


/* ------- toggle hide repeated symptomps -------- */

.darktheme .intake_form .quick-symptom-section .quick-symptom-blocks .hide {
    display: none;
}

.lightheme .intake_form .quick-symptom-section .hide {
    display: none !important;
}


/* ----------------------------------------------- */

.darktheme .intake_form .quick-symptom-section .authNsign {
    width: 100%;
    border: 1px solid #dadada;
    margin: .5%;
    box-sizing: border-box;
    padding: 2px;
}

.darktheme .intake_form .quick-symptom-section .authNsign .heading {
    font-family: avenir-medium;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
    padding: 0 6px;
    background-color: #525252;
    color: rgb(50, 50, 50);
    margin: 0px;
}

.darktheme .intake_form .quick-symptom-section .authNsign .content {
    padding: 16px;
    margin: 0px;
}

.darktheme .intake_form .authNsign .formFeild {
    width: 429px;
}

.darktheme .intake_form .authNsign .signSec {
    /* width: 444px; */
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.darktheme .intake_form .authNsign .authfeilds {
    display: flex;
    /* justify-content: center; */
}

.darktheme .intake_form .authNsign .signbtn {
    font-size: 1.5em;
    border-radius: 38px;
    padding: 18px;
    background: #30ada2;
    color: white;
}

.modalPop {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.modalPop .MuiBackdrop-root {
    z-index: -1 !important;
}

.modalPop .modalContent {
    background-color: white;
    padding: 25px;
    /* min-height: auto;
    max-height: 383px;
    overflow-y: scroll; */
    position: relative;
}

.modalPop .modalContent p {
    margin: 0px !important;
}

.termscond {
    cursor: pointer;
    text-decoration: underline;
}

.intakeForm_header {
    width: 100% !important;
}


/* {/*################################################################################ */


/* ############################## Middlewar Modal {/*################/*###################### */

.middleWarModalClass.modalPop.modalblock .modalContent {
    text-align: center;
    position: relative;
    min-height: 60px;
    overflow: hidden;
    width: 620px;
}

.middleWarModalClass.modalPop.modalblock .modalContent p {
    margin: 20px auto !important;
    /* width: 100%; */
    /* padding: 0px 10px; */
}

.formFeild .formFeildinside {
    width: 100% !important;
    border: transparent;
}

.dobController .formdob {
    width: 100% !important;
}


/* 
.bullets_wrpr ul li{
    padding-left: 17px;
} */

.signUp_header .heading {
    width: auto !important;
}

.modalContent .insuranceAccessGainedBox {
    width: 100% !important;
    padding: 24px 0px !important;
    min-width: 600px;
}

.modalContent .insuranceAccessGainedBox form .innerFields {
    display: flex;
}

.modalContent .closeBtn {
    position: absolute;
    top: -16px;
    right: -16px;
    border-radius: 50%;
    border: none;
    width: 30px;
    height: 30px;
    padding: 0;
    cursor: pointer;
}

.modalContent .closeBtn .MuiSvgIcon-root {
    height: 30px;
}

.modalContent .insuranceAccessGainedBox form button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 10px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background: rgb(25, 118, 210);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.modalContent .insuranceAccessGainedBox .formFeild {
    width: 48%;
    margin-right: 2%;
}

.modalContent .heading h5 {
    margin: inherit;
}

.modalContent h5 {
    font-size: 16px;
    text-align: center;
    margin: 26px 16px;
    font-family: "Poppins", sans-serif !important;
    font-weight: normal;
}

.modalContent .insuranceAccessGainedBox .formFeild .formFeildinside {
    width: 100%;
    background: #fff;
    border: 1px solid #e0e0e0;
    height: auto;
    margin: 5px;
    position: relative;
    transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
}

.modalContent .insuranceAccessGainedBox .formFeild label {
    background: #fff;
    padding: 0px 5px;
}

.modalContent .insuranceAccessGainedBox form .errortext {
    padding-left: 14px;
}

.is_diabetesrexburgBannerHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.is_diabetesrexburgBannerHeader p {
    color: #1dbce9 !important;
    font-family: "avenir-medium";
    font-weight: bold;
    margin: 0px;
    font-size: 28px;
    line-height: 34px;
}

.quickIntakeMobBanner.is_diabetesrexburg {
    padding-top: 5px;
}

.is_diabetesrexburgBannerHeader .randomimgwrpr {
    width: 480px;
    margin-right: 20px;
}

.is_diabetesrexburgBannerHeader.calender {
    width: 70%;
    margin: 0px auto;
}

.is_diabetesrexburgBannerHeader.calender .randomimgwrpr {
    width: 300px;
}

.is_diabetesrexburgBannerHeader .randomimgwrpr img {
    width: 100%;
}

.buttonSection.is_diabetesrexburg Button[type="submit"] {
    background: rgb(120, 225, 3);
    background: linear-gradient(180deg, rgba(120, 225, 3, 1) 0%, rgba(68, 177, 1, 1) 100%);
    color: #fff;
    border-radius: 6px;
}

.addresspara.is_diabetesrexburg .address1 {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.quickintakeBannerBlock1 {
    background: url('https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/quickintake_BannerBG.webp');
    background-size: cover;
    padding: 30px 34px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.quickintakeBannerBlock1Logo {
    width: 330px;
    padding: 30px 35px;
    background: #fff;
    border-radius: 10px;
}

.quickintakeBannerBlock1text {
    background: url('https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/quickintake_Banner_img1.webp') no-repeat right center;
    padding: 0 325px 0 15px;
    min-height: 260px;
    width: calc(100% - 346px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quickintakeBannerBlock1text .h2text {
    font-size: 25px;
    color: #fbfe00;
    text-align: left;
    text-transform: uppercase;
    line-height: 30px;
    font-style: italic;
    margin: 0;
    padding: 0 60px 0 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    text-shadow: 2px 1px 3px #465e68;
    letter-spacing: 1px;
}

.quickintakeBannerBlock1text .h3text {
    font-size: 33px;
    color: #fff;
    text-align: left;
    text-transform: uppercase;
    line-height: 36px;
    margin: 10px 0 0px 0!important;
    padding: 0 0 0 0!important;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    text-shadow: 2px 1px 3px #465e68;
    letter-spacing: 1px;
}

.quickintakeBannerBlock1text .h4text {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-shadow: 0 0 5px #625d5d;
}

.quickintakeBannerBlock1text .h4text p.address1con {
    margin: 10px 0 0 0;
    font-size: 18px;
    line-height: 20px;
    color: #fff!important;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize!important;
    font-weight: 600;
    padding-left: 28px;
}

.quickintakeBannerBlock1text .h4text p.address1con a {
    color: #fff!important;
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}

.quickintakeBannerBlock1text .h4text .address1con:nth-of-type(1) {
    background: url('https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/quickintake_Banner_icon1.webp') no-repeat left 1px;
    margin-right: 15px;
}

.quickintakeBannerBlock1text .h4text .address1con:nth-of-type(2) {
    background: url('https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/quickintake_Banner_icon2.webp') no-repeat left 1px;
}

.quickintakeBanner2 .quickintakeBannerBlock1text .h2text {
    padding-right: 0;
    font-size: 40px;
    line-height: 45px;
}

.quickintakeBanner2 .quickintakeBannerBlock1text .h3text {
    font-size: 40px;
    line-height: 46px;
    padding: 5px 0!important;
}

.quickintakeBanner2 .quickintakeBannerBlock1text .h4text {
    justify-content: left;
}

.modalPop .modalContent p.submitttedmsg {
    min-width: 400px;
    text-align: center;
    font-size: 16px;
    text-align: center;
    margin: 26px 16px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: normal;
}

.modalPop .modalContent p.submitttedmsg span {
    display: block;
    font-size: 30px;
}

.defaultSkipModal.modalPop .modalContent {
    position: inherit;
    max-width: initial;
    width: auto;
    margin: 0px !important;
}


/* {/*################################################################################ */


/* *############################# responsive {################################*/

@media screen and (max-width:1740px) {
    .quickintakeBanner2 .quickintakeBannerBlock1text .h2text {
        font-size: 36px;
        line-height: 40px;
    }
}

@media screen and (max-width:1640px) {
    .quickintakeBanner2 .quickintakeBannerBlock1text .h2text {
        font-size: 30px;
        line-height: 36px;
    }
    .quickintakeBanner2 .quickintakeBannerBlock1text .h3text {
        font-size: 36px;
        line-height: 40px;
    }
}

@media screen and (max-width:1500px) {
    .quickintakeBannerBlock1text .h3text {
        font-size: 30px;
        line-height: 36px;
        padding-right: 0;
    }
    .quickintakeBannerBlock1text .h2text {
        font-size: 24px;
        line-height: 30px;
        padding-right: 0;
    }
    .darktheme .intake_form {
        width: 96%;
    }
    .lighttheme .intake_form {
        width: 96% !important;
    }
    .dobBlock {
        width: 50%;
    }
    .dobBlock .formFeild {
        width: 96%;
    }
    .First-form .dobBlock {
        width: 49%;
    }
    .dobBlock .formFeild,
    .formdob {
        width: 98% !important;
    }
    .First-form .wrapper {
        width: 49%;
    }
    .First-form .wrapper .formFeild {
        width: 97%;
    }
    .intake_form .quick-symptom-section .quick-symptom-blocks {
        column-count: 1;
    }
    .intake_form .quick-symptom-section .quick-symptom-blocks .fields {
        flex-wrap: nowrap !important;
    }
    /* .intake_form .quick-symptom-section .quick-symptom-blocks .fields .title {
        width: 65% !important;
    } */
    .modalPop .modalContent {
        background-color: white;
        padding: 25px;
        min-height: auto;
        /* max-height: 383px; */
        /* max-width: 80vw !important; */
        /* overflow-y: scroll; */
        position: relative;
    }
    .sign .modalContent {
        /* max-width: 80% !important; */
    }
    /* .SignPart {
        width: 98%;
    } */
    .signUp_modal {
        max-width: 600px !important;
    }
}

@media screen and (max-width:1480px) {
    .quickintakeBanner2 .quickintakeBannerBlock1text .h2text {
        font-size: 28px;
        line-height: 34px;
    }
    .quickintakeBanner2 .quickintakeBannerBlock1text .h3text {
        font-size: 32px;
        line-height: 36px;
    }
}

@media screen and (max-width:1400px) {
    .quickintakeBannerBlock1text .h3text {
        font-size: 26px;
        line-height: 30px
    }
    .quickintakeBannerBlock1text .h2text {
        font-size: 20px;
        line-height: 24px;
    }
}


/* @media screen and (max-width:1300px){
    .intake_form .quick-symptom-section .quick-symptom-blocks .fields .title {
        width: 60% !important;
    }
} */

@media screen and (max-width:1199px) {
    .quickintakeBanner2 .quickintakeBannerBlock1text .h2text {
        font-size: 25px;
        line-height: 34px;
    }
    .quickintakeBanner2 .quickintakeBannerBlock1text .h3text {
        font-size: 30px;
        line-height: 34px;
    }
    .quickintakeBannerBlock1text .h3text {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 10px!important;
    }
    .quickintakeBannerBlock1text .h2text {
        font-size: 25px;
        line-height: 30px;
    }
    .quickintakeBannerBlock1 {
        display: block;
        background: rgb(0, 158, 247);
        background: linear-gradient(180deg, rgba(0, 158, 247, 1) 0%, rgba(0, 132, 249, 1) 100%);
    }
    .quickintakeBannerBlock1text {
        width: auto;
        padding-left: 0;
    }
    .quickintakeBannerBlock1Logo {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 35px;
    }
    .websize-footerbanner {
        display: none;
    }
    .intake_form .form .otherDetails-blocks .box {
        display: block;
        width: auto !important;
        /* padding: 10px; */
    }
    .insurance-field {
        align-items: center;
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start;
        width: 100% !important;
    }
    .insurance-field .formFeild {
        width: 100% !important;
    }
    .insurance-field .insurancename {
        width: 98% !important;
    }
    .darktheme .intake_form .otherDetails-blocks {
        width: auto;
    }
    .intake_form .form .otherDetails-blocks .box .form-radio-input {
        padding-left: 0px;
    }
    .darktheme .intake_form .otherDetails-blocks {
        display: block;
    }
    .formFeildDiv {
        width: 48%;
    }
    .lighttheme .intake_form .patientIntakeFormheder .formFeild {
        width: 48%;
    }
}

@media screen and (max-width:1010px) {
    .lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields .title {
        max-width: 88%;
        padding-right: 5px;
    }
    .lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields {
        margin: 10px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #e1e1e1;
        padding: 5px;
        flex-wrap: wrap !important;
    }
    /* .darktheme .intake_form .patientIntakeFormheder .formFeild {
        width: 48%;
    } */
    .lighttheme .intake_form .patientIntakeFormheder .formFeild {
        width: 100%;
    }
    .dobController {
        width: 48% !important;
    }
    .darktheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin: 5px 0;
    }
    .lightheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin: 5px 0;
    }
    .darktheme .intake_form .insurance-info .formFeild {
        width: 99%;
    }
    .lightheme .intake_form .insurance-info .formFeild {
        width: 99%;
    }
    .lighttheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin: 5px 0;
    }
    .lighttheme .intake_form .insurance-info .formFeild {
        width: 100%;
    }
    .dobController .formdob {
        width: 100% !important;
    }
}

@media screen and (max-width:898px) {
    .lighttheme .intake_form .quick-symptom-section .quickintake3row {
        column-count: inherit;
        display: block;
        padding: inherit;
        flex-wrap: inherit;
        justify-content: inherit;
    }
    .lighttheme .intake_form .quick-symptom-section .quickintake3row .fields {
        width: auto;
        margin: inherit;
    }
    .groupfld {
        width: 98%;
    }
    .form .First-form .wrapper {
        overflow: inherit;
        width: 100%;
    }
    /* ---------------- Light theme Start here ---------------------- */
    .lighttheme .First-form .dobBlock {
        width: 100%;
    }
    .lighttheme .intake_form {
        width: 96% !important;
    }
    .lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks {
        column-count: 1;
    }
    /* .lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields {
        flex-wrap: nowrap !important;
    } */
    /* .lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields .title {
        width: 100% !important;
    } */
    .lighttheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin: 5px 0;
    }
    .lighttheme .intake_form .insurance-info .formFeild {
        width: 98%;
    }
    .lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields {
        margin: 10px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #e1e1e1;
        padding: 5px;
        flex-wrap: wrap !important;
    }
    /* ----- insurance ----- */
    .insurance-field .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.4375em;
    }
    .lighttheme .insurance-info .ins {
        font-size: 18px;
        margin-top: 10px;
        padding-left: 5px;
    }
    .lighttheme .intake_form .patientIntakeFormheder .insurance-info h3 {
        font-size: 20px;
    }
    .lighttheme .intake_form .insurance-info .insurance-field {
        display: flex;
        flex-direction: column;
    }
    /* ---------------------- */
    /* ------- Signature Section ------- */
    .lighttheme .authNsign .authfeilds .formFeild {
        width: 98%;
    }
    .lighttheme .intake_form .authNsign .signbtn {
        font-size: inherit;
        position: relative;
        right: 0;
        border-radius: 0px;
    }
    .lighttheme .intake_form .authNsign .signSec {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .lighttheme .intake_form .authNsign .authfeilds {
        display: flex;
        flex-direction: column;
    }
    .authfeilds .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.9375em;
        font-size: 16px;
        position: inherit;
        /* top: 8px; */
    }
    /* ---------------------------------- */
    /* ------------------------ End HEree -------------------- */
    .First-form .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.4375em;
        font-size: 16px;
        position: inherit;
        top: 6px;
    }
    .First-form .dobBlock {
        width: 100%;
    }
    .intake_form .patientIntakeFormheder .MuiInputBase-formControl {
        font-size: 16px;
        height: 52px;
    }
    .intake_form .patientIntakeFormheder .MuiInputBase-formControl::placeholder {
        font-size: 50px;
    }
    .intake_form .patientIntakeFormheder .First-form {
        display: flex;
        /* flex-direction: column; */
    }
    .intake_form .patientIntakeFormheder .First-form .formFeild {
        width: 98%;
    }
    .darktheme .intake_form .quick-symptom-section .quick-symptom-blocks {
        column-count: 1;
    }
    .darktheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields {
        flex-wrap: nowrap !important;
    }
    /* .darktheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields .title {
        width: 65% !important;
    } */
    .darktheme .intake_form .patientIntakeFormheder .formFeild {
        width: 99%;
    }
    /* ------- Signature Section ------- */
    .darktheme .authNsign .authfeilds .formFeild {
        width: 98%;
    }
    .darktheme .intake_form .authNsign .signbtn {
        width: 100%;
        font-size: inherit;
        border-radius: 0px;
    }
    .authfeilds .signSec .Signbox {
        /* width: 75%; */
        width: 100%;
        /* border: solid 1px #e0e0e0 !important; */
    }
    .darktheme .intake_form .authNsign .signSec {
        width: 98%;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .darktheme .intake_form .authNsign .authfeilds {
        display: flex;
        flex-direction: column;
    }
    .authfeilds .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.9375em;
        font-size: 16px;
        position: inherit;
        /* top: 8px; */
    }
    /* ---------------------------------- */
    /* ----- insurance ----- */
    .darktheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin: 5px 0;
    }
    .insurance-field .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.4375em;
    }
    .intake_form .insurance-info .ins {
        font-size: 18px;
        margin-top: 10px;
        padding-left: 5px;
    }
    .darktheme .intake_form .patientIntakeFormheder .insurance-info h3 {
        font-size: 20px;
    }
    .darktheme .intake_form .insurance-info .insurance-field {
        display: flex;
        flex-direction: column;
    }
    .darktheme .intake_form .insurance-info .formFeild {
        width: 99%;
    }
    /* ---------------------- */
    .darktheme .First-form .dobBlock {
        width: 99%;
    }
    .intake_form .patientIntakeFormheder .First-form .heading {
        padding: 6px 0;
        margin: 10px 0;
    }
    .formFeildDiv {
        width: 47%;
    }
    .dobController {
        width: 47% !important;
    }
}

@media screen and (max-width:860px) {
    .quickintakeBannerBlock1text {
        display: block;
    }
    .quickintakeBannerBlock1text {
        display: block;
        background-position: center bottom;
        padding: 0 0 290px 0;
    }
    .quickintakeBannerBlock1 {
        padding: 25px;
    }
}

@media screen and (max-width:672px) {
    .darktheme .intake_form .patientIntakeFormheder .insurance-info h3 {
        font-size: 17px;
    }
    .intake_form .patientIntakeFormheder .MuiInputBase-formControl {
        font-size: 18px;
    }
    .darktheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin: 5px 0;
    }
    .darktheme .intake_form .insurance-info {
        font-size: 20px;
    }
    .darktheme .intake_form .insurance-info span {
        padding-left: 0;
    }
    .darktheme .intake_form .otherDetails-blocks .heading h3 {
        font-size: 18px;
        padding: 7px;
    }
    .darktheme .intake_form .otherDetails-blocks {
        display: flex;
        flex-direction: column;
        font-size: 12px;
    }
    .darktheme .intake_form .otherDetails-blocks input {
        height: 10px;
        width: 10px;
    }
    .darktheme .intake_form .otherDetails-blocks {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        padding: 3px;
    }
    /* .lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields {
        flex-direction: column !important;
    } */
    /* .darktheme .intake_form .quick-symptom-section .quick-symptom-blocks .fields {
        flex-direction: column !important;
    } */
    .darktheme .intake_form .quick-symptom-section .authNsign .heading {
        font-family: avenir-medium;
        text-transform: uppercase;
        font-size: 13px;
        text-align: center;
        padding: 20px 6px;
    }
    .darktheme .intake_form .quick-symptom-section .buttonSection {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
    }
    .darktheme .intake_form .quick-symptom-section .quick-symptom-blocks {
        padding: 0;
    }
    .darktheme .intake_form .authNsign .authfeilds {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .lighttheme .intake_form {
        width: auto !important;
    }
}

@media screen and (max-width:671px) {
    .termsNcond {
        max-height: 25Vh;
    }
    .SignPart .signFeilds {
        display: block;
    }
    .signFormat {
        width: auto;
        margin: 5px 0 0 0;
    }
    .signFeilds .name_type {
        width: 100%!important;
    }
    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{ display: ;} */
    .darktheme .intake_form .authNsign .heading {
        font-size: 20px;
    }
    .darktheme .intake_form .patientIntakeFormheder {
        padding: 8px;
    }
    .First-form .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.4375em;
    }
    .First-form .dobBlock {
        width: 100%;
    }
    .intake_form .patientIntakeFormheder .MuiInputBase-formControl {
        font-size: 16px;
    }
    .intake_form .patientIntakeFormheder .MuiInputBase-formControl::placeholder {
        font-size: 50px;
    }
    .intake_form .patientIntakeFormheder .First-form {
        display: flex;
        flex-direction: row;
    }
    .intake_form .patientIntakeFormheder .First-form .formFeild {
        width: 98%;
    }
    .groupfld {
        width: 98%;
    }
    /* ----- insurance ----- */
    .insurance-field .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.4375em;
    }
    .darktheme .intake_form .insurance-info {
        font-size: 24px;
        /* margin: 48px 0 !important; */
    }
    .darktheme .intake_form .patientIntakeFormheder .insurance-info h3 {
        font-size: 20px;
    }
    .darktheme .intake_form .insurance-info .insurance-field {
        display: flex;
        flex-direction: column;
    }
    .darktheme .intake_form .insurance-info .formFeild {
        width: 99%;
    }
    /* ---------------------- */
    /* ------------- otherDetails-blocks --------- */
    .darktheme .intake_form .otherDetails-blocks {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        padding: 8px;
    }
    .darktheme .intake_form .otherDetails-blocks input {
        height: 14px;
        width: 100%;
    }
    .darktheme .intake_form .otherDetails-blocks .heading h3 {
        font-size: 20px;
    }
    /* ------------------------------------------- */
    /* -------- symptom ------- */
    .darktheme .intake_form .quick-symptom-section {
        display: flex;
        flex-direction: column;
    }
    .darktheme .intake_form .quick-symptom-section .symptom-blocks {
        width: 100%;
        font-size: 45px;
    }
    .darktheme .intake_form .quick-symptom-section .symptom-blocks .heading {
        width: 100%;
        font-size: 45px;
    }
    .darktheme .intake_form .quick-symptom-section .symptom-blocks .fields input {
        height: 36px;
        width: 36px;
    }
    /* 
    .authfeilds .signSec .Signbox{
        width: 60%;
    } */
    /* .authfeilds .signSec .Signbox label{ position: inherit!important; max-width: inherit;}

    .authfeilds .signSec .Signbox input{ padding: 0!important;} */
    /* ------------------------- */
}

@media screen and (max-width:640px) {
    .formFeildDiv {
        width: 100%;
    }
    .dobController {
        width: 100% !important;
    }
    .modalPop .modalContent p.submitttedmsg {
        width: 90%;
        margin: 0px auto !important;
        margin-top: 10px !important;
        min-width: auto;
    }
}

@media screen and (max-width:460px) {
    .quickintakeBannerBlock1Logo {
        padding: 10px 25px;
        background: #fff;
        border-radius: 10px;
        max-width: 100%;
    }
    .quickintakeBannerBlock1Logo img {
        width: 100%;
    }
    .quickintakeBannerBlock1text {
        display: block;
        background-position: center bottom;
        padding: 0 0 84% 0;
        background-size: 100%;
    }
}

@media screen and (max-width:400px) {
    .sign .btngrp {
        display: block;
        text-align: center;
    }
    .css-gepadz-MuiTypography-root {
        font-size: 28px !important;
    }
    .groupfld .formFeildinside {
        width: 28%;
    }
}

.MuiOutlinedInput-root {
    outline: none !important;
    top: 0 !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.MuiOutlinedInput-root fieldset {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.darktheme .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    color: #fff;
}

.darktheme .quick-symptom-blocks .fields .title {
    padding-right: 10px;
}

.name_type input {
    background: #e8f0fe !important;
    /* display: none; */
}

.hidemobile {
    display: none !important;
}

.intake_form_banner_wrapper1_text1BG {
    padding-bottom: 10px;
    ;
}

.insurance-field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.insurance-field .fields.box {
    width: 26%;
}

.insurance-field .formFeild {
    width: 50%;
}