.main_wrapper{    background: linear-gradient(360deg, rgba(116,130,137,1) 0%, rgb(0 8 12) 100%);
    overflow: hidden;}

.banner_assets_block1{ background: rgb(200,212,218);
    background: linear-gradient(180deg, rgba(200,212,218,1) 0%, rgba(217,226,231,1) 99%); margin: 15px; padding: 15px; border: solid 1px #fff;  border-radius: 15px; }  


    .banner_assets_block1 p{ margin: 0; padding: 0;}
.banner_container{ display: flex; justify-content: center;
   
    width: auto!important; } 
.img_txt_container .image img{width: 100%;display: block;}

.img_txt_container .image {    margin-bottom: 10px;
    cursor: pointer;}

.pece_bnr_img{width: 1500px;}

/* .img_txt_container{ 
    padding: 0px;
    border-radius: 10px;
    margin: 0.5%;
    width: 32.3%!important;
    max-width: inherit!important;
    flex-basis: initial!important;
} */
.bnnr_txt_cntainr{width: 48% !important;}
.img_txt_wrp{background: rgb(1,138,251);
    background: linear-gradient(90deg, rgba(1,138,251,1) 0%, rgba(3,169,245,1) 100%);padding: 10px;border-radius: 8px 8px 0 0;}

.img_txt_container .txt {background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/lighttheme_titlebarlineimg1.webp);
    background-repeat: no-repeat;
    background-position: right bottom;
    padding: 9px 10px 10px 16px;
    background-color: #ffe613;
    color: #000;text-align: center;background-size: cover;}  
.img_txt_container .txt p{
    font-size:25px;
    line-height: 32px;
    font-weight: 600;
}

.logo_wrapper{background-color: #303538;
    padding: 10px;
    border-radius:0 0 8px 8px;  width: 100%!important;
    max-width: inherit!important; align-items: center;}  

.logo_wrapper .only_logo_wrp img{ width: 30px; border-radius: 100px;  margin: 0 5px; cursor: pointer;}

.click_wrp{        text-align: right;}

.click_wrp a {
    background: rgb(58,59,59);
    background: linear-gradient(180deg, rgba(58,59,59,1) 0%, rgba(6,17,22,1) 99%);
    margin: 5px;
    display: inline-block;
    color: #fff;
    padding: 5px 15px;
    border-radius: 50px;
    border: 1px solid #576268;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Roboto', 'sans-serif', 'Montserrat';
    font-weight: 500; cursor: pointer;
}

 
.dwnldlogo img{ width: 16px;}

@media (max-width: 1360px){
.banner_assets_block2 .img_txt_container .txt {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
} }
@media (max-width:1199px){
.img_txt_container .txt p {
    font-size: 26px;
    line-height: 30px; 
}

 
    .img_txt_container{  
        width: 99%!important; 
    }

}

@media (max-width:899px){
.logo_container{ display: block; text-align: center;  width: 100%!important; }

.click_wrp{ display: block; text-align: center;  width: 100%!important;  margin-top: 15px;}
}

@media (max-width:767px){
    .logo_container{ display: block; text-align: center;  width: 50%!important; }
    .click_wrp{ display: block; text-align: center;  width: 50%!important;  margin-top: 0px;}
}
