.navigation{
    width: 1400px;
    margin: 75px auto;
    background: black;
    padding: 15px;
}

 .navigation .nav{
    cursor: pointer;
    color: white;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

