p {
    margin: 0;
}

.common_width {
    width: 1734px;
    margin: 0 auto;
}

.privacy_sub_sec_wrapper h1 {
    font-size: 25px;
    font-weight: 700;
    background: linear-gradient(180deg, rgba(5, 91, 177, 1) 11%, rgba(6, 62, 143, 1) 55%, rgba(7, 34, 112, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* filter: drop-shadow(0px 0px 2px #000000de); */
    line-height: 31px;
    /* display: inline; */
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 8px;
}

.privacy_sub_sec_wrapper h2 {
    font-size: 35px;
    font-weight: 800;
    background: linear-gradient(180deg, rgba(5, 91, 177, 1) 11%, rgba(6, 62, 143, 1) 55%, rgba(7, 34, 112, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* filter: drop-shadow(0px 0px 2px #000000de); */
    line-height: 41px;
    /* display: inline; */
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 20px;
}

.privacy_sub_sec_wrapper p {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: #000;
    /* text-shadow: 0px 0px 2px #000000de; */
    margin-bottom: 15px;
}

.privacy_sub_sec_wrapper {
    margin-bottom: 30px;
}

.privacy_main_wrapper {
    background: linear-gradient(131deg, rgba(243, 246, 248, 1) 0%, rgba(234, 244, 248, 1) 43%, rgba(193, 232, 250, 1) 100%);
    background-size: cover;
    padding: 40px 0;
}

.diabetes_rxbrg_logo {
    margin-bottom: 30px;
    width: fit-content;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
}

.diabetes_rxbrg_logo img {
    display: block;
    max-width: 100%;
}

.privacy_sub_wrapper {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
}

.privacy_sub_sec_wrapper ul li {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: #000;
    /* text-shadow: 0px 0px 2px #000000de; */
    margin-bottom: 15px;
}

.privacy_sub_sec_wrapper p a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width:1780px) {
    .common_width {
        width: 96%;
    }
}

@media (max-width:1199px) {
    .privacy_main_wrapper {
        padding: 20px 0;
    }

    .diabetes_rxbrg_logo {
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .privacy_sub_wrapper {
        padding: 20px;
    }
}

@media (max-width:499px) {
    .privacy_sub_sec_wrapper h1 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 8px;
    }

    .privacy_sub_sec_wrapper h2 {
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 10px;
    }

    .privacy_sub_sec_wrapper p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .privacy_sub_sec_wrapper ul li {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
    }

}