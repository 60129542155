.calender_body {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.calender_block {
    max-width: inherit !important;
    width: 30.3% !important;
    margin: 10px 0.5% !important;
    background: #f2f8fa;
    padding: 0.5% !important;
    border: solid 1px #f5e4c7 !important;
    word-break: break-all;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 2px 1px white inset, 0px -2px 8px white, 0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
    position: relative;
}

.calender_block.bookNowModal {
    width: 100% !important;
    background: none;
    box-shadow: none;
    border: none !important;
    word-break: normal;
    margin-bottom: 0px !important;
}

.calender_block.bookNowModal:hover {
    transform: none !important;
}

.calender_block:hover {
    transform: scale(1.1);
}

.modalblock .editAttendeeDiv {
    margin: 6px auto;
}

.modalblock .editAttendeeDiv h4 {
    padding-top: 0px;
    padding-bottom: 5px;
}

.modalblock .editAttendeeDiv .saveAttendeBtn {
    margin-top: 3px;
    color: #fff;
    background-color: #1976d2;
    font-size: 13px;
    padding: 2px 4px;
    min-width: 50px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.calender_block h2 {
    margin: 0;
    display: block;
    background: #00abff;
    background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
    font-size: 18px;
    padding: 8px 5px;
    font-size: 18px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 6px;
}

.calender_block p {
    margin: 0;
    padding: 6px 0;
}

.calender_block p span {
    color: #3f86ce;
}

.calender_block p span strong {
    color: #09427c;
}

.calender_block .stateCityZipBlock p {
    display: inline-block;
    margin-right: 6px;
}

.calender_body.formCls.is_diabetesrexburg .calender_block h2 {
    background: rgb(0, 158, 247);
    background: linear-gradient(180deg, rgba(0, 158, 247, 1) 0%, rgba(0, 132, 249, 1) 100%);
}

.formCls.is_diabetesrexburg button,
.actionBtnDiv.is_diabetesrexburg .subBTN.searchBtn Button,
.actionBtnDiv.is_diabetesrexburg .subBTN.refreshBtn Button {
    /* background: rgb(120, 225, 3);
    background: linear-gradient(180deg, rgba(120, 225, 3, 1) 0%, rgba(68, 177, 1, 1) 100%); */
    background: #0070ce;
    color: #fff;
    border-radius: 6px;
}

.addemailbox p {
    display: flex;
}

.addemailbox p span {
    width: 190px;
}

.addemailbox p span.flexSpan {
    color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.addemailbox p span.flexSpan button {
    padding: 0;
}

.addemailbox p span.flexSpan button svg {
    width: 20px;
    height: 20px;
}

.formCls .multipleCheckbox .MuiFormControl-root {
    flex: 1 0 100%;
    box-shadow: none;
}

.formCls .eventDetails .MuiFormLabel-root {
    position: relative;
    height: 50px;
    width: auto;
    background: #626fb1;
    text-align: left;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transform: none;
    text-transform: capitalize;
}

.formCls .multipleCheckbox .MuiFormControlLabel-label {
    color: #000;
}

.formCls .multipleCheckbox .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
    color: rgb(0 0 0);
    font-weight: 600;
}

.formCls .singleCheckbox .MuiFormControl-root {
    color: #000;
    flex: 1 0 100%;
    box-shadow: none;
}

.formCls .singleCheckbox .MuiInputLabel-root {
    color: #000;
    font-weight: 600;
}

.formCls .singleCheckbox {
    padding: 0 8px !important;
}

.ContentWrapper {
    width: 1780px;
    margin: 20px auto 0;
    display: block;
}

.connectedemail {
    margin: 0;
    background: #00abff;
    background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
    font-size: 18px;
    padding: 18px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
}

.connectedemail h2 {
    margin: 0;
    margin-bottom: 15px;
}

.calenderinfo {
    width: 450px;
}

.calenderinfo p {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}

.calenderinfo p span {
    text-transform: initial!important;
}

.buttonsetToolbar {
    margin: 0;
    background: #c9d2d9;
    font-size: 18px;
    padding: 18px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 20px;
}

.buttonsetToolbar button {
    margin: 5px
}

.buttonsetToolbar button a,
.buttonsetToolbar a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}

.buttonsetToolbar button:hover a,
.buttonsetToolbar a:hover {
    color: #000;
}

.formCls {
    margin-top: 20px;
}

.reactTable .calendarAvailabilityTable {
    width: 100%;
}


/*--------amitava 5/17/2022-----------*/

.addemailbox {
    position: relative;
    overflow: hidden;
}

.addemailbox input {
    margin: 5px 0;
}

.addemail {
    padding-right: 10%!important;
}

.addemailBTN {
    width: 10%;
    height: 38px;
    position: absolute;
    right: 0;
    bottom: 5px;
    border: none;
    background: #08507f;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}

.modalblock .shareholder.addemailbox .firstIndex {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

.modalSUBbtn_wrapper {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.modalSUBbtn_wrapper .modalSUBbtn {
    margin: 2px;
    color: #fff;
    background-color: #1976d2!important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.banner_IMG {
    margin: 0 auto;
}

.banner_IMG img {
    width: 100%;
    display: block;
}

.form_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #f3f1f1;
    border-radius: 6px;
    border: solid 1px #c9d2d9;
    box-shadow: 0 0 2px #c9d2d9;
    padding: 15px;
    margin: 14px 0 0 0;
    align-items: center;
}

.form_wrapper .subBTN Button {
    height: 46px;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-top: 32px;
    line-height: 54px;
    display: block;
    padding: 0;
    border-radius: 100px;
    width: 46px;
    min-width: inherit;
    background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
    margin-left: 15px;
}

.form_wrapper .subBTN.searchBtn Button,
.form_wrapper .subBTN.refreshBtn Button {
    width: auto;
    line-height: 40px;
    padding: 0px 12px;
    height: 40px;
}

.subBTN .MuiSvgIcon-root {
    width: 40px;
    height: 48px;
}

.form_wrapper .form_group,
.form_wrapper .actionBtnDiv {
    width: 240px;
    margin: 5px 10px;
}

.form_wrapper .daterangecls.form_group {
    width: 400px;
}

.form_wrapper .actionBtnDiv {
    display: flex;
}

.form_wrapper .form_group .MuiOutlinedInput-root {
    width: 100%;
}

.form_wrapper .form_group label {
    display: block;
    margin-bottom: 10px;
    color: #236bb0;
}

.form_wrapper .form_group label.MuiInputLabel-formControl {
    color: #000;
}

.form_wrapper .MuiInputBase-input {
    background: #fff;
}

.submitbtnsection {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 10px;
}

.submitbtnsection Button[type="submit"] {
    display: inline-block;
}

.BioEnergetics_banner1 {
    /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_1BG.jpg);
    background-position: center top; background-size: 100% 100%;
    background-repeat: no-repeat;    */
    background: rgb(17, 132, 192);
    background: linear-gradient(90deg, rgba(17, 132, 192, 1) 0%, rgba(99, 50, 222, 1) 100%);
    border-radius: 15px;
}

.banner_textblock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 27px;
    min-height: 238px;
}

.banner_textblock .banner_textblock_logo {
    width: 20%;
}

.banner_textblock .banner_textblock_logo span {
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 15px;
    padding: 10px 14px 5px 10px;
}

.banner_textblock .banner_textblock_logo span img {
    width: 100%;
}

.banner_textblock h2 {
    margin: 0;
    padding: 0;
    font-size: 45px;
    color: #ffffff;
    width: 75%;
    text-shadow: 0 0 2px #465a34;
}

.banner_textblock h2 p {
    display: block;
    font-size: 26px;
    color: #000000;
    margin: 0;
    padding: 0;
    padding-top: 12px;
    font-weight: normal;
    text-shadow: none;
}

.BioEnergetics_banner1 .banner_textblock p {
    color: #ffffff;
}

.PECE_banner1 {
    /* background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1BG.jpg);
    background-position: center top; background-size: 100% 100%;
    background-repeat: no-repeat;   */
    background: rgb(49, 216, 103);
    background: linear-gradient(90deg, rgba(49, 216, 103, 1) 0%, rgba(217, 226, 95, 1) 100%);
    border-radius: 15px;
}

.BioEnergetics_banner1_bg {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_1_topBG.png), url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_1_bottomBG.png);
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
    border-radius: 15px;
}

.PECE_banner1_bg {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1_topBG.png), url(https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_1_bottomBG.png);
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
    border-radius: 15px;
}


/* ---------- Load More ------------- */

.loadMoreSection {
    display: flex;
    justify-content: center;
    margin: 15px 0px;
}


/* ---------- Cancel Slot------------ */

.cancelSlotBtnSection {
    display: flex;
    justify-content: center;
}


/*------------------------------------*/

@media screen and (max-width: 1800px) {
    .ContentWrapper {
        width: 96%;
    }
    /* .form_wrapper .subBTN {
        clear: both;
        overflow: hidden;
        margin-top: 10px;
        display: block;
        width: 100%;
        text-align: center;
    }
    .form_wrapper .subBTN Button {
        margin-top: 0px;
        display: inline-block;
        margin-bottom: 5px;
    } */
}

@media screen and (max-width: 991px) {
    .formCls .formInline .formGroup .countDiv__column--col6 {
        flex: 1 0 98%!important;
    }
}

@media screen and (max-width: 767px) {
    .calender_block {
        width: 99% !important;
    }
    .connectedemail {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 540px) {
    .buttonsetToolbar {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1460px) {
    .banner_textblock .banner_textblock_logo {
        width: 25%;
    }
    .banner_textblock h2 {
        width: 70%;
    }
}

@media screen and (max-width: 1360px) {
    .banner_textblock .banner_textblock_logo {
        width: 28%;
    }
    .banner_textblock h2 {
        width: 67%;
    }
}

@media screen and (max-width: 1199px) {
    .BioEnergetics_banner1_bg {
        background-image: none;
    }
    .PECE_banner1_bg {
        background-image: none;
    }
    .banner_textblock {
        padding: 15px;
    }
    .banner_textblock {
        display: block;
        text-align: center;
    }
    .banner_textblock .banner_textblock_logo {
        width: auto;
        display: inline-block;
        margin-bottom: 15px;
    }
    .banner_textblock h2 {
        width: auto;
        text-align: center;
    }
    .banner_textblock .banner_textblock_logo span {
        width: auto;
    }
    .banner_textblock .banner_textblock_logo span img {
        width: auto;
        max-width: 90%;
    }
    .banner_textblock h2 {
        font-size: 30px;
    }
    .banner_textblock h2 p {
        font-size: 20px;
    }
    .calender_body{ justify-content: space-between;}
    .calender_block {
        width: calc(50% - 15px )!important;
        /* display: block; */
        padding: 10px!important;
    }
    .formCls button {
        margin: 0!important;
    }
    /* .calender_body {
        display: block;
    } */
    /* .form_wrapper .subBTN {
        clear: both;
        overflow: hidden;
        margin-top: 10px;
        display: block;
        width: 100%;
        text-align: center;
    }
    .form_wrapper .subBTN Button {
        margin-top: 0px;
        display: inline-block;
        margin-bottom: 5px;
    } */
}

@media screen and (max-width: 991px) {
    .formCls .formInline .formGroup .countDiv__column--col6 {
        flex: 1 0 98%!important;
    }
}

@media screen and (max-width: 767px) {
    /* .calender_block {
        width: 99% !important;
    } */
    .connectedemail {
        flex-wrap: wrap;
    }

    .calender_block {
        width: calc(50% - 10px )!important; 
    }
}

@media screen and (max-width: 540px) {
    .buttonsetToolbar {
        flex-wrap: wrap;
    }

    .calender_block {
        width: calc(100% - 10px )!important; 
    }
}

.darkThemeBanner_block {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/appointment-dark_BG.webp) no-repeat left top;
    background-size: cover;
    padding: 45px;
    padding-bottom: 0px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
}

.darkThemeBanner_block_con {
    border: solid 8px #ccc;
    border-radius: 15px;
    background: rgb(226, 203, 35);
    background: linear-gradient(190deg, rgba(226, 203, 35, 1) 0%, rgba(254, 200, 13, 1) 100%);
}

.darkThemeBanner_block_con h2 {
    margin: 0;
    padding: 25px;
    padding-right: 22%;
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/appointment-dark1.webp) no-repeat right bottom;
    font-size: 40px;
    color: #000;
    font-weight: normal;
    border: solid 8px #fff;
    border-radius: 6px;
}

.darkThemeBanner_block_con h2 b {
    color: #1f2cd5;
}

.darkThemeBanner_block_con h2 span {
    font-weight: bold;
}

.darkThemeBanner_block_shadow {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/appointment-dark2.webp) no-repeat left bottom;
    padding-bottom: 45px;
    background-size: 100%;
}

.lightThemeBanner_block {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/quickintake_banner_mainbg2.webp) no-repeat left top;
    background-size: cover;
    padding: 45px;
    padding-bottom: 0px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
}

.lightThemeBanner_block_con {
    border: solid 8px #ccc;
    border-radius: 15px;
    background: rgb(20, 167, 241);
    background: linear-gradient(267deg, rgba(20, 167, 241, 1) 0%, rgba(22, 107, 166, 1) 100%);
}

.lightThemeBanner_block_con h2 {
    margin: 0;
    padding: 25px;
    padding-right: 22%;
    font-size: 40px;
    color: #fff;
    font-weight: normal;
    border: solid 8px #fff;
    border-radius: 6px;
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/appointment-dark2shadow.webp) no-repeat right bottom;
}

.lightThemeBanner_block_con h2 b {
    color: #1f2cd5;
}

.lightThemeBanner_block_con h2 span {
    font-weight: bold;
}

.lightThemeBanner_block_shadow {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/appointment-dark2.webp) no-repeat left bottom;
    padding-bottom: 45px;
    background-size: 100%;
}

.lightThemeBanner_IMG {
    position: absolute;
    right: 30px;
    bottom: 0;
    width: 25%;
}

.darkThemeBanner_IMG {
    position: absolute;
    right: 30px;
    bottom: 0;
    width: 25%;
}

@media screen and (max-width:1800px) {
    .lightThemeBanner_IMG {
        width: 30%;
    }
    .lightThemeBanner_block_con h2 {
        padding-right: 27%;
    }
    .darkThemeBanner_IMG {
        width: 30%;
    }
    .darkThemeBanner_block_con h2 {
        padding-right: 27%;
    }
}

@media screen and (max-width:1580px) {
    .lightThemeBanner_block_con h2 {
        font-size: 34px;
    }
    .darkThemeBanner_block_con h2 {
        font-size: 34px;
    }
}

@media screen and (max-width:1380px) {
    .lightThemeBanner_block_con h2 {
        font-size: 26px;
    }
    .darkThemeBanner_block_con h2 {
        font-size: 26px;
    }
}

@media screen and (max-width:1199px) {
    .lightThemeBanner_IMG {
        display: none;
    }
    .lightThemeBanner_block_con h2 {
        padding: 25px;
    }
    .darkThemeBanner_IMG {
        display: none;
    }
    .darkThemeBanner_block_con h2 {
        padding: 25px;
    }
    .darkThemeBanner_block {
        padding: 4%;
    }
    .darkThemeBanner_block_shadow {
        padding: 0;
        background: none;
    }
    .lightThemeBanner_block {
        padding: 4%;
    }
    .lightThemeBanner_block_shadow {
        padding: 0;
        background: none;
    }
}

@media screen and (max-width: 750px) {
    .as_formstyle .form_group {
        width: 100%;
    }
    .as_formstyle .form_wrapper .daterangecls.form_group {
        width: 100%;
    }
    .as_formstyle .form_wrapper .daterangecls.form_group .css-17vdz66 .MuiFormControl-root {
        width: 47%;
    }
}

@media screen and (max-width: 540px) {
    .darkThemeBanner_block_con h2 {
        font-size: 30px;
        padding: 15px;
    }
    .darkThemeBanner_block {
        padding: 15px;
    }
    .lightThemeBanner_block_con h2 {
        font-size: 30px;
        padding: 15px;
    }
    .lightThemeBanner_block {
        padding: 15px;
    }
}

@media screen and (max-width: 380px) {
    .darkThemeBanner_block_con h2 {
        font-size: 24px;
    }
    .lightThemeBanner_block_con h2 {
        font-size: 24px;
    }
}