.diabaticPaymentContainer {
    background: url(../../assets/diabatic_payment_bg.png) center center;
    background-size: cover;
    min-height: 100vh;
}

.diabaticPaymentSubContainer {
    width: 90%;
    margin: 0px auto;
    padding: 25px 0;
}

.diabaticPaymentSubContainer .diabaticPaymentSubContainerHeading h1 {
    font-family: 'avenir-medium';
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    margin: 0px auto;
    font-size: 34px;
    margin-left: 40px;
    color: #012956;
    font-weight: bolder;
}

.diabaticPaymentContainer .DecisionDocLogo {
    width: 186px;
    margin: 0px auto;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: middle;
}

.patientInformation .patientInformationContent {
    /* background: #efefef; */
    padding: 16px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #b3daff;
}

.patientInformation .patientInformationContent .patientInformationName,
.patientInformation .patientInformationContent .patientInformationEmail {
    font-size: 16px;
    margin-bottom: 6px;
}

.patientInformation .patientInformationContent .patientInformationEmail {
    margin-left: 16px;
}

.patientInformation .patientInformationContent span {
    color: #fff;
    font-weight: bold;
    line-height: 20px;
    font-size: 18px;
}

.paymentSection {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.billingSection,
.orderSummarySection {
    background: rgb(6, 69, 136);
    background: linear-gradient(90deg, rgba(6, 69, 136, 1) 0%, rgba(6, 60, 123, 1) 100%);
    padding: 40px;
}

.billingSection {
    width: 76%;
}

.billingSection h2 {
    background: url(../../assets/diabatic_payment_heading_bg.png) left center;
    background-size: 100%;
    margin: 0px;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 30px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.billingSection .MuiFormControl-root {
    background: #fff;
    margin: 0px;
    box-shadow: 0 0px 6px rgb(6, 68, 135, 0.5) inset;
}

.billingSection .billingState label[data-shrink='true'],
.giftModalContent .billingState label[data-shrink='true'] {
    /* padding: 6px 14px; */
    transform: translate(14px, -9px) scale(0.75);
    z-index: 1;
}

.billingSection .billingState label,
.giftModalContent .billingState label {
    z-index: auto;
}

.billingSection .billingState .MuiSelect-select,
.giftModalContent .billingState .MuiSelect-select {
    padding: 16.5px 14px;
}

.giftModalContent .billingState .MuiInputBase-root {
    background-color: rgba(0, 0, 0, 0.0);
}

.emailAddressDiv,
.stateCityZipDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.billingSection .billingName,
.billingSection .billingEmail {
    width: 49%;
    margin-top: 15px !important;
    margin-bottom: 8px;
}

.billingSection .billingAddress {
    width: 32%;
    margin-top: 15px !important;
    margin-bottom: 23px !important;
}

.stateCityZipDiv .billingCity,
.stateCityZipDiv .billingState,
.stateCityZipDiv .billingZip {
    width: 32%;
    margin-bottom: 15px !important;
}

.billingSection .MuiFormLabel-root {
    background: #fff;
    padding: 3px 6px;
    color: #064487;
}

.giftTextSection {
    margin: 0px 0px 15px 0;
}

.giftTextSection .iconedit {
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
    padding-left: 7px;
    padding-right: 6px;
    vertical-align: middle;
    background: #000;
    line-height: 30px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    /* cursor: pointer;
    display: inline-block;
    margin-left: 5px;
    border-left: 1px solid #99ceff;
    padding-left: 5px;
    vertical-align: middle; */
}

.giftTextSection span,
.giftTextSection label {
    display: inline-block !important;
}

.giftTextSection .patientName {
    display: inline-block;
    margin: 0px 6px;
    color: #fff;
    font-weight: bold;
    background: #009aff;
    padding: 0px 0px 0px 13px;
    border-radius: 4px;
    line-height: 28px;
    /* display: inline-block;
    margin: 0px 6px;
    color: #fff;
    font-weight: bold;
    background: #009aff;
    padding: 5px 8px;
    border-radius: 4px; */
}

.giftTextSection .allPatientCountSpan {
    display: inline-block;
    color: #fff;
    border-left: 1px solid #99ceff;
    padding-left: 5px;
    margin-left: 5px;
    font-weight: normal;
    font-size: 14px;
    vertical-align: middle;
    line-height: 20px;
    cursor: default;
}

.giftTextSection .addPatientButton {
    width: 110px;
    background: rgb(120, 225, 3);
    background: linear-gradient(180deg, rgba(120, 225, 3, 1) 0%, rgba(68, 177, 1, 1) 100%);
    color: #fff;
    margin: 0px auto;
    margin-left: 7px;
    display: inline-block;
    vertical-align: middle;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border: 1px solid #435e6d;
    border-radius: 6px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
    font-size: 18px;
    line-height: 28px;
    cursor: pointer;
}

.RecipientModal {
    width: 800px !important;
    max-width: 90% !important;
}

.RecipientModal .giftModalContent div {
    min-width: inherit !important;
    max-width: inherit !important;
    padding: 0;
    text-align: left;
}

.RecipientModal .css-yrdy0g-MuiDataGrid-columnHeaderRow .MuiDataGrid-columnHeader {
    width: 20% !important;
}

.RecipientModal .css-yrdy0g-MuiDataGrid-columnHeaderRow .MuiDataGrid-columnHeader:nth-of-type(1) {
    width: 80px !important;
}

.RecipientModal .css-yrdy0g-MuiDataGrid-columnHeaderRow .MuiDataGrid-columnHeader div {
    display: block;
}

.RecipientModal .MuiDataGrid-row .MuiDataGrid-cell {
    width: 20% !important;
    justify-content: left;
}

.RecipientModal .MuiDataGrid-row .MuiDataGrid-cell:nth-of-type(1) {
    width: 80px !important;
}

.RecipientModal .MuiDataGrid-row .MuiDataGrid-cell div {
    display: block;
}

.RecipientModal .MuiDataGrid-row .MuiDataGrid-cell div {
    justify-content: flex-start;
}

.RecipientModal .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer {
    display: block;
}

.RecipientModal .MuiDataGrid-columnHeaders {
    width: 100%;
}

.RecipientModal .css-gl260s-MuiDataGrid-columnHeadersInner {
    width: 100%;
}

.RecipientModal .css-yrdy0g-MuiDataGrid-columnHeaderRow {
    width: 100% !important;
    justify-content: space-between !important;
    outline: none !important;
}

.RecipientModal .MuiDataGrid-cell:last-of-type {
    display: none !important;
}

.RecipientModal .MuiDataGrid-cell--withRenderer {
    display: flex !important;
}

.RecipientModal .MuiDataGrid-cell--textLeft {
    display: flex !important;
}

.RecipientModal .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
    width: 100% !important;
}

.RecipientModal .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer {
    flex-direction: inherit;
}

.RecipientModal .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row {
    width: 100%;
    justify-content: space-between;
}

.RecipientModal .css-1inm7gi {
    width: 100% !important;
}

.RecipientModal .css-k008qs {
    justify-content: space-between;
}

.RecipientModal .css-aop0yh .MuiDataGrid-row {
    justify-content: space-between;
}

.RecipientModal .css-k008qs .MuiDataGrid-columnHeader {
    width: 20% !important;
}

.RecipientModal .css-k008qs .MuiDataGrid-columnHeader:nth-of-type(1) {
    width: 80px !important;
}

.RecipientModal .css-k008qs .MuiDataGrid-columnHeader div {
    display: block;
}

.css-aop0yh .MuiDataGrid-columnHeaderTitleContainer {
    display: block;
}

.RecipientModal .css-k008qs .MuiDataGrid-columnHeader div {
    display: block;
}

.css-aop0yh .MuiDataGrid-columnHeaderTitleContainer {
    display: block;
}

.RecipientModal .css-k008qs .MuiDataGrid-columnHeader:nth-of-type(1) {
    width: 80px !important;
}

.RecipientModa .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner:first-of-type {
    display: block !important;
    width: 100% !important;
}

.RecipientModa .MuiDataGrid-row {
    display: block !important;
    width: 100% !important;
}

.RecipientModa .MuiDataGrid-row {
    display: block !important;
    width: 100% !important;
}

.giftTextSection .billingGift,
.giftTextSection .billingSelf {
    background: none;
    margin-left: 10px;
}

.giftTextSection span {
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
}

.giftTextSection span.billingGift svg path {
    color: #fff;
}

.cardDetailsContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardDetailsLeft {
    width: 70%;
    padding-right: 25px;
    margin-right: 25px;
    border-right: 1px solid #5084b5;
    margin-top: 15px;
}

.cardElementClass .CardField-input-wrapper {
    width: 100%;
    background: #fff !important;
    display: block;
    transform: unset;
}

.cardDetailsRight p {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;     margin-top: 30px;
}

.cardDetails .holderName,
.cardDetails .cardNumber {
    width: 100%;
    margin: 0px 0 26px 0;
}

.cardDetails .expireDetailsDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 15px; */
}

.cardDetails .expireDetailsDiv .expiryDateField,
.cardDetails .expireDetailsDiv .cvvField {
    width: 49%;
}

.cardDetailsRight {
    text-align: center;
}

.cardDetailsRight .allCardList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.cardDetailsRight img {
    width: 70px;
    display: inline-block;
    margin: 6px;
}

.paymentSection .billingSection .MuiFormHelperText-root.Mui-error {
    right: 0px !important;
    position: absolute !important;
    left: 0px !important;
    bottom: -20px !important;
    /* transform: translatex(-50%) !important; */
    background: linear-gradient(180deg, rgba(255, 0, 0, 1) 0%, rgba(209, 22, 22, 1) 100%) !important;
    padding: 0px 8px 4px !important;
    font-size: 13px !important;
    border-radius: 4px !important;
    color: #fff !important;
    border: 1px solid #eb0404 !important;
    height: 23px !important;
    z-index: 1;
    width: 98%;
    margin: 0px auto;
}

.paymentSection .billingSection .billingDetails .MuiFormHelperText-root.Mui-error {
    z-index: 11;
    bottom: -10px !important;
}

.paymentSection .billingSection .MuiFormHelperText-root.Mui-error::before {
    content: "";
    position: absolute;
    left: 15px;
    top: -12px;
    transform: rotate(279deg);
    border-width: 36px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 15px solid red;
}

.orderSummarySection {
    margin-left: 40px;
    padding: 16px;
    width: calc(24% - 40px);
}

.orderSummarySection h2 {
    background: rgb(0, 100, 200);
    background: linear-gradient(90deg, rgba(0, 100, 200, 1) 0%, rgba(0, 155, 247, 1) 100%);
    color: #fff;
    text-transform: uppercase;
    margin: 0px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.orderSummarySection p {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderSummarySection p strong {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderSummaryDetailsSectionContent,
.priceDetailsSectionContent {
    background: #e3e3e3;
    padding: 12px;
    margin-top: 10px;
}

.orderSummaryDetailsSectionContent h4 {
    margin: 0px;
}

.orderSummaryDetailsSectionContent p {
    background: #fff;
    padding: 6px;
    margin: 5px 0px;
}

.orderSummaryDetailsSectionContent p span {
    display: inline-block;
    margin-left: 10px;
}

.priceDetailsSection {
    margin-top: 16px;
}

.paybutton {
    width: 100%;
}

.paybutton button {
    width: 180px;
    background: rgb(120, 225, 3);
    background: linear-gradient(180deg, rgba(120, 225, 3, 1) 0%, rgba(68, 177, 1, 1) 100%);
    color: #fff;
    margin: 15px auto;
    display: block;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border: 1px solid #435e6d;
    border-radius: 6px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
    font-size: 18px;
    font-weight: bold;
}

.giftModal .btnClose {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    min-width: 30px;
    padding: 0px;
    border-radius: 50%;
    background: #fff;
    color: #000;
}

.giftModal .btnClose:hover {
    color: #fff;
}

.giftModal .btnClose svg {
    font-size: 18px !important;
}

.giftModal .MuiDialogContent-root {
    padding: 0px;
}

.giftModal #alert-dialog-slide-description {
    background: #1185bd;
    text-align: center;
    color: #fff;
    padding: 0px 8px 6px 8px;
    font-size: 1.4rem;
}

.giftModal .giftModalContentainer {
    padding: 20px;
    background: #ddd;
    overflow-x: auto;
    max-height: 80vh;
    padding-bottom: 10px;
}

.giftModal .giftModalContentainer .giftModalContent .dateTimeField .MuiFormHelperText-root.Mui-error {
    bottom: 2px !important;
}

.giftModal .giftModalContentainer .giftModalContent .MuiFormHelperText-root.Mui-error {
    right: 0px !important;
    position: absolute !important;
    left: 0px !important;
    bottom: -12px !important;
    /* transform: translatex(-50%) !important; */
    background: linear-gradient(180deg, rgba(255, 0, 0, 1) 0%, rgba(209, 22, 22, 1) 100%) !important;
    padding: 0px 8px 4px !important;
    font-size: 13px !important;
    border-radius: 4px !important;
    color: #fff !important;
    border: 1px solid #eb0404 !important;
    height: 23px !important;
    z-index: 11;
    width: 98%;
    margin: 0px auto;
}

.giftModal .giftModalContentainer .giftModalContent .MuiFormHelperText-root.Mui-error::before {
    content: "";
    position: absolute;
    left: 15px;
    top: -12px;
    transform: rotate(279deg);
    border-width: 36px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 15px solid red;
}

.giftModal .giftModalContentainer .giftModalContent .dateTimeField {
    position: relative;
}


/* .giftModal .giftModalContent {
    max-height: 80vh;
} */

.giftModal .MuiFormControl-root {
    background: #fff;
    margin: 0px;
    width: 100%;
    margin-bottom: 16px;
}

.MuiDataGrid-columnHeadersInner {
    display: block !important;
    width: 100% !important;
}

.MuiDataGrid-row {
    width: 100% !important;
}


/* .giftModal .MuiFormControl-root  input{  padding: 10px;} */


/* 
.giftModal .MuiFormControl-root .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{  padding: 10px;} */

.giftModal .MuiFormLabel-root {
    /* background: #fff; */
    padding: 3px 0;
    color: #064487;
    top: 0;
}

.giftModal .MuiInputLabel-shrink {
    top: 8px !important;
}

.giftModal .MuiDialogActions-root {
    text-align: center;
}

.giftModal .MuiDialogActions-root button {
    display: block;
    margin: 0px auto;
    font-size: 20px;
    padding: 4px 20px;
}

.giftModalContent .MuiDataGrid-cell {
    min-width: 100px !important;
}


/* .giftModalContent .MuiDataGrid-cell:first-child {
    min-width: 20px !important;
} */

.giftModal.patientList {
    /* min-width: 50vw; */
}

.giftModalContent .modalActionButton {
    cursor: pointer;
}

.giftModalContent .modalActionButton.editButton {
    color: #5caf00;
}

.giftModalContent .modalActionButton.deleteButton {
    color: #bb0000;
}

.cardError {
    position: relative;
    display: block;
}

.cardError .cardErrorMsg {
    right: 0px !important;
    position: absolute !important;
    left: 0px !important;
    bottom: -20px !important;
    /* transform: translatex(-50%) !important; */
    background: linear-gradient(180deg, rgba(255, 0, 0, 1) 0%, rgba(209, 22, 22, 1) 100%) !important;
    padding: 0px 8px 4px !important;
    font-size: 13px !important;
    border-radius: 4px !important;
    color: #fff !important;
    border: 1px solid #eb0404 !important;
    height: 23px !important;
    z-index: 1;
    width: 98%;
    margin: 0px auto;
    line-height: 1.66;
}

.cardError .cardErrorMsg::before {
    content: "";
    position: absolute;
    left: 15px;
    top: -12px;
    transform: rotate(279deg);
    border-width: 36px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 15px solid red;
}


/* Responsive */

@media screen and (max-width:1599px) {
    .diabaticPaymentContainer {
        height: auto;
    }
}

@media screen and (max-width:1399px) {
    .billingSection {
        width: 70%;
    }

    .orderSummarySection {
        width: calc(28% - 40px);
    }

    .cardDetailsRight {
        width: 40%;
    }

    .cardDetailsRight img {
        width: 50px;
    }

    .diabaticPaymentSubContainer .diabaticPaymentSubContainerHeading h1 {
        font-size: 28px;
    }
}

@media screen and (max-width:1199px) {
    .diabaticPaymentSubContainer .diabaticPaymentSubContainerHeading h1 {
        text-align: center;
        margin: 0px 0px 10px 0px;
        width: 100%;
    }

    .diabaticPaymentContainer {
        background: rgb(211, 239, 251);
        background: linear-gradient(90deg, rgba(211, 239, 251, 1) 0%, rgba(172, 220, 240, 1) 100%);
        height: auto;
    }

    .diabaticPaymentContainer .DecisionDocLogo {
        display: block;
        margin: 0px auto;
        margin-bottom: 15px;
    }

    .paymentSection {
        display: block;
    }

    .billingSection {
        width: 100%;
    }

    .orderSummarySection {
        width: 100%;
        margin: 15px auto;
    }

    .orderSummarySection .orderSummarySectionContent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .orderSummarySection .orderSummarySectionContent .orderSummaryDetailsSection,
    .orderSummarySection .orderSummarySectionContent .priceDetailsSection {
        width: 48%;
        margin: 0px;
    }

    .orderSummarySection .orderSummarySectionContent .priceDetailsSection .priceDetailsSectionContent p:first-child {
        margin-top: 0px;
    }
}

@media screen and (max-width:991px) {
    .cardDetailsRight img {
        /* width: 35px; */
    }
}

@media screen and (max-width:767px) {


.MuiDataGrid-columnHeaders{ display: none;}

.MuiDataGrid-row{ display: block!important; 
    max-height: inherit!Important;
    min-height: inherit!Important;     background: #ccc!important;}

  .MuiDataGrid-row:nth-of-type(odd){ background-color: #fff!important;}

    .RecipientModal .MuiDataGrid-row .MuiDataGrid-cell:nth-of-type(1) {
        width: 100%!important;
    }

  .MuiDataGrid-row .MuiDataGrid-cell:nth-of-type(1) {
        width: 100%!important;
    }

    .RecipientModal .MuiDataGrid-row .MuiDataGrid-cell {
        width: 100%!important;
        justify-content: left;
    }

 .MuiDataGrid-row .MuiDataGrid-cell {
        width: 100%!important;
        justify-content: left;
    }
   .MuiDataGrid-cell::before {
        
        content: attr(data-field); 
        font-weight: bold;  padding-left: 10px; padding-right: 10px; text-transform: capitalize;
      }

      .MuiDataGrid-virtualScrollerContent{ height: auto!important;}

      .MuiDataGrid-virtualScrollerRenderZone{ position: inherit!important;}


 
      


    
    .cardDetailsRight p {
        margin-top: 10px;
    }

    .billingSection,
    .orderSummarySection {
        padding-bottom: 20px;
    }

    .patientInformation .patientInformationContent {
        display: block;
    }

    .patientInformation .patientInformationContent .patientInformationEmail {
        margin: 0px;
    }

    .emailAddressDiv {
        display: block;
    }

    .billingSection .billingName,
    .billingSection .billingEmail,
    .billingSection .billingAddress {
        width: 100%;
        margin-bottom: 0px !important;
    }

    .stateCityZipDiv {
        margin-top: 15px;
    }

    .cardDetailsContent {
        display: block;
    }

    .cardDetailsLeft {
        width: 100%;
        border-right: none;
        padding: 0px 0px 15px 0px;
        margin: 0px;
        border-bottom: 1px solid #5084b5;
    }

    .cardDetailsRight {
        width: 100%;
    }

    .cardDetailsRight img {
        width: 60px;
    }

    .billingSection h2 {
        font-size: 20px;
    }
}

@media screen and (max-width:499px) {
    .diabaticPaymentSubContainer .diabaticPaymentSubContainerHeading h1 {
        font-size: 20px;
        line-height: 24px;
    }

    .stateCityZipDiv .billingCity,
    .stateCityZipDiv .billingState,
    .stateCityZipDiv .billingZip {
        width: 100%;
    }

    .stateCityZipDiv {
        display: block;
    }

    .orderSummarySection .orderSummarySectionContent {
        display: block;
    }

    .orderSummarySection .orderSummarySectionContent .orderSummaryDetailsSection,
    .orderSummarySection .orderSummarySectionContent .priceDetailsSection {
        width: 100%;
    }

    .orderSummarySection .orderSummarySectionContent .priceDetailsSection {
        margin-top: 15px;
    }

    .diabaticPaymentContainer .DecisionDocLogo {
        width: 200px;
    }

    .giftTextSection span,
    .cardDetailsRight p {
        font-size: 14px;
        line-height: 18px;
    }

    .billingSection {
        padding: 20px;
    }

    .giftTextSection {
        margin-top: 0px;
    }

    .patientInformation .patientInformationContent .patientInformationName,
    .patientInformation .patientInformationContent .patientInformationEmail {
        font-size: 14px;
    }

    .patientInformation .patientInformationContent span {
        font-size: 16px;
    }
}

@media screen and (max-width:380px) {

    .giftTextSection .billingGift,
    .giftTextSection .billingSelf {
        padding: 6px;
    }

    .giftTextSection span,
    .giftTextSection label {
        margin-right: 0px;
    }

    .cardDetailsRight img {
        width: 42px;
    }
}