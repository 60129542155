html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "POPPINS", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


/***********Form Css Start************/


/* .headerTxt{margin: 0;padding: 0;} */

.css-1wc848c-MuiFormHelperText-root.Mui-error {
    color: #d32f2f;
    position: absolute;
    bottom: 0;
}


/* =============================field-required-error-msg============================================= */

.MuiFormHelperText-root.Mui-error {
    color: #f1f1f1 !important;
    position: absolute;
    background: #d32f2f;
    right: 0;
    bottom: 10px;
    padding: 4px 8px 4px 10px;
    /* border-radius: 24px 24px 24px 24px; */
    font-weight: 500;
}

.signSec .MuiFormHelperText-root.Mui-error {
    color: #d32f2f !important;
    position: inherit;
    background: inherit;
    right: 0;
    bottom: 10px;
    padding: 0;
    border-radius: 0;
    font-weight: 500;
}

.patientIntakeFormheder .errortext {
    position: absolute;
    left: 13px;
    bottom: 0;
    z-index: 9;
}

h2.headerTxt {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    font-size: 24px;
    justify-content: center;
}

.formCls {
    margin: 0;
    padding: 8px;
    border-radius: 20px;
    background: #f0f0f0;
    box-shadow: 0 0 5px #626fb1 !important;
}

.formCls .formInline {
    margin: 0;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.formCls .formInline .formGroup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    padding: 8px;
    color: #000;
    font-size: 20px;
}

.formCls .MuiFormControl-root {
    padding: 0;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    box-shadow: 0px 2px 1px white inset, 0px -2px 8px white, 0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
    -webkit-transition: box-shadow 0.5s;
}

.formCls {
    margin: 0;
    padding: 10px;
    border-radius: 20px;
    background: #c9d5df;
    background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 0 0 5px #b1b1b1 !important;
}

.formCls form {
    margin: 0;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.formCls .MuiFormControl-root {
    padding: 0;
    flex: 1 0 32.33%;
    margin: 0;
}


/* .formCls .MuiFormControl-root .MuiInputLabel-root {
    position: relative;
    height: 50px;
    width: auto;
    background: #626fb1;
    text-align: left;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-transform: none;
    transform: none;
    text-transform: capitalize;
} */

.formCls .MuiFormControl-root .MuiOutlinedInput-root,
.formCls .MuiFormControl-root textarea {
    height: 50px !important;
    font-size: 14px;
    border-radius: 5px;
    background-color: #f0f0f0 !important;
    margin-top: 0;
    margin-bottom: 0;
    border: 1px solid #9a9a9a;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex: 1;
}

.formCls .formInline .formGroup:last-of-type {
    margin-bottom: 10px !important;
}

Button[type="submit"],
.formCls.otherStateCls Button[type="button"] {
    cursor: pointer;
    background: linear-gradient(90deg, #00abff 0%, #fc00ff 100%);
    border-radius: 50px;
    padding: 8px 30px 4px;
    display: block;
    text-decoration: none;
    width: auto;
    margin: 0px;
    box-shadow: 0px 2px 1px white inset, 0px -2px 8px white, 0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
    -webkit-transition: box-shadow 0.5s;
    font-size: 24px;
    color: var(--white);
    text-transform: uppercase;
    margin-top: 15px;
}

.formCls.otherStateCls {
    background: transparent;
    padding: 0;
    border-radius: 0;
    box-shadow: none !important;
}

.formCls.otherStateCls Button[type="button"] {
    margin-top: 0;
    margin-left: 20px;
}

.formCls .css-viou3o-MuiAutocomplete-root {
    width: 100%;
}

.formCls .formInline .formGroup.fullWidth {
    width: 100%;
}

.formActionBtn {
    justify-content: center;
    margin-top: 20px !important;
}

.formCls button {
    margin: 0 5px;
}

.formCls .MuiFormControl-root textarea {
    padding: 16.5px 14px;
}

.formCls .switchCls {
    display: flex;
    flex-direction: row-reverse;
}


/* .formCls .formInline .formGroup .css-1nrlq1o-MuiFormControl-root{flex-wrap: wrap;flex-direction: column;} */

.formCls .formInline .formGroup .heading {
    font-weight: var(--main-semibold);
}


/* MuiDrawer */

.MuiDrawer-root .MuiBackdrop-root {
    background-color: transparent;
}

.MuiPaper-root .formCls {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.MuiModal-root .css-9emuhu-MuiPaper-root-MuiDrawer-paper {
    background-color: transparent;
}

.css-a5rdam-MuiGrid-root>.MuiGrid-item {
    padding-top: 20px;
}

.css-a5rdam-MuiGrid-root>.MuiGrid-item {
    padding-left: 20px;
}

.MuiModal-root .css-9emuhu-MuiPaper-root-MuiDrawer-paper {
    background: #c9d5df;
    background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
    padding: 10px 20px;
    border-radius: 0;
}

.MuiModal-root .MuiPaper-root .formCls {
    background: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
}

.MuiModal-root.otherStateCls .css-9emuhu-MuiPaper-root-MuiDrawer-paper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dfe8edb3;
    background: linear-gradient(180deg, #dfe8ed73 0, #c9d5df8c 100%);
}

.formCls .MuiFormControl-root .MuiInputLabel-root {
    position: relative;
    height: 50px;
    width: auto;
    background: #626fb1;
    text-align: left;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transform: none;
    text-transform: capitalize;
}

.formCls.otherStateCls .formInline {
    background-color: #efefef;
    padding: 20px;
    border: 2px solid #c6cbce;
    border-radius: 10px;
    position: relative;
}

.formCls .close {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: #ff4c09;
    text-shadow: 0 1px 0 var(--white);
    filter: alpha(opacity=20);
    opacity: 1 !important;
    padding: 0 9px 2px !important;
    border-radius: 100px;
    right: 5px;
    position: absolute;
    top: 5px;
    outline: none !important;
    width: 20px;
    height: 20px;
    padding: 0px !important;
    min-width: 1px;
    cursor: pointer;
}


/***********Form Css End************/


/**************Logged User Section*******************/

.loggedUser .css-pwngrv-MuiPaper-root-MuiCard-root.formCls {
    padding: 10px 20px !important;
    background: #000000 !important;
    border-radius: 20px !important;
    border: 3px solid #3d4144;
    box-shadow: none !important;
}

.loggedUser .formCls .formInline {
    padding: 0 !important;
}

.loggedUser .formCls .formInline .MuiIcon-root {
    padding: 2px;
    border: 2px solid #ffb640;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border-radius: 100px;
}

.loggedUser .formCls .formInline p.formGroup {
    color: #fff;
    text-transform: uppercase;
}


/*****************************/


/* Banner Image */

.ContentWrapper.marketingBanner {
    border-radius: 15px;
    overflow: hidden;
    display: flex;
}

.bannerimg {
    width: 100%;
}

.ContentWrapper.bookingContentWrapper {
    background: #f3f3f3;
}

.ContentWrapper.bookingContentWrapper .quickIntakeMobBanner.heading {
    font-family: avenir-medium;
    text-transform: uppercase;
    font-size: 24px;
    padding: 6px;
    color: #1dbce9;
    background: #f7f7f7;
    width: 98%;
}


/* Modal */

.modalblock .MuiBox-root {
    border: solid 10px #08507f;
    border-radius: 4px;
    padding: 15px;
}

.modalblock .MuiBox-root .header {
    width: calc(100% + 2px);
    background: #13507f;
    position: absolute;
    top: -1px;
    text-align: center;
    left: -1px;
    height: 35px;
    font-size: 32px;
    line-height: 30px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.modalblock .MuiBox-root h2 {
    margin: 0;
    padding: 0;
    color: #1b4965;
    font-size: 30px;
    line-height: 30px;
}

.modalblock .MuiBox-root h3 {
    margin: 0;
    padding: 0;
    color: #1b4965;
}

.modalblock .MuiBox-root h4 {
    margin: 0;
    padding: 10px 0;
    color: #000;
    font-size: 18px;
}

.modalblock .MuiBox-root h4.noteHeading {
    font-size: 16px;
    font-weight: 500;
}

.modalblock .MuiBox-root input {
    width: calc(100% - 6px);
    height: 36px;
    background: #f3efef;
    border: solid 1px #d1c0c0;
    box-shadow: none;
    padding: 0px 0px 0px 4px;
    color: #000;
    padding-right: 0px !important;
}

.slotModal.modalblock .MuiBox-root {
    padding-top: 35px;
    position: relative;
}

.modalblock .MuiBox-root h3 {
    text-align: center;
    margin-top: 10px;
}

.bookingModal.modalblock .MuiBox-root {
    padding: 0px !important;
}

.bookingModal.modalblock .modalBox {
    max-height: 82vh;
    overflow-y: scroll;
    border: 0px !important;
    height: 100% !important;
    padding: 15px !important;
}

.bookingModal.modalblock .closeModalBtn {
    position: absolute;
    background: #2076d2 !important;
    top: -20px;
    width: 30px;
    color: #fff;
    height: 30px;
    right: -20px;
    min-width: auto;
    border-radius: 50%;
}

.contractContainer {
    /* display: flex !important;
    flex-wrap: wrap !important; */
    /* justify-content: space-between !important; */
    /* width: 1668px; */
    margin: 0 auto;
    box-shadow: none;
}

.contractContainer .termsNcond {
    box-shadow: 0 2px 13px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    width: 90%;
    margin: 0px auto;
    height: auto;
    min-height: auto;
    max-height: inherit;
    padding: 20px;
    overflow: hidden;
}

.termsNcond .box h2 {
    margin: 0;
    padding: 0;
    color: #249ac7;
}

.termsNcond h3 {
    margin: 0;
    padding: 10px 0;
    color: #224f60;
}

.termsNcond ul {
    margin: 0;
    padding: 0;
}

.termsNcond h4 {
    margin: 0;
    padding: 10px 0;
    color: #224f60;
}

.contractContainer .termsNcond .practiceinfo {
    font-weight: bold;
}

.contractContainer .termsNcond ul {
    list-style: none;
    padding-left: 0px;
    line-height: 20px;
}

.contractContainer .termsNcond p {
    line-height: 20px;
}

.contractContainer .termsNcond .signature {
    width: 100%;
    text-align: right;
    font-size: 14px;
}

.contractContainer .termsNcond .signature span.sign {
    font-family: "Parisienne", cursive;
    font-size: 30px;
    margin-left: 10px;
}

.contractContainer .termsNcond .signature span.userType {
    text-transform: capitalize;
}

.signatureBlock {
    display: flex;
}

.contractContainer .termsNcond .signature.doctorSignature {
    text-align: left;
}


/* -----------------------LANDING NEW CSS------------------------------->>>>>>>>>>>>>>>>>> */

.landingnew_wrpr .headerblock {
    margin-top: 18px;
}

.landingnew_wrpr .signup_bannerarrow {
    position: relative;
}

.landingnew_wrpr .arrow_Up_text {
    background: rgb(248, 0, 0);
    background: linear-gradient(180deg, rgba(248, 0, 0, 1) 0%, rgba(105, 5, 3, 1) 100%);
    border-radius: 35px 35px 0 0;
    display: block;
    margin-left: 36%;
    margin-bottom: -87px;
    bottom: 49px;
    position: relative;
    z-index: 100;
}

.landingnew_wrpr .arrow_Up_text h3 {
    background: -webkit-linear-gradient(#ffffff 0, #ffffff 56%, #d7d7d7 56%, #d7d7d7 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 41px;
    padding: 18px 30px;
}


/* .landingnew_wrpr .signup_bannerarrow .arrow_Up_text{
  background: rgb(248,0,0);
  background: linear-gradient(180deg, rgba(248,0,0,1) 0%, rgba(105,5,3,1) 100%);
  position:absolute;
  top: -19px;
  right: 223px;
  border-radius: 35px 35px 0 0;
}
.landingnew_wrpr .signup_bannerarrow .arrow_Up_text h3{
  background: -webkit-linear-gradient(#ffffff 0,#ffffff 56%,#d7d7d7 56%,#d7d7d7 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 41px;
  padding: 18px 30px;;
} */

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text {
    padding: 0 0 0 60px;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
    background: rgb(237, 240, 243);
    background: linear-gradient(180deg, rgba(237, 240, 243, 1) 0%, rgba(162, 183, 192, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 45px;
    font-weight: bold;
    position: relative;
    /* left: -165px; */
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
    background: rgb(251, 212, 83);
    background: linear-gradient(180deg, rgba(251, 212, 83, 1) 0%, rgba(230, 176, 23, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 67px;
    font-weight: bold;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {
    width: 77%;
}

.landingnew_wrpr .footercopy {
    padding: 22px 30px 30px;
    width: auto;
}

.landingnew_wrpr .copyrighttext {
    padding-top: 0px;
    padding-bottom: 0px;
}


/* ==============================BANNER TOP HEADER TEXT Start======================================== */

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr {
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline1.png), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline2.png), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline3.png), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline4.png), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline5.png);
    background-color: #021322;
    background-repeat: no-repeat;
    background-position: left bottom, center left, left top, left top, right top;
    background-size: auto, 17px 100%, auto auto, 180px 16px, auto auto;
    width: 340px;
    border: 1px solid #323f4a;
    border-radius: 45px;
    padding: 30px 0 30px 30px;
    position: relative;
    margin-bottom: 30px;
}


/* .landingnew_wrpr .pece_mkbannerblock .leftwrp_content top_text_wrpr .textwrapper {
    width: 340px;
    border: 1px solid #323f4a;
    border-radius: 45px;
    padding: 30px 0 30px 30px;
    position: relative;
    margin-bottom: 28px;
} */

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    background: rgb(254, 243, 208);
    background: linear-gradient(180deg, rgba(254, 243, 208, 1) 20%, rgba(194, 137, 61, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    text-transform: uppercase;
    width: 340px;
    font-family: "Roboto", sans-serif;
}

.landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
    position: absolute;
    right: -12%;
    top: -14px;
    z-index: 9;
}

.topheader_bottom {
    text-transform: none !important;
}

.mobileviewlist {
    display: none;
}


/* ==============================BANNER TOP HEADER TEXT END======================================== */

.landingnew_wrpr .mk_innerrightsection {
    position: relative;
    width: 56%;
}

.landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper {
    position: absolute;
    right: -88px;
    bottom: -110px;
}


/* .landingnew_wrpr  .mk_innerrightsection h2{
  background: rgb(237,240,243);
  background: linear-gradient(180deg, rgba(237,240,243,1) 0%, rgba(162,183,192,1) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 45px;
  font-weight: bold;
  position: relative;
  left: -162px;
} */


/* .landingnew_wrpr   .mk_innerrightsection  h2 span{
  background: rgb(251,212,83);
background: linear-gradient(180deg, rgba(251,212,83,1) 0%, rgba(230,176,23,1) 100%);
-webkit-text-fill-color: transparent;
-webkit-background-clip: text;
font-size: 67px;
  font-weight: bold;
} */

.landingnew_wrpr .organtest_wrpr {
    margin-top: -31px;
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
    background: rgb(253, 219, 102);
    background: linear-gradient(180deg, rgba(253, 219, 102, 1) 0%, rgba(228, 170, 8, 1) 100%);
    border-radius: 10px;
    margin: 30px 0;
    width: 40%;
    position: relative;
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
    font-size: 18px;
    font-weight: bold;
    padding: 7px 37px;
    width: 71%;
    text-decoration: none;
    color: #000000;
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1::before {
    content: "";
    position: absolute;
    background-image: url(../src/assets/landingnew/brain.png);
    background-repeat: no-repeat;
    height: 82px;
    width: 82px;
    left: -54px;
    transform: translate(0px, -13px);
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before {
    content: "";
    position: absolute;
    background-image: url(../src/assets/landingnew/heart.png);
    background-repeat: no-repeat;
    height: 82px;
    width: 82px;
    left: -54px;
    transform: translate(0px, -13px);
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(3)::before {
    content: "";
    position: absolute;
    background-image: url(../src/assets/landingnew/kidneys.png);
    background-repeat: no-repeat;
    height: 82px;
    width: 82px;
    left: -54px;
    transform: translate(0px, -13px);
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(4)::before {
    content: "";
    position: absolute;
    background-image: url(../src/assets/landingnew/Eye.png);
    background-repeat: no-repeat;
    height: 82px;
    width: 82px;
    left: -54px;
    transform: translate(0px, -13px);
}


/* .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(5)::before{
  content: "";
  position: absolute;
  background-image: url(../src/assets/landingnew/heartattack.png);
  background-repeat: no-repeat;
  height: 82px;
  width: 82px;
  left: -54px;
  transform: translate(0px, -13px);
} */

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext {
    margin-bottom: 38px;
}

.landingnew_wrpr .blk3humanbodymainbg {
    background-size: cover;
    background-position: center top;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtextmain::before {
    opacity: 0.2;
}


/* .landingnew_wrpr .organtest_wrpr .orgn_bnr1:hover{
  background: rgb(9,15,17);
background: linear-gradient(180deg, rgba(9,15,17,1) 0%, rgba(12,56,85,1) 100%);
color: #ffffff;
} */


/* .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p:hover{
color: #ffffff;
} */


/* .landingnew_wrpr .organtest_wrpr .orgn_bnr1 a:active{
  background: rgb(9,15,17);
background: linear-gradient(180deg, rgba(9,15,17,1) 0%, rgba(12,56,85,1) 100%);
} */

.bottmobutton {
    display: none;
}

.form_grp .MuiSelect-select {
    text-align: left !important;
}

.darktheme.as_darkthemestyle {
    padding: 0;
}

.darktheme.as_darkthemestyle p {
    color: #fff;
}

.darktheme.is_diabetesrexburg.as_darkthemestyle {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/diabaticThankyouBg.png) center center;
    background-size: 100%;
}

.darktheme.is_diabetesrexburg.as_darkthemestyle .thankyou-container {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/diabaticsThankYouDarkShape.png), url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/diabaticThankYouDarkShape2.png);
    background-color: unset !important;
    background-position: -30px -8px, 1564px 505px;
    background-size: 30%, 17%;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    flex-wrap: wrap;
    width: 100%;
    min-height: 100vh;
}

.cardDetailsLeft .stripeCard label {
    margin-bottom: 26px;
    display: block;
}

.ElementsApp,
.ElementsApp .InputElement {
    padding-left: 10px;
}


/* -----------------------LANDING NEW CSS------------------------------->>>>>>>>>>>>>>>>>> */

.landingnew_wrpr .headerblock {
    margin-top: 18px;
}

.landingnew_wrpr .signup_bannerarrow {
    position: relative;
}

.landingnew_wrpr .arrow_Up_text {
    background: #f80000;
    background: linear-gradient(180deg, rgba(248, 0, 0, 1) 0%, rgba(105, 5, 3, 1) 100%);
    border-radius: 35px 35px 0 0;
    display: block;
    margin-left: 36%;
    margin-bottom: -87px;
    margin-top: -10px;
    bottom: 59px;
    position: relative;
    z-index: 100;
}

.landingnew_wrpr .arrow_Up_text h3 {
    background: -webkit-linear-gradient(#fff 0, #fff 56%, #d7d7d7 56%, #d7d7d7 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 41px;
    padding: 18px 30px;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text {
    padding: 0 0 0 60px;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
    background: #edf0f3;
    background: linear-gradient(180deg, rgba(237, 240, 243, 1) 0%, rgba(162, 183, 192, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 45px;
    font-weight: 700;
    position: relative;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
    background: #fbd453;
    background: linear-gradient(180deg, rgba(251, 212, 83, 1) 0%, rgba(230, 176, 23, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 67px;
    font-weight: 700;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {
    width: 77%;
}

.landingnew_wrpr .footercopy {
    padding: 22px 30px 30px;
    width: auto;
}

.landingnew_wrpr .copyrighttext {
    padding-top: 0;
    padding-bottom: 0;
}


/* ==============================BANNER TOP HEADER TEXT Start======================================== */

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr {
    width: 100%;
    display: flex;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline1.webp), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline2.webp), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline3.webp), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline4.webp), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/darktheme_borderline5.webp);
    background-color: #021322;
    background-repeat: no-repeat;
    background-position: left bottom, center left, left top, left top, right top;
    background-size: auto, 17px 100%, auto auto, 180px 16px, auto auto;
    width: 340px;
    border: 1px solid #323f4a;
    border-radius: 45px;
    padding: 30px 0 30px 30px;
    position: relative;
    margin-bottom: 30px;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    background: #fef3d0;
    background: linear-gradient(180deg, rgba(254, 243, 208, 1) 20%, rgba(194, 137, 61, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    text-transform: uppercase;
    width: 340px;
    font-family: "Roboto", sans-serif;
}

.landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
    position: absolute;
    right: -12%;
    top: -14px;
    z-index: 9;
}

.topheader_bottom {
    text-transform: none !important;
}

.mobileviewlist {
    display: none;
}

.resolve_loaderTop {
    top: 0;
    position: fixed;
    z-index: 9999;
}

.resolve_loaderTop .mat-progress-bar {
    height: 5px;
}


/* ==============================BANNER TOP HEADER TEXT END======================================== */

.landingnew_wrpr .mk_innerrightsection {
    position: relative;
    width: 56%;
}

.landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper {
    position: absolute;
    right: -88px;
    bottom: -110px;
}

.landingnew_wrpr .organtest_wrpr {
    margin-top: -31px;
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
    background: #fddb66;
    background: linear-gradient(180deg, rgba(253, 219, 102, 1) 0%, rgba(228, 170, 8, 1) 100%);
    border-radius: 10px;
    margin: 30px 0;
    width: 40%;
    position: relative;
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
    font-size: 18px;
    font-weight: 700;
    padding: 7px 37px;
    width: 71%;
    text-decoration: none;
    color: #000;
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1::before {
    content: "";
    position: absolute;
    background-image: url(../src/assets/landingnew/brain.png);
    background-repeat: no-repeat;
    height: 82px;
    width: 82px;
    left: -54px;
    transform: translate(0px, -13px);
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before {
    content: "";
    position: absolute;
    background-image: url(../src/assets/landingnew/heart.png);
    background-repeat: no-repeat;
    height: 82px;
    width: 82px;
    left: -54px;
    transform: translate(0px, -13px);
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(3)::before {
    content: "";
    position: absolute;
    background-image: url(../src/assets/landingnew/kidneys.png);
    background-repeat: no-repeat;
    height: 82px;
    width: 82px;
    left: -54px;
    transform: translate(0px, -13px);
}

.landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(4)::before {
    content: "";
    position: absolute;
    background-image: url(../src/assets/landingnew/Eye.png);
    background-repeat: no-repeat;
    height: 82px;
    width: 82px;
    left: -54px;
    transform: translate(0px, -13px);
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext {
    margin-bottom: 38px;
}

.landingnew_wrpr .blk3humanbodymainbg {
    background-size: cover;
    background-position: center top;
}

.landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtextmain::before {
    opacity: 0.2;
}

.bottmobutton {
    display: none;
}

.form_grp .MuiSelect-select {
    text-align: left !important;
}

.rightwrp_formsection .form_grp .gender_dropdown .MuiFormHelperText-root {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.landingnew_wrpr .pece_mkbannerblock .rightwrp_forsection .signupform .form_grp h2 {
    margin-top: 25px;
    margin-bottom: 5px;
}

.landingnew_wrpr .pece_mkbannerblock .rightwrp_forsection .form_grp .MuiInputBase-formControl {
    margin: 20px 0 0 0;
}

.landingnew_wrpr .pece_mkbannerblock .rightwrp_forsection .form_grp .buttonSection {
    margin-top: 30px;
}

@media screen and (max-width: 1199px) {
    .landingnew_wrpr .arrow_Up_text {
        display: none;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerrightsection {
        margin-top: 0;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {
        width: auto;
        margin-bottom: 20px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerrightsection {
        width: 100%;
    }

    .landingnew_wrpr .mk_innerrightsection {
        left: 0;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext {
        margin-bottom: 20px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text {
        margin-bottom: 20px;
    }

    .landingnew_wrpr .subtitlemainheader {
        margin-bottom: 30px !important;
    }

    .landingnew_wrpr .signupblocktwo .listwrapper {
        margin-bottom: 30px;
    }

    .landingnew_wrpr .organtest_wrpr {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
        left: 0;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        margin: 15px 0;
        width: 49%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 30px 10px 10px 30px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(3)::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(4)::before {
        left: 0;
        transform: translate(0%, 0%);
        height: 82px;
        width: 82px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 23px;
    }

    .landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper {
        display: none;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr {
        flex-direction: column;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper {
        width: auto;
        background-size: auto, 17px 100%, auto auto, 83% 16px, 196px auto;
        padding: 20px 100px 20px 50px;
        text-align: center;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
        font-size: 50px;
        line-height: 54px;
        display: inline;
        width: auto;
    }

    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
        right: -2%;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text {
        width: 100%;
        display: block;
        margin: 0 auto 15px;
        text-align: center;
        line-height: 40px;
        padding: 0;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        left: 0;
        font-size: 30px;
    }

    .landingnew_wrpr .pece_mkbannerblock .pecemk_bannerpara p {
        text-align: center;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 20px;
        padding-right: 0;
        padding-left: 80px;
        margin: 10px;
    }

    .right_toptext .mobileviewtxt {
        display: none;
    }

    .right_toptext .address1 br {
        display: none;
    }

    .bannertagimg {
        display: none;
    }

    .landingnew_wrpr .pece_mkbannerblock .rightwrp_forsection .form_grp .buttonSection {
        margin-top: 0px;
    }
}

@media screen and (max-width: 1025px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 18px;
    }
}

@media screen and (max-width: 991px) {
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
        font-size: 40px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 40px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        margin: 15px 0;
    }
}

@media screen and (max-width: 900px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 16px;
    }
}

@media screen and (max-width: 820px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        margin: 10px 0;
        font-size: 20px;
        padding-left: 100px;
    }
}

@media screen and (max-width: 720px) {

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(3)::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(4)::before {
        transform: translate(0%, 0%);
    }
}

@media screen and (max-width: 699px) {
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper {
        padding: 20px 80px 20px 20px;
    }

    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp img {
        width: 70%;
    }

    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
        right: -5%;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
        font-size: 35px;
        line-height: 42px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 25px;
        line-height: 28px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 35px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text {
        line-height: 35px;
    }
}

@media screen and (max-width: 445px) {
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text {
        display: none;
    }

    .right_toptext .mobileviewtxt {
        display: block;
    }

    .right_toptext .tabviewtxt {
        display: none;
    }

    .title1 {
        display: none !important;
    }

    .right_toptext {
        display: flex;
        flex-direction: column-reverse;
    }

    .right_toptext .mobileviewtxt {
        margin-top: 10px;
    }

    .right_toptext .address1 br {
        display: block;
    }

    .mobileviewlist {
        display: block;
        margin-bottom: 14px;
    }

    .mobviewclose {
        display: none;
    }

    .bottmobutton {
        display: block;
    }

    .nxt2banner {
        margin: -40px auto 20px !important;
    }
}

@media screen and (max-width: 400px) {
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
        font-size: 17px;
        line-height: 27px;
    }

    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
        right: -16%;
        top: 6px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        border-radius: 50px 10px 10px 50px;
    }
}

@media screen and (max-width: 360px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 12px;
        padding-left: 79px;
    }
}

.form_grp .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: #fff;
}

.nxt2banner {
    display: none;
}

@media screen and (max-width: 1199px) {
    .nxt2banner {
        display: block;
        margin: -20px auto 20px;
        text-align: center;
    }
}

.form_grp .MuiFormControl-root .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    text-align: left;
}

.topheader_mainwrpr {
    display: none !important;
}

.header_subwrpr {
    padding: 22px 0 5px;
    border-radius: 10px;
}

.left_section img {
    max-width: 80%;
    width: 80%;
}

.right_toptext {
    margin-bottom: 5px;
    width: 100%;
}

.right_toptext h1 {
    color: #fcff00;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
}

.right_toptext p {
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 23px;
}

.right_bottomcontent {
    background-color: #fbff00;
    display: flex;
    padding: 9px;
    border-radius: 5px;
    border: 1px solid #d7ae11;
    position: relative;
}

.right_bottomcontent p {
    font-size: 16px;
    color: #000;
    font-weight: 700;
    position: relative;
    margin-left: 24px;
}

.right_bottomcontent p::before {
    content: "";
    background-color: #fe0000;
    position: absolute;
    height: 7px;
    width: 7px;
    left: -14px;
    transform: translateX(0px);
    bottom: 5px;
}

.human_img {
    position: absolute;
    right: -130px;
    bottom: -43px;
}

.header_bottomwrpr {
    background: rgb(116, 130, 137);
    background: linear-gradient(180deg, rgba(116, 130, 137, 1) 0%, rgba(35, 40, 43, 1) 100%);
    padding: 20px 16px 20px 30px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin-bottom: 14px !important;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 50%) inset;
    -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 50%) inset;
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5) inset;
    z-index: 99;
    display: block;
    border: 1px solid #fff;
}

.header_bottominnerwrpr h3 {
    color: inherit;
    text-transform: initial;
    background: #fcfcfd;
    background: linear-gradient(180deg, rgba(252, 252, 253, 1) 0%, rgba(167, 187, 196, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    font-size: 22px;
    line-height: 22px;
    margin: 0;
    font-family: "Roboto", "sans-serif", "Montserrat";
    text-transform: uppercase;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
}

.randomimgwrpr {
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 100%;
    border: 8px solid #302a51;
    padding: 5px;
}

.lighttheme-mob-banner {
    background: #ffffff8c;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.darktheme-mob-banner {
    background: #07172466;
    padding: 10px;
    border-radius: 10px;
}

.practiceNamebnr h3 {
    font-size: 30px;
    color: #ffffff9e;
}

img.header {
    max-width: 98%;
    display: block;
    margin: 10px auto;
    width: auto;
}

.nopage404 {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    padding: 0px;
    background: #fff958;
    flex-wrap: wrap;
    width: 100%;
    min-height: 100vh
}

.nopage404 h1 {
    padding: 0px;
    margin: 0px;
    color: #000;
    font-size: 10em;
}

.intake_form_banner_wrapper1_body {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/quickintake_banner_IMG.webp) no-repeat left bottom;
    padding: 55px 25px 55px 580px;
    background-size: 550px;
}

.intake_form_banner_wrapper1 {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/quickintake_banner_mainbg.webp) no-repeat left top;
    background-size: cover;
    padding: 0;
    margin-bottom: 15px;
    position: relative;
}

.intake_form_banner_wrapper1_text1BG {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/quickintake_banner_shadow2.webp) no-repeat left bottom;
    padding-bottom: 100px;
}

.intake_form_banner_wrapper1_text_con {
    border: solid 8px #cdcdcd;
    border-radius: 15px;
    background: #137eea;
    background: linear-gradient(190deg, rgba(19, 126, 234, 1) 0%, rgba(1, 64, 129, 1) 100%);
}

.intake_form_banner_wrapper1_text_con h2 {
    margin: 0;
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/quickintake_banner2.webp) no-repeat right top;
    background-size: cover;
    padding: 20px;
    font-size: 38px;
    color: #fff;
    text-shadow: 0 2px 2px #0a3d72;
    border-radius: 10px;
}

.intake_form_banner_wrapper1_text2BG {
    margin-left: 10%;
    margin-top: -140px;
}

.intake_form_banner_wrapper1_text2_con {
    border: solid 1px #fff;
    border-radius: 15px;
    background: #ffe513;
}

.intake_form_banner_wrapper1_text2_con h2 {
    margin: 0;
    padding: 20px;
    font-size: 30px;
    color: #000;
    border-radius: 10px;
}

.intake_form_banner_wrapper2_body {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/quickintake_banner_IMG.webp) no-repeat left bottom;
    padding: 55px 25px 55px 580px;
    background-size: 580px;
}

.intake_form_banner_wrapper2 {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/quickintake_banner_mainbg2.webp) no-repeat left top;
    background-size: cover;
    padding: 0;
    margin-bottom: 15px;
    position: relative;
}

.intake_form_banner_wrapper2_text1BG {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/quickintake_banner_shadow2.webp) no-repeat left bottom;
}

.intake_form_banner_wrapper2_text_con {
    border: solid 8px #cdcdcd;
    border-radius: 15px;
    background: #4cacde;
    background: linear-gradient(190deg, rgba(76, 172, 222, 1) 0%, rgba(18, 80, 134, 1) 100%);
}

.intake_form_banner_wrapper2_text_con h2 {
    margin: 0;
    background: url(https://all-frontend-assets.s3.amazonaws.com/pecemarketinglandingpage/assets/images/quickintake_banner2.webp) no-repeat right top;
    background-size: cover;
    padding: 34px 20px 40px 20px;
    font-size: 38px;
    color: #fff;
    text-shadow: 0 2px 2px #0a3d72;
    border-radius: 10px;
}

.intake_form_banner_wrapper2_text2BG {
    margin-left: 10%;
    margin-top: -140px;
}

.intake_form_banner_wrapper2_text2_con {
    border: solid 1px #fff;
    border-radius: 15px;
    background: #ffe513;
}

.intake_form_banner_wrapper2_text2_con h2 {
    margin: 0;
    padding: 20px;
    font-size: 30px;
    color: #000;
    border-radius: 10px;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
}

.phonmbr2 {
    color: #fff !important;
}

.wtheme .phonmbr2 {
    color: #000 !important;
}

.ltheme .phonmbr2 {
    color: #000 !important;
}

.authNsign .content {
    margin-left: 5px !important;
    margin-right: 5px !important;
    line-height: 1.6;
}

.capl {
    text-transform: capitalize !important;
    font-size: 22.55px !important;
}

.loaderGif {
    display: block;
    margin: 0px auto;
    height: 100vh;
}

.rootDivFlex {
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

.overflowLoader {
    position: fixed;
    z-index: 11;
    width: 99vw;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overflowLoader .loaderGif {
    height: 80px;
    width: 140px;
}

.quickIntakeMobBanner {
    align-items: center;
    display: block !important;
}

.quickIntakeMobBanner.heading {
    text-align: center;
    font-size: 35px !important;
    font-weight: bold;
}

.quickIntakeMobBanner p,
.quickIntakeMobBanner p a.phonmbr2 {
    color: #000 !important;
    font-size: 25px;
    line-height: 32px;
    font-weight: normal;
}

.quickIntakeMobBanner p a.phonmbr2 {
    color: #33a1acfc !important;
    margin-bottom: 15px;
    /* display: block; */
}

.quickIntakeMobBanner .left_section {
    display: block;
    width: 20%;
    margin: 0px auto;
}

.quickIntakeMobBanner .left_section img {
    max-width: 100%;
    width: 100%;
}

.quickIntakeMobBanner .right_section {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    text-align: center;
}

.darktheme .quickIntakeMobBanner.heading,
.darkthemedirect .quickIntakeMobBanner.heading {
    color: #00f7cb !important;
}

.darktheme .quickIntakeMobBanner p,
.darkthemedirect .quickIntakeMobBanner p {
    color: #fff !important;
}


/* Popup-specific styles */

.exit-intent-popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1111;
    background: rgba(33, 33, 33, 0.8);
    transform: translateY(60%) scale(0);
    transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.exit-intent-popup.visible {
    transform: translateY(0) scale(1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsletter .secOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.newsletter .secOne #practiceLogo {
    max-width: 230px;
    width: auto;
    min-width: 28%;
    height: 80px;
    background: #00547c;
    border-radius: 10px;
}

.newsletter .secOne #practiceLogo img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.newsletter .secOne .headerText {
    background: #00547c;
    border-radius: 10px;
    /* width: calc( 100% - 280px); */
    padding: 10px 16px 10px 17px;
    color: #fff;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 31px;
    font-weight: 700;
    position: relative;
    letter-spacing: 0.2px;
}

.newsletter .secOne .headerText::before {
    content: '\0022';
    position: absolute;
    margin-left: -7px;
}

.newsletter .secOne .headerText::after {
    content: '\0022';
    position: absolute;
}

.newsletter .secOne .headerText span {
    display: inline-block;
    font-size: 31px;
    font-weight: 800;
    letter-spacing: 0px;
}

.newsletter .secTwo {
    background: #00547c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    border-radius: 10px;
    padding: 0px 20px 0 10px;
    margin: 8px auto;
}

#practiceAddress {
    background: url('../src/assets/pin_icon.png') left center no-repeat;
    background-size: 26px;
    height: 30px;
    max-width: 50%;
    padding-left: 30px;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#practicePhone {
    background: #ce2d3a url('../src/assets/phone_icon.png') 12px center no-repeat;
    background-size: 32px;
    padding: 10px 10px 10px 56px;
    line-height: 30px;
    font-size: 24px;
    font-weight: 700;
}

.newsletter .secThree h2 {
    color: #00547c;
    font-size: 26px;
    margin: 0;
}

.newsletter .secThree h2 span {
    color: #000;
    font-size: 24px;
    display: block;
}

.newsletter .secFour p {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.newsletter .secFour p br {
    display: none;
}

.newsletter .secFive {
    font-size: 14px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 14px rgb(0 0 0 / 15%);
    overflow: hidden;
}

.contentDiv_body {
    padding: 20px 20px 4px 20px;
}

.contentDiv_bottom {
    background: url('../src/assets/global_popup_bg.webp') right bottom -8px no-repeat;
    background-size: 345px;
    padding: 10px 330px 20px 20px;
}

.newsletter .secFive .heading {
    background: #00547c;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 6px;
}

.newsletter .secFive ul {
    list-style: none;
    padding-left: 0px;
    margin: 6px auto;
}

.newsletter .secFive ul li {
    position: relative;
    line-height: 20px;
    padding-left: 26px;
}

.newsletter .secFive ul li::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #1397d6;
    position: absolute;
    left: 8px;
    top: 8px;
}

.newsletter {
    background: linear-gradient(137deg, rgba(249, 246, 203, 1) 0%, rgba(213, 214, 208, 1) 100%);
    border-radius: 3px;
    border: 10px solid #00547c;
    width: 920px;
    margin: 10px auto;
    /* padding: 35px 45px; */
    /* font-family: 'POPPINS'; */
    position: relative;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6) inset;
}

.newsletter .emailText {
    font-size: 19px;
    color: #fff;
    cursor: pointer;
    margin: 12px 0px 0px 0px;
    line-height: 26px;
    background: #0095ab url('../src/assets/popup_email_icon.png') left 10px center no-repeat;
    background-size: 20px;
    width: auto;
    display: inline-block;
    padding: 5px 15px 5px 38px;
    border-radius: 6px;
    text-transform: capitalize;
}

.newsletter .emailText span {
    /* font-size: 22px;
    display: inline-block;
    vertical-align: middle; */
    display: none;
}

.close,
.closeEmailPopup {
    position: absolute;
    top: -22px;
    right: -22px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 50%;
    line-height: 34px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
}


/* .contentDiv {
    overflow: hidden;
    overflow-y: scroll;
    max-height: 70vh;
} */

.emailContainer {
    display: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
}

.emailContainer.open {
    display: flex;
}

.emailContainer .innerContainer {
    background: #fff;
    width: 40%;
    max-width: 400px;
    border-radius: 6px;
    border: 8px solid #1185bd;
    position: relative;
}

.emailContainer .innerContainer .heading {
    background: #1185bd;
    color: white;
    padding: 10px;
    font-size: 22px;
}

.emailContainer .innerContainer .bodySec input {
    width: 94%;
    display: block;
    margin: 10px auto;
    line-height: 22px;
}

.emailContainer .innerContainer .MuiLinearProgress-colorPrimary {
    width: 94%;
    margin: 0px auto;
    margin-bottom: 10px;
}

.emailContainer .innerContainer button {
    background: #1976d2;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    padding: 3px 16px;
    border-radius: 4px;
    border: none;
    width: 90px;
    color: #fff;
    margin: 15px auto;
    margin-top: 4px;
    display: block;
    font-size: 17px;
    height: 32px;
    cursor: pointer;
}

.successSnackbar {
    position: absolute !important;
    left: calc(50vw - 200px) !important;
    bottom: 10vh !important;
}

.Snackbar_center {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.Snackbar_center .MuiPaper-root {
    background-color: #78952d;
}

.top-to-btm {
    position: relative;
}

.icon-position {
    position: fixed;
    bottom: 150px;
    right: 25px;
    z-index: 20;
}

.icon-style {
    background-color: #23cbe6;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-style svg {
    font-size: 30px;
}

.icon-style:hover {
    animation: none;
    background: #fff;
    color: #23cbe6;
    border: 2px solid #23cbe6;
}

.MuiDrawer-modal .searchBarCloseIcon button {
    position: absolute;
    right: 20px;
    top: 10px;
    background: #000;
    color: #fff;
    border-radius: 0 6px 0 10px;
}

.userFormLead .submitbtnsection button {
    font-size: 20px;
    line-height: 30px;
    border-radius: 10px;
    width: auto;
    padding: 10px;
    min-width: 150px;
}

.userFormLead {
    width: calc(100% - 20px) !important;
}

.myaccountWrapper {
    width: 98%;
    margin: 10px auto;
    margin-top: 25px;
}

.myaccountheading {
    margin: 0;
    padding: 15px;
    background: rgb(255, 246, 0);
    background: linear-gradient(180deg, rgba(255, 246, 0, 1) 0%, rgba(255, 217, 1, 1) 100%);
    border-radius: 10px;
    font-size: 25px;
    line-height: 26px;
    font-weight: 800;
    color: #333;
}

.myaccountheading a {
    color: #50728d;
    text-decoration: none;
    cursor: pointer;
}

.myaccountheading a span {
    color: #333;
}

.myaccountheading span {
    display: inline-block;
    padding: 1px 10px;
}

.myaccountForm {
    margin: 25px 0;
}

.myaccountForm .formCls {
    background: #fff !important;
    margin: 0 !important;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 0 8px rgb(0 0 0 / 50%);
    overflow: hidden;
}

.myaccountFormSub {
    background-color: #fff;
    padding: 15px;
    overflow: hidden;
    margin: 0 !important;
    border-radius: 8px;
    box-shadow: 0 0 8px rgb(0 0 0 / 50%);
}

.myaccountForm .formCls .formInline {
    display: flex;
    width: auto;
    justify-content: space-between;
}

.myaccountForm .formCls .formInline .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0 10px;
    width: calc(100% - 20px);
    height: 100%;
    display: flex;
}

.myaccountForm .formCls .MuiFormControl-root {
    display: block;
    box-shadow: none;
    width: 100%;
}

.myaccountForm .formCls fieldset {
    top: 0 !important;
}

.myaccountForm .formCls .MuiFormControl-root .MuiOutlinedInput-root,
.myaccountForm .formCls .MuiFormControl-root textarea {
    display: flex;
    width: 100%;
    padding: 0px;
    resize: none;
}

.myaccountForm .formCls .MuiFormControl-root textarea {
    padding: 10px;
    border-radius: 0;
    height: 70px !important;
    width: calc(100% - 20px);
}

.myaccountForm .formCls .MuiFormControl-root .MuiInputLabel-root {
    display: none;
}

.myaccountForm .formCls .form_item {
    margin-bottom: 10px;
}

.myaccountForm .formCls label.MuiInputLabel-root div {
    color: #021d3a !important;
    font-size: 18px !important;
}

.myaccountForm .formCls label.MuiFormLabel-root {
    color: #021d3a !important;
    font-size: 18px !important;
    margin-bottom: 5px;
}

.myaccountForm .resatbuttonwrapper {
    display: flex;
}

.myaccountForm .resatbuttonwrapper button {
    cursor: pointer;
    background: #3fedfd;
    background: linear-gradient(180deg, rgba(63, 237, 253, 1) 0%, rgba(7, 177, 242, 1) 100%);
    border: 0 solid #00dcff;
    border-radius: 5px;
    padding: 8px 30px 4px;
    display: block;
    text-decoration: none;
    width: auto;
    margin: 0px;
    box-shadow: 0px 2px 1px white inset, 0px -2px 8px white, 0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
    -webkit-transition: box-shadow 0.5s;
    font-size: 24px;
    color: var(--white);
    text-transform: uppercase;
    margin-top: 15px;
    margin-right: 10px !important;
}

.myaccountForm Button[type="submit"] {
    cursor: pointer;
    background: #3fedfd;
    background: linear-gradient(180deg, rgba(63, 237, 253, 1) 0%, rgba(7, 177, 242, 1) 100%);
    border: 0 solid #00dcff;
    border-radius: 5px;
    padding: 8px 30px 4px;
    display: block;
    text-decoration: none;
    width: auto;
    margin: 0px;
    box-shadow: 0px 2px 1px white inset, 0px -2px 8px white, 0px 2px 5px rgb(0 0 0 / 10%), 0px 8px 10px rgb(0 0 0 / 10%);
    -webkit-transition: box-shadow 0.5s;
    font-size: 24px;
    color: var(--white);
    text-transform: uppercase;
    margin-top: 15px;
}

.myaccountForm .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    padding: 0 10px !important;
    height: 100%;
    display: flex;
    align-items: center;
    width: fit-content;
}

.myaccountForm .inputBlock4line {
    width: 24%;
    margin: 0;
}

.myaccountForm .addressbox {
    width: 100%;
    margin: 0;
}

.myaccountForm .inputBlock3line {
    width: 32.3%;
    margin: 0;
}

.MyAccountDivmenu ul {
    margin: 0;
    padding: 0;
}

.MyAccountDivmenu ul li {
    text-align: center;
    border-bottom: solid 1px #ccc;
    font-size: 16px;
    display: block;
}

.MyAccountDiv {
    position: fixed;
    right: 0;
    top: 10px;
    border-radius: 10px 0 0 10px;
    padding: 4px 0 0 6px;
    cursor: pointer;
    background: rgba(137, 137, 37, 90%);
    z-index: 9;
    color: #fff;
}

.MyAccountDiv svg {
    color: #fff;
    width: 30px;
    height: 30px;
}

.MyAccountDiv::after {
    content: '';
    background: rgba(137, 137, 37, 90%);
    clip-path: polygon(100% 0, 0 53%, 100% 100%);
    width: 6px;
    height: 24px;
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
}

.adminformbody {
    margin: 15px auto;
    width: 90%;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    border-radius: 10px;
}

.adminformbody .formInline {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #f5f5f5;
    padding: 15px 45px;
    border-radius: 10px;
}

.adminformbody .formInline .inputBlock .MuiFormControl-root.MuiTextField-root {
    background-color: #fff;
    width: 100%;
    margin-bottom: 0;
    border: solid 1px #ccc;
    height: 48px;
}

.admin_addForm .backendTableInline_addform .description .MuiFormControl-root textarea {
    resize: none;
    border: 1px solid rgba(0, 0, 0, 0.23);
    outline: none;
}

.adminformbody .formInline .inputBlock1line {
    flex: 100%;
    margin: 10px 0.3%;
}

.adminformbody .formInline .singleCheckbox {
    margin: 0 0.3%;
}

.CommissionDiv {
    text-align: right;
    margin: 15px;
    padding: 15px;
    color: #ffa800;
    font-size: 24px;
    line-height: 30px;
    background: #efeeee;
    border-radius: 5px;
}

.intakeFormbnrHeader {
    padding: 0 !important;
    width: 100% !important;
}


/* ==============--------------------->>>>>>>>>>>>responsive START<<<<<<<<<<<<<<<<<<<<<<<<<--------------------------============== */

@media screen and (max-width: 1800px) {
    .landingnew_wrpr .signup_bannerarrow .arrow_Up_text h3 {
        font-size: 35px;
    }

    .landingnew_wrpr .signup_bannerarrow .arrow_Up_text {
        top: -10px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 40px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 60px;
    }
}

@media screen and (max-width: 1770px) {

    /* .landingnew_wrpr .mk_innerrightsection h2{
    left: -132px;
  } */
    /* .landingnew_wrpr .organtest_wrpr{
    margin-top: 60px;
  } */
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 18px;
    }

    .landingnew_wrpr .arrow_Up_text h3 {
        font-size: 35px;
    }
}

@media screen and (max-width: 1720px) {
    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
        width: auto;
        right: -22%;
        top: -7px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper {
        width: 300px;
    }

    /* .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1{
    font-size: 35px;
  } */
    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp img {
        width: 65%;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 38px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 58px;
    }

    .landingnew_wrpr .mk_innerrightsection {
        left: -25px;
    }

    .landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper {
        bottom: -113px;
        right: -144px;
    }

    .landingnew_wrpr .pece_mkbannerblock .signup_bannerarrow h2 {
        font-size: 37px;
    }
}

@media screen and (max-width: 1620px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 17px;
    }

    .landingnew_wrpr .arrow_Up_text h3 {
        font-size: 30px;
    }
}

@media screen and (max-width: 1580px) {
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerrightsection {
        width: 54%;
        margin-top: -45px;
    }

    /* .landingnew_wrpr .arrow_Up_text h3 {
    font-size: 30px;
} */
    /* .landingnew_wrpr .mk_innerrightsection h2{
    font-size: 37px;
  } */
    /* .landingnew_wrpr .mk_innerrightsection h2 span{
    font-size: 57px;
  } */
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        /* font-size: 18px; */
        padding: 8px 30px;
        width: 80%;
    }

    .landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper {
        right: -180px;
    }

    /* .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p{
    width: 85%;
  } */
    .landingnew_wrpr .signup_bannerarrow .arrow_Up_text {
        right: 190px;
        top: -4px;
    }

    .landingnew_wrpr .signup_bannerarrow .arrow_Up_text h3 {
        font-size: 30px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 54px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 35px;
    }

    .landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper img {
        width: 90%;
    }

    /* .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2{
    left: -84px;
  } */
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 17px;
    }
}

@media screen and (max-width: 1520px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 15px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        width: 37%;
    }
}

@media screen and (max-width: 1460px) {
    .pece_mkbannerblock .leftwrp_content .mk_innerleftsection {
        width: 30%;
    }

    .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .textwrapper h1 {
        font-size: 30px;
    }

    /* .landingnew_wrpr .mk_innerrightsection h2 {
    left: -32px;
    font-size: 32px;
} */
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {
        width: 80%;
    }

    .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext h2 {
        font-size: 26px;
    }

    .landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper img {
        width: 88%;
    }

    .landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper {
        right: -208px;
    }

    .landingnew_wrpr .organtest_wrpr {
        position: relative;
        left: 30px;
        margin-top: -15px;
    }

    /* .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
  left: -35px;
} */
}

@media screen and (max-width: 1400px) {
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 33px;
        line-height: 40px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 50px;
    }

    .landingnew_wrpr .organtest_wrpr {
        left: 10px;
    }

    .landingnew_wrpr .arrow_Up_text {
        margin-bottom: -70px;
        bottom: 33px;
    }

    .landingnew_wrpr .arrow_Up_text h3 {
        font-size: 25px;
    }

    .landingnew_wrpr .pece_mkbannerblock .signup_bannerarrow h2 {
        font-size: 34px;
    }

    .intake_form_banner_wrapper2_body {
        background-size: 480px;
    }

    .intake_form_banner_wrapper1_body {
        background-size: 480px;
    }

    .intake_form_banner_wrapper2_text_con h2 {
        font-size: 30px;
    }

    .intake_form_banner_wrapper2_text2_con h2 {
        font-size: 28px;
    }

    .intake_form_banner_wrapper1_text_con h2 {
        font-size: 30px;
    }

    .intake_form_banner_wrapper1_text2_con h2 {
        font-size: 28px;
    }
}

@media screen and (max-width: 1360px) {
    #practicePhone {
        font-size: 22px;
        line-height: 26px;
    }

    /* .landingnew_wrpr .mk_innerrightsection h2 span{
    font-size: 53px;
  } */
    /* .landingnew_wrpr .organtest_wrpr {
    left: 17px;
} */
    .landingnew_wrpr .signup_bannerarrow .arrow_Up_text h3 {
        font-size: 25px;
    }

    .landingnew_wrpr .signup_bannerarrow .arrow_Up_text {
        top: 2px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 br {
        display: none;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 50px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 32px;
    }

    /* .contentDiv {
        background-size: 54%;
        overflow-y: scroll;
        height: 71vh;
    } */
    /* .newsletter .secFive {
        width: 57%;
    } */
    .newsletter .secThree h2 {
        font-size: 24px;
    }

    .newsletter .secThree h2 span {
        font-size: 22px;
    }

    #practiceAddress {
        font-size: 22px;
        height: 26px;
        line-height: 26px;
    }

    .newsletter .secFour p {
        margin-top: 6px;
    }
}

@media screen and (max-width: 1320px) {
    /* .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
    left: -18px;
 } */
    /* .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper{
  width: 250px;
  }
  .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
    font-size: 30px;
} */
}

@media screen and (max-width: 1300px) {

    /* .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
    left: -18px;
} */
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper {
        width: 270px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
        font-size: 22px;
        line-height: 25px;
    }

    /* .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
    font-size: 30px;
} */
    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
        right: -22%;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerrightsection {
        width: 57%;
    }

    .landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper {
        bottom: -100px;
        right: -218px;
    }

    .landingnew_wrpr .arrow_Up_text h3 {
        font-size: 22px;
    }

    .landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper img {
        width: 85%;
    }

    .intake_form_banner_wrapper2_body {
        padding: 35px 15px 35px 380px;
        background-size: 380px;
    }

    .intake_form_banner_wrapper1_body {
        padding: 35px 15px 35px 380px;
        background-size: 380px;
    }

    .intake_form_banner_wrapper2_text_con h2 {
        font-size: 28px;
    }

    .intake_form_banner_wrapper2_text2_con h2 {
        font-size: 26px;
    }

    .intake_form_banner_wrapper1_text_con h2 {
        font-size: 28px;
    }

    .intake_form_banner_wrapper1_text2_con h2 {
        font-size: 26px;
    }
}


/* @media screen and (min-device-width: 1260px) {
    .newsletter {
        width: 801px;
    }
    .newsletter .secOne #practiceLogo {
        height: 60px;
    }
    .newsletter .secOne .headerText {
        font-size: 22px;
        height: 41px;
        line-height: 23px;
    }
    .newsletter .secOne .headerText span {
        font-size: 25px;
    }
    .newsletter .secTwo {
        margin: 8px auto;
    }
    #practiceAddress {
        font-size: 19px;
    }

    .newsletter .secThree h2 {
        font-size: 21px;
    }
    .newsletter .secThree h2 span {
        font-size: 19px;
    }
    .newsletter .secFour p {
        line-height: 17px;
    }
    .newsletter .secFour p br {
        display: none;
    }
 
    .newsletter .secFive ul {
        margin: 4px auto;
    }
    .newsletter .secFive .heading {
        font-size: 16px;
    }
} */

@media screen and (max-width: 1260px) {
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 32px;
    }

    .landingnew_wrpr .pece_mkbannerblock .signup_bannerarrow h2 {
        font-size: 32px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 30px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        width: 40%;
    }
}

@media screen and (max-width: 1199px) {
    .userFormLead .form_item {
        flex: 100% !important;
    }

    .userFormLead .formCls button {
        margin: 0 5px !important;
    }

    .landingnew_wrpr .arrow_Up_text {
        display: none;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerrightsection {
        margin-top: 0px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {
        width: auto;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .contenttextwrapper h2 {
        margin-bottom: 20px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerrightsection {
        width: 100%;
    }

    .landingnew_wrpr .mk_innerrightsection {
        left: 0px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .mk_innerleftsection .bluebgtext {
        margin-bottom: 20px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text {
        margin-bottom: 20px;
    }

    .landingnew_wrpr .subtitlemainheader {
        margin-bottom: 30px !important;
        /* margin-top: -25px; */
    }

    .landingnew_wrpr .signupblocktwo .listwrapper {
        margin-bottom: 30px;
    }

    /* .landingnew_wrpr .mk_innerrightsection h2 br{
  display: none;
} */
    /* .landingnew_wrpr .mk_innerrightsection h2{
  text-align: center;
  left: 0px;
} */
    .landingnew_wrpr .organtest_wrpr {
        display: flex;
        flex-direction: row;
        /* width: 100%; */
        justify-content: space-between;
        flex-wrap: wrap;
        /* align-items: center; */
        /* margin-top: 20px; */
        width: 100%;
        margin: 0px auto 0px;
        left: 0px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        margin: 15px 0;
        width: 49%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 30px 10px 10px 30px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(3)::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(4)::before {
        left: 0;
        /* right: 0; */
        transform: translate(0%, 0%);
        height: 82px;
        width: 82px;
        /* background-color: #ffffff; */
        /* border-radius: 0 10px 10px 0; */
    }

    /* .landingnew_wrpr .organtest_wrpr {
  margin-top: 20px;
} */
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 23px;
    }

    /* .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before{

} */
    .landingnew_wrpr .mk_innerrightsection .mk_rightimagewrapper {
        display: none;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr {
        flex-direction: column;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper {
        width: auto;
        background-size: auto, 17px 100%, auto auto, 83% 16px, 196px auto;
        padding: 20px 100px 20px 50px;
        text-align: center;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
        font-size: 50px;
        line-height: 54px;
        display: inline;
        width: auto;
    }

    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
        right: -2%;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text {
        width: 100%;
        display: block;
        margin: 0 auto 15px;
        text-align: center;
        line-height: 40px;
        padding: 0px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        left: 0;
        font-size: 30px;
    }

    .landingnew_wrpr .pece_mkbannerblock .pecemk_bannerpara p {
        text-align: center;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        /* padding: 8px 15px; */
        font-size: 20px;
        padding-right: 0px;
        padding-left: 80px;
        margin: 10px;
    }

    .right_toptext .mobileviewtxt {
        display: none;
    }

    .right_toptext .address1 br {
        display: none;
    }

    .bannertagimg {
        display: none;
    }

    .right_section {
        display: flex;
        align-items: center;
    }

    .intake_form_banner_wrapper2_body {
        padding: 25px;
        background: none;
    }

    .intake_form_banner_wrapper1_body {
        padding: 25px;
        background: none;
    }

    .topheader_mainwrpr {
        display: block !important;
    }

    .header_subwrpr {
        margin-top: -16px !important;
    }

    .right_toptext .addresspara .blockadd {
        display: inline-block;
    }

    .right_toptext .addresspara a {
        text-decoration: none;
        color: #000 !important;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        margin-left: 5px;
    }

    .right_toptext .address1 a {
        color: #fff !important;
    }

    .signup2_main .right_toptext .address1 a {
        color: #000 !important;
    }

    .landingnew_wrpr .right_toptext .addresspara a {
        color: #fff !important;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .right_toptext .addresspara a img {
        max-width: 15px;
    }

    .nopage404 h1 {
        font-size: 6em;
    }
}

@media screen and (max-width: 1100px) {
    .right_toptext h1 {
        font-size: 25px;
    }
}

@media screen and (max-width: 1025px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 18px;
    }

    .newsletter {
        width: 80%;
        margin: 0px auto;
        position: fixed;
    }

    .newsletter .secOne .headerText {
        font-size: 20px;
        line-height: 24px;
    }

    .newsletter .secOne .headerText span {
        font-size: 22px;
    }

    .contentDiv {
        overflow-y: scroll;
    }

    .newsletter .secFour p br {
        display: none;
    }

    .newsletter .secFive .heading {
        font-size: 16px;
    }

    /* .newsletter .secFive {
        width: 60%;
    } */
    .newsletter .secFive ul li {
        font-size: 13px;
    }

    .newsletter .secOne #practiceLogo {
        height: 66px;
    }

    .newsletter .secThree h2 {
        font-size: 20px;
    }

    .newsletter .secThree h2 span {
        font-size: 20px;
    }

    #practicePhone {
        font-size: 18px;
        line-height: 20px;
    }
}

@media screen and (max-width: 991px) {
    .myaccountForm .inputBlock4line {
        width: 100%;
        margin: 0;
    }

    .myaccountWrapper {
        width: 90%;
    }

    .myaccountForm .inputBlock3line {
        width: 100%;
        margin: 0;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
        font-size: 40px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 40px;
    }

    /* .landingnew_wrpr .organtest_wrpr{
  width: 96%  !important;
} */
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        margin: 15px 0;
        /* width: 47.5%; */
    }

    /* .landingnew_wrpr .organtest_wrpr .orgn_bnr1::before, .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before, .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(3)::before, .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(4)::before{
  left: 82%;
} */
    .intake_form_banner_wrapper1_text_con h2 {
        font-size: 28px;
    }

    .intake_form_banner_wrapper1_text2_con h2 {
        font-size: 20px;
    }

    .intake_form_banner_wrapper2_text_con h2 {
        font-size: 28px;
    }

    .intake_form_banner_wrapper2_text2_con h2 {
        font-size: 20px;
    }

    .intake_form_banner_wrapper2_body {
        padding: 15px;
    }

    .intake_form_banner_wrapper1_body {
        padding: 15px;
    }

    .newsletter .secOne {
        display: block;
        height: auto;
    }

    .newsletter .secOne #practiceLogo {
        margin: 0px auto;
        margin-bottom: 15px;
    }

    .newsletter .secOne .headerText {
        width: auto;
        font-size: 19px;
        height: auto;
    }

    .newsletter .secTwo {
        display: block;
        padding: 0px;
        overflow: hidden;
    }

    #practiceAddress {
        background-position: left 10px center;
        padding: 6px 6px 6px 40px;
        height: auto;
        max-width: 100%;
        white-space: inherit;
    }

    .contentDiv_bottom {
        background: none;
        padding: 10px;
        padding-top: 0;
    }

    .contentDiv_body {
        padding: 10px;
    }

    .newsletter .secOne #practiceLogo {
        margin: 0px auto;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 960px) {
    .right_toptext h1 {
        font-size: 23px;
    }

    .right_toptext p {
        font-size: 16px;
        line-height: 19px;
        line-height: 22px;
    }
}

@media screen and (max-width: 900px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        font-size: 16px;
    }

    .patientIntakeFormheder .errortext {
        bottom: -4px;
    }
}

@media screen and (max-width: 899.98px) {
    .left_section img {
        display: block;
        margin: 0 auto;
    }

    .right_toptext h1 {
        text-align: center;
        font-size: 28px;
    }

    .right_toptext p {
        text-align: center;
        font-size: 19px;
    }

    .practiceNamebnr h3 {
        text-align: center;
    }

    .right_toptext .addresspara .blockadd {
        display: block;
    }

    .right_toptext .addresspara a {
        text-align: center;
        margin-left: 0;
        justify-content: center;
    }
}

@media screen and (max-width: 820px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        /* margin: 15px 0; */
        width: 100%;
    }
}


/* @media only screen and (max-width: 820px) {
    .newsletter .secOne #practiceLogo {
        height: 62px;
    }
    .newsletter .secOne .headerText {
        font-size: 15px;
        line-height: 22px;
        height: 42px;
    }
    .newsletter .secOne .headerText span {
        font-size: 17px;
    }
} */

@media screen and (max-width: 800px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        margin: 10px 0;
        /* padding: 8px 16px; */
        font-size: 20px;
        padding-left: 100px;
    }

    /* .landingnew_wrpr .organtest_wrpr .orgn_bnr1{
    min-height: 80px;
  } */
    /* .landingnew_wrpr .organtest_wrpr .orgn_bnr1::before, .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before, .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(3)::before, .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(4)::before{
    left: inherit;
    right: 0;
  } */
    /* .landingnew_wrpr .organtest_wrpr .orgn_bnr1::before, .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before, .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(3)::before, .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(4)::before{
    left: 2%;
    transform: translate(-2%, 0%);
  } */
    /* .landingnew_wrpr .mk_innerrightsection h2{
    line-height: 38px;
    font-size: 27px;
  }
  .landingnew_wrpr .mk_innerrightsection h2 span{
    font-size: 40px;
  } */
    .intake_form_banner_wrapper1_text1BG {
        background: none;
    }
}

@media screen and (max-width: 768px) {
    .newsletter .secFive ul li {
        line-height: 16px;
    }

    #practiceAddress {
        font-size: 18px;
    }

    .newsletter {
        width: 70%;
    }

    .newsletter .secOne {
        display: block;
        height: auto;
    }

    .newsletter .secOne #practiceLogo {
        margin: 0px auto;
        margin-bottom: 15px;
    }

    .newsletter .secOne .headerText {
        width: auto;
        font-size: 19px;
        height: auto;
    }

    .newsletter .secOne .headerText span {
        font-size: 18px;
    }

    .newsletter .secTwo {
        display: block;
        padding: 0px;
        overflow: hidden;
    }

    #practiceAddress {
        background-position: left 10px center;
        padding: 6px 6px 6px 40px;
        height: auto;
        max-width: 100%;
        white-space: inherit;
    }

    .newsletter .secThree h2 {
        font-size: 16px;
    }

    .newsletter .secThree h2 span {
        font-size: 21px;
    }

    .contentDiv {
        background: none;
    }

    .emailContainer .innerContainer {
        width: 64%;
    }

    .lighttheme .intake_form .heading,
    .ContentWrapper.bookingContentWrapper .quickIntakeMobBanner.heading {
        font-size: 22px !important;
        line-height: 26px;
    }

    .quickIntakeMobBanner .left_section {
        width: 200px;
        padding: 10px !important;
    }

    .quickIntakeMobBanner p a.phonmbr2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 720px) {

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(2)::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(3)::before,
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(4)::before {
        transform: translate(0%, 0%);
    }
}

@media screen and (max-width: 700px) {

    .right_toptext h1,
    .nopage404 h3 {
        font-size: 24px;
    }

    .nopage404 h1 {
        font-size: 4em;
    }

    .newsletter .secThree h2 span {
        font-size: 18px;
    }

    .newsletter .secFour p {
        font-size: 12px;
    }

    .newsletter .secFive .heading {
        font-size: 14px;
        padding: 5px;
    }

    .close,
    .closeEmailPopup {
        position: absolute;
        top: -12px;
        right: -12px;
        cursor: pointer;
        height: 30px;
        width: 30px;
        background: #fff;
        border-radius: 50%;
        line-height: 28px;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 699px) {

    /* .landingnew_wrpr .mk_innerrightsection h2{
    font-size: 25px;
  }
  .landingnew_wrpr .mk_innerrightsection h2 span{
    font-size: 30px;
  } */
    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper {
        padding: 20px 80px 20px 20px;
    }

    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp img {
        width: 70%;
    }

    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
        right: -5%;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
        font-size: 35px;
        line-height: 42px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 25px;
        line-height: 28px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 35px;
        line-height: 38px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text {
        line-height: 35px;
    }
}

@media screen and (max-width: 640px) {
    .intake_form_banner_wrapper1 {
        padding: 10px;
    }

    .intake_form_banner_wrapper1_text1BG {
        padding-right: 0;
    }

    .intake_form_banner_wrapper1_text2BG {
        margin-top: -150px;
        margin-right: 5%;
        margin-left: 5%;
    }

    .intake_form_banner_wrapper2 {
        padding: 10px;
    }

    .intake_form_banner_wrapper2_text1BG {
        padding-right: 0;
    }

    .intake_form_banner_wrapper2_text2BG {
        margin-top: -150px;
        margin-right: 5%;
        margin-left: 5%;
    }

    .intake_form_banner_wrapper2_body {
        padding: 0;
    }

    .intake_form_banner_wrapper1_body {
        padding: 0;
    }
}

@media screen and (max-width: 599px) {

    .newsletter .secFour p:nth-of-type(1),
    .newsletter .secFour p:nth-of-type(2) {
        display: none;
    }

    .formCls .formInline .formGroup {
        flex: 0 0 100% !important;
        padding-left: 0;
    }

    .modalblock .MuiBox-root {
        width: 80%;
    }

    .modalblock .MuiBox-root h2 {
        font-size: 24px;
        line-height: 24px;
    }

    .formCls .formInline .formGroup {
        flex: 0 0 100% !important;
        padding-left: 0;
    }

    .modalblock .MuiBox-root {
        width: 80%;
    }

    .modalblock .MuiBox-root h2 {
        font-size: 24px;
        line-height: 24px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper h1 {
        font-size: 22px;
        line-height: 27px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 30px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 20px;
        line-height: 22px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        padding-right: 0;
    }
}

@media screen and (max-width: 500px) {
    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        width: 100%;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        margin: 5px 0;
        font-size: 14px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 {
        margin: 23px 0 10px;
        padding: 6px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1:nth-child(1) {
        margin-top: 10px !important;
    }

    .landingnew_wrpr .pece_mkbannerblock .pecemk_bannerpara p {
        font-size: 18px;
    }

    .landingnew_wrpr .organtest_wrpr {
        left: 0;
        margin: 0 auto;
    }

    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp {
        right: -13%;
        top: 6px;
    }

    .landingnew_wrpr .pece_mkbannerblock .tagimg1wrp img {
        width: 45%;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .textwrapper {
        padding: 20px 50px 20px 20px;
        margin-bottom: 5px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 span {
        font-size: 25px;
        line-height: 30px;
    }

    .landingnew_wrpr .pece_mkbannerblock .leftwrp_content .top_text_wrpr .rightnew_text h2 {
        font-size: 17px;
    }

    .landingnew_wrpr .organtest_wrpr .orgn_bnr1 p {
        width: 100%;
        padding-left: 87px;
    }

    .landingnew_wrpr .mk_innerrightsection {
        left: 0;
    }

    .landingnew_wrpr .footercopy {
        padding: 15px;
        width: auto;
    }

    .copyrighttext p {
        font-size: 14px;
    }

    .newsletter {
        width: 82%;
    }

    .emailContainer .innerContainer .heading {
        font-size: 16px;
    }

    .emailContainer .innerContainer .bodySec input {
        width: 90%;
    }

    .emailContainer .innerContainer button {
        width: 84px;
        font-size: 15px;
        height: 26px;
    }
}

@media screen and (max-width: 480px) {
    .intake_form_banner_wrapper2_text_con h2 {
        font-size: 26px;
    }

    .intake_form_banner_wrapper2_text2_con h2 {
        font-size: 18px;
    }

    .intake_form_banner_wrapper2_text_con h2 {
        padding: 15px;
        padding-bottom: 60px;
    }

    .newsletter .secOne .headerText {
        width: auto;
    }

    .newsletter .secFive {
        background: #fff;
    }

    .lighttheme .intake_form .heading,
    .ContentWrapper.bookingContentWrapper .quickIntakeMobBanner.heading {
        font-size: 18px !important;
        line-height: 22px;
    }

    .right_toptext p {
        font-size: 16px;
        line-height: 18px;
    }

    .lighttheme .intake_form .form .quick-symptom-section .heading h3,
    .lighttheme .intake_form .otherDetails-blocks .heading h3,
    .lighttheme .intake_form .patientIntakeFormheder .First-form .heading h3,
    .lighttheme .intake_form .heading h3 {
        font-size: 16px !important;
    }

    .intake_form .form .quick-symptom-section .heading p {
        font-size: 17px;
    }

    .lighttheme .intake_form .quick-symptom-section .quick-symptom-blocks,
    .lighttheme .intake_form .otherDetails-blocks .fields {
        font-size: 14px;
        line-height: 18px;
    }

    /* .contentDiv_bottom {
        background: none;
        padding: 10px;
    } */
}

@media screen and (max-width: 445px) {
    .right_toptext h1 {
        font-size: 22px;
    }

    .right_toptext p {
        font-size: 16px;
    }

    .healthcare_bannerblock .right_toptext h1 {
        font-size: 16px !important;
    }

    .right_toptext h1 {
        font-size: 16px;
    }

    .signup2_main .right_toptext h1 {
        font-size: 16px;
    }

    .header_bottomwrpr {
        padding: 10px;
    }

    .header_bottominnerwrpr h3 {
        font-size: 19px;
    }

    .textwrapper {
        display: none !important;
    }

    .tagimg1wrp {
        display: none;
    }

    .pece_mkbannerblock .pecemk_bannerpara p {
        font-size: 19px !important;
    }

    .nopage404 h1 {
        font-size: 2.5em;
    }
}


/* @media screen and (max-width: 425px) {
    .contentDiv {
        padding: 20px
    }
} */


/* Iphone XR */

@media screen and (max-width: 414px) {
    .newsletter .secOne .headerText {
        font-size: 15px;
        line-height: 18px;
    }

    .newsletter .secOne .headerText span {
        font-size: 18px;
        line-height: 20px;
    }

    #practiceAddress,
    .newsletter .emailText {
        font-size: 16px;
        line-height: 20px;
    }

    .newsletter .secThree h2 {
        font-size: 15px;
    }

    .newsletter .secThree h2 span {
        font-size: 14px;
    }

    .newsletter .secFour p,
    .newsletter .secFive ul li {
        font-size: 13px;
    }

    .newsletter .secFive .heading {
        font-size: 14px;
    }

    .newsletter .secFour p br {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .newsletter .secThree h2 {
        font-size: 12px;
    }

    .newsletter .secThree h2 span {
        font-size: 12px;
    }

    .newsletter .secFive .heading {
        font-size: 12px;
    }

    .newsletter .secOne .headerText {
        padding: 10px;
    }

    .newsletter .secOne #practiceLogo {
        margin: 0px auto;
        margin-bottom: 10px;
        width: 120px;
    }

    .newsletter .secOne .headerText span {
        font-size: 14px;
        line-height: 16px;
    }

    .newsletter .secOne .headerText {
        font-size: 13px;
        line-height: 14px;
    }

    #practiceAddress {
        font-size: 12px;
        background-size: 20px;
    }

    .newsletter .emailText {
        font-size: 12px;
        background-size: 13px;
        padding-left: 28px;
    }

    #practicePhone {
        font-size: 12px;
        line-height: 16px;
    }

    #practicePhone {
        background-size: 25px;
        padding: 6px 10px 6px 42px;
        background-size: 20px;
    }
}


/* Iphone SE */

@media screen and (max-width: 375px) {

    .newsletter .secFour p,
    .newsletter .secFive ul li {
        font-size: 12px;
    }
}


/* Sumsung Galaxy S8+ */


/* @media screen and (max-width: 360px) {
    #practiceAddress,
    .newsletter .emailText {
        font-size: 15px;
    }
    .newsletter .secThree h2 {
        font-size: 14px;
    }
} */

.copyRightSection_modal .MuiBox-root {
    border-radius: 10px;
    padding: 25px;
    background: #fff;
    border: 6px solid #00abff;
}

.typo {
    margin-top: 0 !important;
}

.copyRightSection_modal .close {
    background-color: #00abff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pp_txt,
.term_cond_txt {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
}

@media screen and (max-width: 599px) {
    .copyRightSection_modal .MuiBox-root {
        width: 70%;
    }
}

.as_formwrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
}

.as_formwrapper .formFeild {
    flex: 1 0 49%;
    margin: .5%
}

.as_formwrapper .formFeild .MuiFormControl-root {
    background: #dfdfdf;
    border-radius: 5px;
    border: 1px solid white;
}

.as_formwrapper .formFeild .MuiInputLabel-root.MuiFormLabel-filled,
.as_formwrapper .formFeild .MuiInputLabel-root.Mui-focused {
    display: none;
}

.as_success_content {
    color: #fff;
    font-size: 30px;
}

.as_success_content span {
    display: block;
    font-size: 24px;
    margin-top: 8px;
}

/* ==========================footer===================== */
.footer_rxbrg {
    background: #5b6669;
    padding: 25px 0;
}

.foooter_rxbrg_bdy p {
    color: #aaaaa9;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
}

.foooter_rxbrg_bdy p a {
    cursor: pointer;
}

.foooter_rxbrg_bdy p a {
    color: #aaaaa9;
    text-decoration: none;
}