.lighttheme.thankyou,
.darktheme.thankyou {
    padding: 0!important
}

.darkthemedirect .thankyou-container,
.darktheme .thankyou-container {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape.webp), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape2.webp);
    background-color: #170036;
    background-position: -85px -158px, 627px 480px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    flex-wrap: wrap;
    width: 100%;
    min-height: 100vh
}

.darktheme.is_diabetesrexburg.thankyou {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/diabaticThankyouBg.png) center center;
    background-size: 100%;
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/diabaticsThankYouDarkShape.png), url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/diabaticThankYouDarkShape2.png);
    background-color: unset !important;
    background-position: -30px -8px, 1564px 505px;
    background-size: 30%, 17%;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    flex-wrap: wrap;
    width: 100%;
    min-height: 100vh
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content h1 {
    color: #fff !important;
    text-transform: uppercase;
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content h3 {
    color: #56f709 !important;
    text-transform: uppercase;
}

.darktheme.is_diabetesrexburg.thankyou .decisionDocPinIcon {
    width: 30px;
    display: inline-block;
    vertical-align: middle;
}

.darktheme.is_diabetesrexburg.thankyou .address {
    color: #00eaff !important;
}

.darktheme.is_diabetesrexburg.thankyou .appointmentP {
    background: #00aeff;
    width: 70%;
    margin: 0px auto;
    padding: 12px 16px;
    border-radius: 14px;
    color: #fff !important; margin-top: 10px;
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content p.appointmentP strong {
    color: #fff !important;
    text-transform: uppercase;
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content .timeP,
.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content .durationP {
    display: inline-block;
    color: #56f709 !important;
    text-transform: uppercase;
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content p strong {
    color: #56f709 !important;
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content .durationP {
    border-left: 1px solid #fff;
    margin-left: 10px;
    padding-left: 10px;
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content .descriptionP span {
    display: inline-block;
    color: #00eaff !important;
    font-size: 18px;
    margin-top: 15px;
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content .descriptionP span img {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    margin-bottom: 0px;
    margin-right: 5px;
}

.darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content .endText,
.darktheme.is_diabetesrexburg.as_darkthemestyle .thankyou-container .thankyou-block .content p.endText {
    color: #00eaff !important;
    text-decoration: none;
    font-style: normal;
    font-size: 44px !important;
    text-transform: uppercase;
    font-weight: bold;
}

.lightthemenew .thankyou-container,
.lighttheme .thankyou-container {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouLightShape.webp), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouLightShape2.webp), linear-gradient(#edfaff, #c1e5f6);
    background-position: -25px -28px, 600px 390px, 0 0;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    flex-wrap: wrap;
    width: 100%;
    min-height: 100vh
}

.thankyou-container .thankyou-block {
    text-align: center;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 30px);
}

.thankyou-container .thankyou-block .content {
    text-align: center;
    max-width: 1000px;
    padding: 0
}

.thankyou-container .thankyou-block .content h1 {
    font-size: 36px;
    line-height: 40px
}

.darkthemedirect .thankyou-container .thankyou-block .content p,
.darktheme .thankyou-container .thankyou-block .content p,
.darktheme .thankyou-container .thankyou-block .content p a {
    color: #fff
}

.darktheme .thankyou-container .thankyou-block .content p a {
    text-decoration: none; display: inline-block;
}

.lighttheme .thankyou-container .thankyou-block .content h1,
.lightthemenew .thankyou-container .thankyou-block .content h1 {
    color: #003c58
}

.lighttheme .thankyou-container .thankyou-block .content h3,
.lightthemenew .thankyou-container .thankyou-block .content h3 {
    color: #003c58
}

.thankyou-container .thankyou-block .content p strong,
.thankyou-container .thankyou-block .content p strong {
    color: #003c58
}

.darktheme .thankyou-container .thankyou-block .content h1,
.darkthemedirect .thankyou-container .thankyou-block .content h1 {
    color: #a7dfbb!important
}

.darktheme .thankyou-container .thankyou-block .content h3,
.darkthemedirect .thankyou-container .thankyou-block .content h3 {
    color: #a7dfbb!important
}

.darktheme .thankyou-container .thankyou-block .content p strong,
.darkthemedirect .thankyou-container .thankyou-block .content p strong {
    color: #a7dfbb!important
}


/* .darktheme .thankyou-container .thankyou-block .content h3,
.darkthemedirect thankyou-container .thankyou-block .content h3 {
    color: #003c58!important
}

.darktheme .thankyou-container .thankyou-block .content p strong,
.darkthemedirect .thankyou-container .thankyou-block .content p strong {
    color: #003c58!important
} */

.thankyou-container .thankyou-block .content p {
    font-size: 22px;
    margin: 10px 0
}

.thankyou-container .thankyou-block .content p.endText {
    font-style: italic;
    font-size: 18px;
    margin-top: 15px;
    text-decoration: underline
}

.darkthemedirect .thankyou-container .thankyou-block .content h3,
.darktheme .thankyou-container .thankyou-block .content h3 {
    font-size: 28px;
    color: #fff
}

.thankyou-container .thankyou-block .success_content p {
    font-size: 28px!important
}

.thankyou-container .thankyou-block .success_content p.errortext {
    color: red;
    font-size: 14px !important;
}

.is_diabetesrexburg .diabaticLogo {
    width: 270px!important;
    display: block;
    background: #fff;
    margin: 0px auto;
    padding: 10px;
    border-radius: 10px;
}

/* .thankyou-container .thankyou-block .content img.praticeLogo {
    width: 100%;
    margin-bottom: 10px
} */

.diabaticLogo img{   width: 270px!important; display: block;}

@media (max-width:701px) {
    .lighttheme .thankyou-container .thankyou-block .content .darktheme .thankyou-container .thankyou-block .content {
        max-width: 700px;
        line-height: 20px!important
    }
}

@media screen and (max-width:500px) {
    .lighttheme .thankyou-container .thankyou-block .content h1 .darktheme .thankyou-container .thankyou-block .content h1 {
        font-size: 30px;
        line-height: 30px
    }
    .lighttheme .thankyou-container .thankyou-block .content p,
    .darktheme .thankyou-container .thankyou-block .content p {
        font-size: 17px;
        margin-bottom: 0
    }
}

.canceledAlready.thankyou-container {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape.webp), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape2.webp);
    background-color: #170036;
    background-position: -85px -158px, 627px 480px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh
}

.canceledAlready.thankyou-container .thankyou-block {
    text-align: center;
    line-height: 3px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center
}

.canceledAlready.thankyou-container .thankyou-block .content {
    color: #fff;
    text-align: center;
    line-height: 3px;
    max-width: 800px;
    padding: 0
}

.canceledAlready.thankyou-container .thankyou-block .content h1 {
    font-size: 36px;
    line-height: 36px
}

.canceledAlready.thankyou-container .thankyou-block .content p {
    font-size: 22px;
    color: #fff;
    margin: 10px 0
}

@media (max-width:701px) {
    .canceledAlready.thankyou-container .thankyou-block .content {
        max-width: 700px;
        line-height: 20px!important
    }

    .thankyou-container .thankyou-block .content h1 {
        font-size: 28px;
        line-height: 32px;
    }

    .darktheme.is_diabetesrexburg.thankyou .thankyou-container .thankyou-block .content .endText, .darktheme.is_diabetesrexburg.as_darkthemestyle .thankyou-container .thankyou-block .content p.endText{    font-size: 38px !important; line-height: 42px;}
}