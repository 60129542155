@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    box-sizing: border-box;
    --main-normal: 400;
    --main-medium: 500;
    --main-semibold: 600;
    --main-bold: 700;
    --main-exbold: 800;
    --main-black: 900;

}

.diabetes_rxbrg_main_wrpr h1, .diabetes_rxbrg_main_wrpr h2, .diabetes_rxbrg_main_wrpr h3, .diabetes_rxbrg_main_wrpr h4, .diabetes_rxbrg_main_wrpr h5, .diabetes_rxbrg_main_wrpr h6, .diabetes_rxbrg_main_wrpr p {
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.diabetes_rxbrg_main_wrpr .formCls .MuiFormControl-root .MuiInputLabel-root {
    position: absolute;
    height: inherit;
    width: inherit;
    background: inherit;
    text-align: inherit;
    color: inherit;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    padding-left: inherit;
    padding-right: inherit;
    font-size: inherit;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    transform: translate(14px, 16px) scale(1);
    text-transform: inherit;
}

.diabetes_rxbrg_main_wrpr .form_item.formfild {
    width: 100%;
    margin-bottom: 16px;
    position: relative;
}

.diabetes_rxbrg_main_wrpr .formCls .MuiFormControl-root {
    box-shadow: none;
    display: block;
}

.diabetes_rxbrg_main_wrpr .formCls {
    padding: 0;
    background: none;
    box-shadow: none !important;
    margin: 0;
}

.diabetes_rxbrg_main_wrpr .formCls .formInline {
    padding: 0;
}

.diabetes_rxbrg_main_wrpr .formCls .MuiFormControl-root .MuiOutlinedInput-root, .diabetes_rxbrg_main_wrpr .formCls .MuiFormControl-root textarea {
    background-color: #def3ff !important;
    border: 1px solid #e7dfdf;
    width: 100%;
    height: 52px !important;
}

.diabetes_rxbrg_main_wrpr .formCls .MuiFormHelperText-root.Mui-error {

    position: relative;
    background: none;
    right: 0;
    bottom: 0;
    padding: 0;
    border-radius: 0;
    font-weight: 500;

}









.common_width {
    width: 1734px;
    margin: 0 auto;
}

.diabetes_rxbrg_main_wrpr {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/daibetes_rxbrg_total_bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.landingpg_img img {
    display: block;
    width: 100%;
}

.diabetes_rxbrg_bnnr {
    background: none, url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/rxbrg_bnnr_couple_img_latest1.webp);
    background-repeat: no-repeat;
    background-position: top center, left 146px bottom;
    padding: 18px 0 18px;
}

.diabetes_rxbrg_logo {
    margin-bottom: 15px;
}

.diabetes_rxbrg_logo img {
    display: block;
    max-width: 100%;
}

.diabetes_rxbrg_txt h2 {
    font-size: 45px;
    line-height: 50px;
    font-weight: var(--main-bold);
    text-transform: uppercase;
}

.diabetes_rxbrg_txt h5 {
    font-size: 27px;
    line-height: 32px;
    color: #022952;
    text-transform: uppercase;
    font-weight: var(--main-bold);
}

.diabetes_rxbrg_txt {
    margin-bottom: 15px;
}

.diabetes_rxbrg_txt h2 span {
    background: linear-gradient(180deg, rgba(5, 91, 177, 1) 11%, rgba(6, 62, 143, 1) 55%, rgba(7, 34, 112, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
    display: inline;

}

.diabetes_rxbrg_txt h3 {
    font-size: 30px;
    line-height: 35px;
    font-weight: var(--main-semibold);
    color: #022952;
    margin-top: 10px;

}

.diabetes_rxbrg_friday h1 {
    font-size: 74px;
    line-height: 74px;
    font-weight: var(--main-bold);
    font-family: 'Poppins', sans-serif;

}

.sky_grd {
    background: linear-gradient(180deg, rgba(3, 225, 250, 1) 0%, rgba(1, 139, 223, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    display: inline;
    filter: drop-shadow(0px 0px 6px #000000de);
}

.diabetes_rxbrg_friday h2 {
    font-size: 60px;
    line-height: 63px;
    font-weight: var(--main-exbold);
    font-family: 'Poppins', sans-serif;

}

.rx_h3 {
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    font-weight: var(--main-bold);
    text-transform: uppercase;
    padding-left: 24px !important;
}

.diabetes_rxbrg_bg {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-diabetes-rexburg/black_friday_bg_green_blu.webp);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left top;
    width: fit-content;
    padding-right: 65px;
    padding-bottom: 20px;
    margin-bottom: 35px;
}

.diabetes_rxbrg_friday {
    padding: 20px;
    padding-bottom: 8px;
}

.diabetes_rxbrg_friday p {
    font-size: 27px;
    line-height: 32px;
    color: #fff;
    text-transform: uppercase;
    font-weight: var(--main-semibold);
}

.diabetes_rxbrg_price {
    background: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-diabetes-rexburg/rxbrg_bg_green.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: fit-content;
    padding: 28px 85px 15px 55px;
    text-align: center;
    margin-bottom: 12px;
}

.diabetes_rxbrg_price h5 {
    font-size: 35px;
    line-height: 41px;
    color: #000;
    text-transform: uppercase;
    font-weight: var(--main-bold);
}

.diabetes_rxbrg_price h5 span {
    text-decoration: line-through;
    text-decoration-color: red;
}

.diabetes_rxbrg_price h6 {
    font-size: 43px;
    line-height: 49px;
    text-transform: uppercase;
    font-weight: var(--main-bold);
}

.diabetes_rxbrg_price h6 span {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 46%, rgba(197, 194, 194, 1) 52%, rgba(228, 228, 228, 1) 61%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    display: inline;
    filter: drop-shadow(0px 0px 3px #000000de);
}


.hurry_block {
    position: relative;
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/huury_block_wht_bg.webp);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 10px;
    padding-left: 105px;
    width: 823px;
    margin-bottom: 15px;
    padding-right: 160px;
}

.hurry_block p {
    font-size: 22px;
    line-height: 32px;
    color: #000;
    font-weight: 600;
}

.hurry_block p span {
    color: #1697e2;
}

.hurry_block h1 {
    writing-mode: vertical-rl;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    z-index: 9999999999999;
    top: -13px;
    left: 8px;
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/bnnr_orng_hurry_bg.webp);
    background-size: 100% 100%;
    width: fit-content;
    padding: 15px 28px 18px;
}

.hurry_block h1 span {
    transform: rotate(180deg);
    display: inline-block;
}

.rxbrg_bnnr_arrow {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/dia_rxbrg_arrow_bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: left top;
    padding: 30px 188px 30px 29px;
    position: relative;
}

.rxbrg_bnnr_arrow::before {
    position: absolute;
    content: "";
    background: #0064ff;
    width: 17px;
    top: 50%;
    transform: translateY(-50%);
    height: 57%;
    left: 2px;
}

.rxbrg_bnnr_arrow::after {
    position: absolute;
    content: "";
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/arrow_shadow_img.webp);
    background-position: center;
    background-size: 100%;
    height: 40px;
    width: 120px;
    top: -17px;
    left: 1px;
}

.rxbrg_bnnr_arrow h1 {
    font-size: 38px;
    line-height: 38px;
    text-transform: uppercase;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 47%, rgb(195 195 195) 56%, rgba(228, 228, 228, 1) 60%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    display: inline;
    filter: drop-shadow(0px 0px 3px #000000de);
    font-weight: 700;
}

.rxbrg_bnnr_arrow h3 {
    font-size: 29px;
    line-height: 35px;
    color: #83fe00;
    text-transform: uppercase;
    font-weight: 700;
}

.diabetes_rxbrg_bnnr_sub {
    display: flex;
    justify-content: space-between;
}

.rxbrg_bnnr_left {
    width: 68%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.rxbrg_bnnr_right {
    width: 26%;
}

/* .rxbrg_bnnr_form_hdr {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/dia_rxbrg_form_hdr_bg.png);
    padding: 12px;
    background-size: 100% 100%;
    margin-bottom:15px;
    text-align: center;
} */

/* .rxbrg_bnnr_form_hdr h2 {
    font-size: 37px;
    line-height: 39px;
    font-weight: 600;
    text-transform: uppercase;
    background: linear-gradient(90deg, rgba(234, 234, 234, 1) 0%, rgba(254, 254, 254, 1) 100%);
    -webkit-background-clip: text;
    display: inline;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    display: inline;
    filter: drop-shadow(0px 0px 3px #000000de);
} */

.diabetes_rxbrg_bnnr_sub {
    display: flex;
    justify-content: space-between;
}

.rxbrg_bnnr_left {
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.rxbrg_bnnr_right {
    width: 26%;
}

.rxbrg_bnnr_form_hdr {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/dia_rxbrg_form_hdr_bg.png);
    padding: 12px;
    background-size: 100% 100%;
    margin-bottom: 28px;
    text-align: center;
}

.rxbrg_bnnr_form_hdr h2 {
    font-size: 37px;
    line-height: 39px;
    font-weight: 600;
    text-transform: uppercase;
    background: linear-gradient(90deg, rgba(234, 234, 234, 1) 0%, rgba(254, 254, 254, 1) 100%);
    -webkit-background-clip: text;
    display: inline;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    display: inline;
    filter: drop-shadow(0px 0px 3px #000000de);
}

.rxbrg_bnnr_form_hdr p {
    font-size: 25px;
    font-weight: 700;
    line-height: 31px;
    color: #000;
    text-transform: uppercase;
    text-shadow: 0 2px 1px rgb(255 255 255);
}

.rxbrg_bnnr_right_inn {
    background-color: #092155;
    padding: 17px;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-top: 40px;
}

.from_input {
    width: 100%;
    margin-bottom: 14px;
}

.from_input .MuiInputBase-root {
    background-color: #def3ff;
}

.input_wrapper {
    position: relative;
}

.toggle_btn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.rxbrg_bnnr_right_inn .submitbtnsection {
    padding-top: 0;
    margin-top: 25px;
}

.rxbrg_bnnr_right_inn button {
    display: block !important;
    width: 100% !important;
    font-size: 50px !important;
    line-height: 50px !important;
    background: linear-gradient(180deg, rgba(12, 12, 12, 1) 0%, rgba(73, 73, 73, 1) 76%) !important;
    padding: 12px !important;
    color: #fff !important;
    font-weight: 700 !important;

    border: 1px solid #fff !important;
    box-shadow: none !important;
    border-radius: 5px !important;
    margin: 0 !important;
}

.diabetes_rxbrg_main_wrpr .formCls fieldset,
.diabetes_rxbrg_main_wrpr .formCls .MuiInputLabel-shrink {
    display: none !important;
}

.diabetes_rxbrg_main_wrpr .formfild .MuiInputBase-root {
    background-color: #def3ff;
    border: 1px solid #e7dfdf;
    border-radius: 1px;
}

.diabetes_rxbrg_main_wrpr .MuiInputBase-input {
    height: 15px !important;
    min-height: auto !important;
    padding: 12.5px 14px;
}

.diabetes_rxbrg_main_wrpr .formfild .MuiFormLabel-root {
    top: 0;
    color: #666565;
    color: #666565 !important;
    font-size: 16px !important;
    font-weight: 500;
}

/* .diabetes_rxbrg_main_wrpr .MuiFormHelperText-contained {
    position: absolute !important;
    bottom: -28px !important;
    color: #fff !important;
    margin: 0;
    padding: 3px !important;
    background: red !important;
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 13px;
    line-height: 18px;
    left: 0;
    width: fit-content;
    clip-path: polygon(11% 1%, 15% 24%, 100% 25%, 100% 100%, 0 100%, 0 24%, 6% 24%);

} */

.diabetes_rxbrg_main_wrpr .MuiFormHelperText-contained {
    right: unset !important;

    position: absolute !important;
    left: 50% !important;
    bottom: -12px !important;
    transform: translatex(-50%) !important;
    background: linear-gradient(180deg, rgba(255, 0, 0, 1) 0%, rgba(209, 22, 22, 1) 100%) !important;
    padding: 0px 8px 4px !important;
    font-size: 13px !important;
    border-radius: 4px !important;
    color: #fff !important;
    border: 1px solid #eb0404 !important;
    height: 23px !important;
    z-index: 1;
    width: 97%;
    font-weight: 500;

}

/* .diabetes_rxbrg_main_wrpr .MuiFormHelperText-contained::after {
    content: "";
    position: absolute;
    right: -17px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 36px;
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 13px solid white;
} */
.diabetes_rxbrg_main_wrpr .MuiFormHelperText-contained::before {
    content: "";
    position: absolute;
    left: 15px;
    top: -12px;
    transform: rotate(279deg);
    border-width: 36px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 15px solid red;
}

.diabetes_rxbrg_block1_video_main {
    width: 49%;

}

.diabetes_rxbrg_block1_video_main_blue_bg {
    background-color: #018fdf;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
}

.diabetes_rxbrg_block1_video_main h1 {
    font-size: 34px;
    line-height: 40px;
    font-weight: 800;
    text-transform: uppercase;
    background: linear-gradient(180deg, rgba(2, 122, 226, 1) 0%, rgba(7, 57, 155, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    margin-bottom: 20px;
}

.rxbrg_1txt {
    width: 50%;
}

.video_sec {
    height: 100% !important;
    width: 100% !important;

}


.diabetes_rxbrg_block1 .react-player__preview {
    background-size: 100% 100% !important;
    padding-top: 50.25%;

    background-image: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/rxbrg_vdo_image_new.webp) !important;
    background-repeat: no-repeat;
}

.diabetes_rxbrg_block1 .react-player__preview .react-player__shadow {
    background: #adadad !important;
    position: absolute;
    top: 50%;
    /* -webkit-transform: translateY(-50%); */
    transform: translateY(-50%);
}


.video_sec video {
    display: block;
}

/* --------------------block1===================================== */
.rxbrg_padd {
    padding: 45px 0;
}

.diabetes_rxbrg_block1 {
    background: none, url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/rxburg_block3_bg.webp);
    background-repeat: no-repeat;
    background-position: right -30px top 0px, right bottom -110px;
}

.rxbrg_txt h1 {
    font-size: 45px;
    line-height: 51px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 25px;
    color: #022952;
}

.diabetes_rxbrg_block1_sub_inn h1 {
    color: #022952;
    margin-bottom: 25px;
}

.diabetes_rxbrg_block1_para p {
    font-size: 24px;
    line-height: 29px;
    color: #000;
    margin-bottom: 10px;
    font-weight: 500;
}

.diabetes_rxbrg_block1_para p span {
    color: #00a2ff;
}

.diabetes_rxbrg_block1_para p:last-child {
    margin-bottom: 0;
}

.diabetes_rxbrg_block1_para p:nth-last-of-type(1) {
    margin-bottom: 0;
}

.diabetes_rxbrg_block1_sub_inn h2 {
    font-size: 33px;
    line-height: 39px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px;
    color: #022952;
}

.rxbrg_padd_rght {
    padding-right: 708px;
}

.diabetes_rxbrg_block1_sub {
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

/* --------------------block2===================================== */
.rxgbrg_blu_left {

    width: 35%;

}

.rxgbrg_blu_left_inn {
    background: #086ba1;
    padding: 16px;
    border-radius: 7px;
    margin-bottom: 20px;
}

.rxgbrg_blu_rght {
    width: 62%;
}

.rxgbrg_blu_left_img {
    overflow: hidden;
    border: 1px solid #fff;
    height: 324px;
    border-radius: 5px;
}

.rxgbrg_blu_left_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.diabetes_rxbrg_block1_sub1_flx h1 {
    color: #fff;
    margin-bottom: 25px;
}

.rxbrg_bullet p {
    position: relative;
    padding-left: 35px;
}

.rxbrg_bullet p::before {
    content: "";
    position: absolute;
    background: #fff;
    border: 6px solid #009fe8;
    width: 8px;
    height: 8px;
    left: 0;
    top: 4px;
}

.rxbrg_blu_sec p {
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 10px;
}

.rxbrg_blu_sec p span {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;

}

.rxbrg_blu_sec {
    column-count: 2;
}

.diabetes_rxbrg_block1_sub1_flx {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
}

.diabetes_rxbrg_block1_sub1 {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/rxbrg_block2_bg_blu.webp);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 25px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.diabetes_rxbrg_green {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/green_bg_blu_sec.webp), #05b822;
    background-repeat: no-repeat;
    background-position: right bottom, center;
    background-size: auto 100%, cover;
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #8dd23f;
    text-shadow: 0 3px 12px rgba(0, 0, 0, .4);
}

.diabetes_rxbrg_green h1 {
    font-size: 25px;
    line-height: 31px;
    color: #fff;
    font-weight: 400;
}

.diabetes_rxbrg_block1_sub1 .diabetes_rxbrg_green h1 {
    margin-bottom: 0;
}

.dia_new_sec1_main_wrapper {
    background-color: #bbd0d9;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.dia_new_sec1_SUB_wrapper {
    background: linear-gradient(131deg, rgba(243, 246, 248, 1) 0%, rgba(234, 244, 248, 1) 43%, rgba(193, 232, 250, 1) 100%);
    padding: 20px;
    border-radius: 10px;
}

.dia_new_sec1_SUB_sec1_wrapper {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-diabetes-rexburg/New_Project_green.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 20px 40px;
    border-radius: 6px;
}

.dia_new_sec1_SUB_sec1_wrapper h1 {
    font-size: 45px;
    line-height: 45px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 3px 6px rgb(0 0 0 / 58%);
}

.ul_column_wrpr {
    width: 24%;
}

.ul_column_wrpr li {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
    color: #000;
    list-style: none;
    padding-left: 34px;
}

.flx_wrp_ul {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.ul_column_wrpr li span {
    font-size: 20px;
    line-height: 25px;
}

.ul_column_wrpr li::before {
    content: "";
    position: absolute;
    background: #fff;
    border: 6px solid #009fe8;
    width: 8px;
    height: 8px;
    left: 0;
    top: 4px;
}

.column_abs_img {
    position: absolute;
    bottom: 20px;
    right: 80px;
    box-shadow: 0 0 50px 35px #fff;
    border-radius: 100%;
}

/* --------------------block3===================================== */
.diabetes_rxbrg_block1_sub2_inn h2 {
    font-size: 30px;
    line-height: 36px;
    color: #27549a;
    text-transform: uppercase;
    font-weight: 700;
}

.rxbrg_bull_sec {
    column-count: 2;
    margin-bottom: 18px;
}

.rxbrg_bull_sec p {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
}

.diabetes_rxbrg_block1_sub2 {
    padding-right: 674px !important;
}


/* ===========================block-common-======================= */
.diabetes_rxbrg_block2_sub:nth-of-type(1) {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/auto_nerv_bg_doctr.webp);
    background-repeat: no-repeat;
    background-position: left bottom -90px;
    padding: 21px 0 56px;
    margin-top: -21px;
}

.diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn {
    padding: 25px 25px 30px 0;
    border: 6px solid #fff;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.diabetes_rxbrg_block2_sub:nth-of-type(odd) .diabetes_rxbrg_block2_sub_inn {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/auto_nerv_blu_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: relative;
    z-index: 3;
}

.diabetes_rxbrg_block2_sub:nth-of-type(odd) .diabetes_rxbrg_block2_sub_inn h2 {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/auto_nerv_heading_new_bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 {
    width: fit-content;
    padding: 13px;
    padding-right: 60px;
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 18px;
    display: flex;
    align-items: flex-start;
    align-items: center;
    line-height: 36px;
    position: relative;
    padding-left: 58px;
    text-shadow: 0 3px 12px rgba(0, 0, 0, .4);
}

.diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 .no {
    display: inline-block;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    background-color: #ffe300;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 24px;
    line-height: 30px;
    color: #000;
    left: 14px;
    top: 14px;
}

.diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn p {
    font-size: 22px;
    color: #fff;
    line-height: 27px;
    margin-bottom: 30px;
    padding: 0 0 0 25px;
    text-shadow: 0 1px 9px rgb(0 0 0 / 60%);
}

.diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn p span {
    color: #ffe300;
}

.diabetes_rxbrg_block2_sub:nth-of-type(odd) .diabetes_rxbrg_block2_sub_pd {
    padding-left: 500px;
}

.green_btn button {
    width: fit-content;
    font-size: 22px;
    line-height: 27px;
    color: #fff;
    border: 4px solid #495a63;
    background: linear-gradient(180deg, rgba(106, 228, 4, 1) 0%, rgba(44, 162, 1, 1) 100%);
    padding: 15px 20px;
    margin: 0 0 0 auto;
    font-family: 'Poppins', sans-serif;
    border-radius: 12px;
    display: block;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-shadow: 0 3px 12px rgba(0, 0, 0, .4);
    cursor: pointer;
}

.diabetes_rxbrg_block2_sub:nth-of-type(2) {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/endothelial_block_bg.webp);
    background-repeat: no-repeat;
    background-position: right bottom 15px;
    padding: 90px 0 58px;
    margin-top: -105px;
    position: relative;
    z-index: 1;
}

.diabetes_rxbrg_block2_sub:nth-of-type(even) .diabetes_rxbrg_block2_sub_pd {
    padding-right: 500px;
}

.diabetes_rxbrg_block2_sub:nth-of-type(even) .diabetes_rxbrg_block2_sub_inn {
    background: #006dc8;
}

.diabetes_rxbrg_block2_sub:nth-of-type(even) .diabetes_rxbrg_block2_sub_inn h2 {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/endothenial_block_heding_bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.diabetes_rxbrg_block2_sub:nth-of-type(3) {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/cardiometa_block_bg_new.webp);
    background-repeat: no-repeat;
    background-position: left bottom 27px;
    padding: 106px 0 58px;
    margin-top: -125px;
    position: relative;
}

.diabetes_rxbrg_block2_sub:nth-of-type(4) {
    background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/plethismography_block_bg.webp);
    background-repeat: no-repeat;
    background-position: right bottom -27px;
    padding: 106px 0 50px;
    margin-top: -125px;
}

.diabetes_rxbrg_block2_sub .blu_sec1 h2:nth-of-type(1) {
    width: 98%;
}

.diabetes_rxbrg_block2_sub .blu_sec1 h2:nth-of-type(2) {
    width: 85%;
}

.diabetes_rxbrg_block2_sub .blu_sec2 h2:nth-of-type(1) {
    width: 95%;
}

.diabetes_rxbrg_block2_sub .blu_sec2 h2:nth-of-type(2) {
    width: 70%;
}

.diabetes_rxbrg_block2_sub .blu_sec3 h2:nth-of-type(1) {
    width: 95%;
}

.diabetes_rxbrg_block2_sub .blu_sec3 h2:nth-of-type(2) {
    width: 77%;
}

.diabetes_rxbrg_block2_sub .blu_sec4 h2:nth-of-type(1) {
    width: 95%;
}

.diabetes_rxbrg_block2_sub .blu_sec4 h2:nth-of-type(2) {
    width: 98%;
}

/* ==========================footer===================== */
.footer_rxbrg {
    background: #5b6669;
    padding: 25px 0;
}

.foooter_rxbrg_bdy p {
    color: #aaaaa9;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
}

.foooter_rxbrg_bdy p a {
    cursor: pointer;
}

/* ----------------------------bnnr--responsive=================== */

@media (max-width:1790px) {
    .diabetes_rxbrg_block1 {
        background-position: right -84px top 0px, right -118px bottom -110px;
    }
}

@media (max-width:1760px) {
    .common_width {
        width: 96%;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(4) {
        background-position: right -100px bottom -27px;
    }

    .diabetes_rxbrg_block1 {

        background-position: right -84px top 0px, right -118px bottom -110px;
    }

    .rxbrg_bnnr_arrow h1 {
        font-size: 30px;
        line-height: 35px;
    }

    .rxbrg_bnnr_arrow h3 {
        font-size: 24px;
        line-height: 30px;
    }
}

@media (max-width:1720px) {
    .rxbrg_bnnr_form_hdr {
        padding: 18px;
    }

    /* .rxbrg_bnnr_form_hdr h2 br{display: none;} */
    .rxbrg_bnnr_form_hdr h2 {
        font-size: 28px;
        line-height: 34px;
    }

    /* .rxbrg_bnnr_arrow h1 {
        font-size: 40px;
        line-height: 46px;
    } */

    .diabetes_rxbrg_block2_sub:nth-of-type(2) {
        background-position: right -82px bottom 19px;
    }

}

@media (max-width: 1680px) {
    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 {
        width: 98% !important;
        font-size: 26px;
        line-height: 32px;
    }

    .column_abs_img {
        bottom: 9px;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 .no {
        height: 28px;
        width: 28px;
        font-size: 21px;
        line-height: 27px;
    }
}


@media (max-width: 1640px) {
    .diabetes_rxbrg_bnnr {

        background-position: top center, left 47px bottom;

    }
}

@media (max-width:1620px) {
    .diabetes_rxbrg_block1_para p {
        font-size: 22px;
        ;
        line-height: 28px;
    }

    .diabetes_rxbrg_block1_sub_inn h2 {
        font-size: 30px;
        line-height: 33px;
    }
}

@media (max-width: 1599px) {
    .diabetes_rxbrg_block2_sub:nth-of-type(4) {
        background-position: right -153px bottom -27px;
        background-size: 843px;
    }
}



@media (max-width:1560px) {

    .diabetes_rxbrg_block1_sub_inn h1 {

        margin-bottom: 15px;
    }

    .diabetes_rxbrg_block1_sub_inn h2 {
        font-size: 25px;
        line-height: 31px;
    }

    .diabetes_rxbrg_block1_para p {
        font-size: 22px;
        line-height: 28px;

        margin-bottom: 15px;
    }


    .rxbrg_txt h1 {
        font-size: 35px;
        line-height: 41px;
    }


    .rxbrg_bull_sec p {
        font-size: 22px;
        line-height: 28px;
    }

    .diabetes_rxbrg_block1_sub2_inn h2 {
        font-size: 25px;
        line-height: 31px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(1) {
        background-position: left -77px bottom -40px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(3) {
        background-position: left -135px bottom 27px;
        padding: 106px 0 58px;
        background-size: 789px;
    }

    .rxbrg_bnnr_arrow {
        padding: 30px 149px 30px 29px;
    }
}

@media (max-width:1440px) {
    .rxbrg_bnnr_arrow h3 {
        font-size: 21px;
        line-height: 30px;
    }

    .rxbrg_bnnr_arrow h1 {
        font-size: 26px;
        line-height: 32px;
    }
}

@media (max-width:1420px) {
    .diabetes_rxbrg_bnnr {
        background-position: top center, left 73px bottom;
        background-size: auto, 1416px;
    }

    /* .rxbrg_bnnr_arrow h1 {
        font-size: 32px;
        line-height: 37px;
    } */

    .rxbrg_bnnr_right {
        width: 28%;
    }

    .diabetes_rxbrg_friday h2 {
        font-size: 51px;
        line-height: 58px;
    }

    .diabetes_rxbrg_friday h1 {
        font-size: 62px;
        line-height: 62px;
    }

    .diabetes_rxbrg_price h6 {
        font-size: 35px;
        line-height: 41px;
    }

    .diabetes_rxbrg_price h5 {
        font-size: 31px;
        line-height: 37px;
    }

    .diabetes_rxbrg_txt h3 {
        font-size: 27px;
        line-height: 32px;
    }

    .diabetes_rxbrg_block1 {
        background-position: right -240px top 0px, right -118px bottom -110px;
    }

    /* .diabetes_rxbrg_block1_sub {
        padding-right: 548px !important;
    } */

    .rxbrg_blu_sec p {
        display: inline-block;
        font-size: 21px;
        line-height: 27px;
        width: 97%;
    }


    /* ===============common-blu---blokc-------========================= */

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 {
        font-size: 23px;
        line-height: 29px;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn p {
        font-size: 20px;

        line-height: 26px;
        margin-bottom: 20px;

    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 .no {
        height: 28px;
        width: 28px;
        font-size: 21px;
        line-height: 26px;
    }

    .green_btn button {

        font-size: 18px;
        line-height: 24px;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn {
        padding: 25px 25px 25px 0;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(4) {
        background-position: right -153px bottom -57px;

    }


}

@media (max-width:1440px) {
    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 {

        font-size: 22px;
        line-height: 28px;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn p {
        font-size: 20px;
        line-height: 26px;
    }
}

@media (max-width:1366px) {
    .diabetes_rxbrg_block2_sub:nth-of-type(odd) .diabetes_rxbrg_block2_sub_pd {
        padding-left: 420px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(even) .diabetes_rxbrg_block2_sub_pd {
        padding-right: 420px;
    }
}

@media (max-width:1340px) {
    .diabetes_rxbrg_block1 {
        background-position: right -240px top 0px, right -84px bottom -70px;
        background-size: auto, 800px;
    }

    .diabetes_rxbrg_block1_sub2 {
        padding-right: 574px !important;
    }

    .rxbrg_bull_sec p {
        font-size: 20px;
        line-height: 26px;
    }

    .rxbrg_bnnr_arrow h1 {
        font-size: 22px;
        line-height: 28px;
    }

    .rxbrg_bnnr_arrow h3 {
        font-size: 18px;
        line-height: 30px;
    }

    .flx_wrp_ul {
        flex-wrap: wrap;
    }

    .ul_column_wrpr {
        width: 33%;
    }

    .ul_column_wrpr:last-of-type {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 70%;
    }

    .ul_column_wrpr:last-of-type li {
        width: 48%;
    }

}

@media (max-width:1310px) {
    .rxbrg_bnnr_form_hdr h2 {
        font-size: 28px;
        line-height: 34px;
    }

    .rxbrg_bnnr_left {
        width: 66%;
    }

    .rxbrg_bnnr_right {
        width: 30%;
    }

    .rxbrg_bnnr_arrow {
        padding: 30px 129px 30px 29px;
    }

    /* .rxbrg_bnnr_arrow h1 {
        font-size: 28px;
        line-height: 32px;
    } */

    .diabetes_rxbrg_bnnr {
        background-position: top center, left 33px bottom;
        background-size: auto, 1340px;
    }

    .rxbrg_bnnr_form_hdr {
        padding: 10px;
    }



    /* ========common--block----------------------=============== */

    .diabetes_rxbrg_block2_sub:nth-of-type(1) {
        background-position: left -95px bottom -10px;
        background-size: 1069px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(3) {
        /* background-position: left -99px bottom 27px; */
        background-size: 840px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(odd) .diabetes_rxbrg_block2_sub_pd {
        padding-left: 480px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(even) .diabetes_rxbrg_block2_sub_pd {
        padding-right: 480px;
    }



}

@media (max-width:1280px) {
    .diabetes_rxbrg_block1_para p {
        font-size: 20px;
        line-height: 26px;
    }

    .diabetes_rxbrg_block1_sub_inn h2 {
        font-size: 22px;
        line-height: 28px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(2) {

        background-size: 1155px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(4) {
        background-size: 850px;
        background-position: right -153px bottom -20px;

    }
}

@media (max-width:1199px) {


    .flx_wrp_ul {
        display: block;
    }

    .ul_column_wrpr {
        width: auto;
        margin: 20px 0 0 0;
        padding: 0;
    }

    .ul_column_wrpr:last-of-type {
        display: block;
        width: auto;
    }

    .ul_column_wrpr:last-of-type li {
        width: auto;
    }

    .diabetes_rxbrg_friday h2 {
        font-size: 62px;
        line-height: 62px;
    }

    .common_width {
        width: 92%;
    }

    .diabetes_rxbrg_logo img {
        margin-left: auto;
        margin-right: auto;
    }

    .diabetes_rxbrg_txt {
        text-align: center;
    }

    .diabetes_rxbrg_friday {
        text-align: center;
    }

    .rxbrg_padd {
        padding: 0 0 25px 0;
    }

    .diabetes_rxbrg_block1_sub {
        flex-direction: column-reverse;
    }

    .diabetes_rxbrg_block1_video_main {
        width: 100%;
        margin-bottom: 20px;
    }

    .rxbrg_1txt {
        width: 100%;
    }

    .diabetes_rxbrg_bg {
        /* background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/black_friday_bg_green_blu.webp); */

        width: auto;
        padding-right: 0;
        padding-bottom: 15px;
        margin-bottom: 35px;
        /* background: #1b2041; */
        border-radius: 5px;
        background: radial-gradient(#273064 15%, transparent 16%), repeating-linear-gradient(0deg, transparent 6.5%, #1b2041 7%, #1b2041 9%, transparent 10%), linear-gradient(45deg, transparent 49%, #7285ff 49%, #7285ff 51%, transparent 51%), linear-gradient(-45deg, transparent 49%, #7285ff 49%, #7285ff 51%, transparent 51%);
        background-size: 3em 3em;
        background-color: #1b2041;
        /* opacity: 1; */

        box-shadow: 0 -40px 100px #98ffe2cf;
    }

    .diabetes_rxbrg_price {
        width: auto;
        background: linear-gradient(180deg, rgba(72, 176, 0, 1) 0%, rgba(117, 235, 0, 1) 100%);
        padding: 15px 55px 15px 28px;
        margin: 0 15px;
        border-radius: 5px;
    }

    .diabetes_rxbrg_price h6 {
        font-size: 41px;
        line-height: 44px;
    }

    .diabetes_rxbrg_price h5 {

        display: inline-block;
        margin-right: 20px;
    }

    .diabetes_rxbrg_price h6 {
        display: inline-block;
    }

    .diabetes_rxbrg_friday h1 {
        display: inline-block;
        margin-right: 10px;
    }

    .diabetes_rxbrg_friday h2 {
        display: inline-block;
    }

    .hurry_block {
        width: auto;
        padding: 24px;
        margin-bottom: 20px;
        padding-right: 15px;
        padding-left: 90px;
        box-shadow: 0 0 25px #cbc8c8;
        border-radius: 5px;
    }

    .hurry_block h1 {
        font-size: 15px;
        width: fit-content;
        padding: 15px 25px 18px;
    }

    .diabetes_rxbrg_bnnr_sub {
        flex-wrap: wrap;
    }

    .rxbrg_bnnr_left {
        width: 100%;
        margin-bottom: 20px;
    }

    .rxbrg_bnnr_right {
        width: 100%;
    }

    .rxbrg_bnnr_arrow {
        background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/arrow_reponsv_bg.webp);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: left top;
        padding: 18px;
        text-align: center;
        border-radius: 5px;
    }

    .rx_h3 br {
        display: none;
    }

    .rx_h3 {
        margin-top: 15px !important;
        padding: 0 15px !important;
    }

    .rxbrg_bnnr_right_inn {
        border-radius: 5px;
        margin-top: 0;
    }

    .rxbrg_bnnr_form_hdr {
        border-radius: 5px;
    }

    .rxbrg_bnnr_arrow::after {
        display: none;
    }


    .rxbrg_bnnr_right_inn button {
        font-size: 38px !important;
        line-height: 44px !important;
        width: 300px !important;
        margin: 10px auto !important;
        padding: 15px 15px !important;
        max-width: 90%;
    }





    .rxbrg_bnnr_arrow::before {
        display: none;
    }

    .rxbrg_bnnr_arrow br {
        display: none;
    }

    .rxbrg_bnnr_form_hdr h2 br {
        display: none;
    }



    .diabetes_rxbrg_bnnr {
        background: none, none;

        background-repeat: no-repeat;
        background-position: top center;
        padding: 25px 0;

        /* background-color: #e5e5f7;
opacity: 0.8;
background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #eaf7f7 10px ), repeating-linear-gradient( #eaf7f755, #eaf7f7 ); */

    }

    .diabetes_rxbrg_block1 {
        background: none, none;
        background-repeat: no-repeat;
        background-position: center top;
    }

    /* .diabetes_rxbrg_block1_sub {
        padding-right: 0 !important;
        padding-top: 532px;
    } */

    .diabetes_rxbrg_block1_sub2 {
        padding-right: 0 !important;

    }

    .diabetes_rxbrg_block1_sub1_flx {
        flex-direction: column;
        align-items: center;
    }

    .rxgbrg_blu_left {
        width: auto;
        margin-bottom: 20px;
    }

    .rxgbrg_blu_rght {
        width: 100%;
    }

    .diabetes_rxbrg_green h1 {
        font-size: 24px;
        line-height: 30px;
    }

    .rxbrg_blu_sec p {
        display: inherit;
    }

    .rxbrg_txt h1 br {
        display: none;
    }

    .rxgbrg_blu_left_img {
        height: auto;
    }

    /* =============================common_blcok======================================= */
    .diabetes_rxbrg_block2_sub:nth-of-type(1) {
        background-position: center top;
        background-size: 81%;
        padding: 21px 0 0px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(1) .diabetes_rxbrg_block2_sub_pd {
        padding-left: 0;
        padding-top: 64%;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(2) {

        background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/endothelial_block_bg_neww.webp);
        background-repeat: no-repeat;
        background-position: center top 0;
        background-size: 100%;
        padding: 0 0 0;
        margin-top: -2%;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(2) .diabetes_rxbrg_block2_sub_pd {
        padding-right: 0;
        padding-top: 64%;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(3) {
        background: url(https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/cardiometa_block_bg_new_responsv.webp);
        background-position: center top 0;
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 0px 0 0px;
        margin-top: -2%;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(3) .diabetes_rxbrg_block2_sub_pd {
        padding-left: 0;
        padding-top: 64%;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(4) {
        background-position: center top 0px;
        background-size: 107%;
        margin-top: -7%;
        padding: 0px 0 25px;
    }

    .diabetes_rxbrg_block2_sub:nth-of-type(4) .diabetes_rxbrg_block2_sub_pd {
        padding-right: 0;
        padding-top: 70%;
    }
}

@media (max-width:820px) {
    .rxbrg_blu_sec p {
        display: inline-block;
        font-size: 19px;
        line-height: 24px;
    }

    /* .diabetes_rxbrg_block1_sub {
        padding-right: 0 !important;
        padding-top: 63%;
    } */

    .diabetes_rxbrg_block1 {
        background-size: 97%;
    }
}

@media (max-width:768px) {


    .ul_column_wrpr li {
        font-size: 19px;
        line-height: 25px;
    }

    .column_abs_img {
        display: none;
    }

    .dia_new_sec1_SUB_sec1_wrapper h1 {
        font-size: 32px;
        line-height: 38px;
    }

    .diabetes_rxbrg_friday {
        text-align: center;
        padding: 12px;
        padding-bottom: 10px;
    }

    .diabetes_rxbrg_friday h2 {
        font-size: 45px;
        line-height: 52px;
    }

    .diabetes_rxbrg_friday h1 {
        font-size: 45px;
        line-height: 52px;
    }

    .diabetes_rxbrg_price {
        padding: 12px;
    }

    .diabetes_rxbrg_txt h2 {
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 8px;
    }

    .diabetes_rxbrg_price h6 {
        font-size: 32px;
        line-height: 38px;
    }

    .diabetes_rxbrg_price h5 {
        font-size: 24px;
        line-height: 30px;
    }

    .diabetes_rxbrg_logo img {
        margin: 0 auto;
    }

    .green_btn button {
        margin: 0 auto;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn {
        padding: 25px 0px 25px 0;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn p {
        padding: 0 18px;
    }

    .green_btn {
        padding: 0 12px;
    }

    .rxbrg_txt h1 {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 15px;

    }

    .diabetes_rxbrg_block1_sub1_flx {
        margin-bottom: 0px;
    }

    .diabetes_rxbrg_block1_sub1 {
        margin-bottom: 20px;
    }

    .rxbrg_padd {
        padding: 0 0 15px 0;
    }

    /* .diabetes_rxbrg_block1_sub {

        padding-top: 67%;
    } */

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 {
        padding-right: 30px;
        padding-left: 42px;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 .no {
        left: 8px;
        top: 14px;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 {
        width: 96% !important;
    }

}

@media (max-width: 620px) {
    .rxbrg_blu_sec p {
        display: block;
        font-size: 19px;
        line-height: 24px;
    }

    .diabetes_rxbrg_block1_para p {
        font-size: 19px;
        line-height: 24px;
    }

    .diabetes_rxbrg_block1_video_main h1 {
        font-size: 26px;
        line-height: 32px;
    }

    .rxbrg_blu_sec {
        column-count: 1;
    }

    .rxbrg_bull_sec {
        column-count: 1;
        margin-bottom: 10px;
    }

    .foooter_rxbrg_bdy p {
        color: #aaaaa9;
        font-size: 16px;
    }

    .footer_rxbrg {
        padding: 18px 0;
    }
}

@media (max-width:499px) {

    .dia_new_sec1_SUB_sec1_wrapper h1 {
        font-size: 25px;
        line-height: 31px;
    }

    .rxbrg_bnnr_right_inn {
        padding: 15px;
    }

    .diabetes_rxbrg_block1_video_main_blue_bg {
        padding: 10px;
    }

    .rxbrg_bnnr_form_hdr {
        margin-bottom: 15px;
    }

    .hurry_block h1 {
        padding: 15px 15px 18px;
    }

    .hurry_block {
        padding-left: 70px;
    }

    .diabetes_rxbrg_friday p {
        font-size: 24px;
        line-height: 30px;
    }

    .diabetes_rxbrg_friday h1 {
        font-size: 40px;
        line-height: 46px;
    }

    .diabetes_rxbrg_friday h2 {
        font-size: 40px;
        line-height: 46px;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn {
        padding: 15px 0px 15px 0;
    }

    .diabetes_rxbrg_block1_sub2_inn h2 {
        font-size: 22px;
        line-height: 28px;
    }

    .diabetes_rxbrg_block1_sub1 {
        padding: 15px;
    }

    .rxgbrg_blu_left {
        padding: 10px;
        margin-bottom: 15px;
    }

    .diabetes_rxbrg_block1_sub {
        padding-bottom: 15px;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn h2 {
        font-size: 20px;
        line-height: 26px;
    }

    .diabetes_rxbrg_block2_sub .diabetes_rxbrg_block2_sub_inn p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
    }

    .diabetes_rxbrg_txt h2 {
        font-size: 34px;
        line-height: 40px;

    }

    .rxbrg_bnnr_arrow h1 {
        font-size: 22px;
        line-height: 28px;
    }

    .rxbrg_bnnr_form_hdr h2 {
        font-size: 23px;
        line-height: 29px;
    }

    .diabetes_rxbrg_green h1 {
        font-size: 21px;
        line-height: 26px;
    }

    .diabetes_rxbrg_green {
        padding: 15px;
    }
}

/* ----------------------------bnnr--responsive=================== */







/* -----------------------------------scroll to top------------------------------- */



/* .icon-style svg path{color: #fff;} */
.icon-style:hover {
    animation: none;
    /* background: #018fdf; */
    color: #fff;
    /* border: 2px solid #fff; */
}


/* .contactus_contact_us_input_field__BuGYr .MuiOutlinedInput-root {
    position: unset;
  } */

@keyframes movebtn {
    0% {
        transform: translateY(0px);
        background: #018fdf;
        /* color: #bdc9d5; */
        /* border: 2px solid #fff; */
    }

    25% {
        transform: translateY(20px);
        box-shadow: 0 0 18px #ffffff86;
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-20px);
        background: #018fdf;
        /* border: 2px solid #fff; */
        /* color: #bdc9d5; */
    }

    100% {
        transform: translateY(0px);
    }
}