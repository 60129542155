

@font-face {
   font-family: "avenir-medium";
   src:url(../font/Avenir\ Medium.ttf);
   }
   

/* {/*################*############## light theme ################################} */
.lighttheme .intake_form{
    width: 1400px;
    margin: 20px auto;
    background: #f3f3f3;
    padding: 16px 10px 50px;
    border: 1px solid #91e7ff;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.lighttheme .intake_form .heading{
    font-family: avenir-medium;
    text-transform: uppercase;
    font-size: 24px;
    padding: 0 6px;
    color: #1dbce9;
}

.lighttheme .intake_form .heading .bannerimg{
   width: 100%;
}

.lighttheme .intake_form .patientIntakeFormheder{
    transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    background: #fff;
    color: rgba(0,0,0,.87);
}


.lighttheme .intake_form .patientIntakeFormheder{
    transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    background: #fff;
    color: rgba(0,0,0,.87);
}

.lighttheme .intake_form .patientIntakeFormheder .First-form{
   display: flex;
   flex-wrap: wrap;
}

.lighttheme .intake_form .patientIntakeFormheder .formFeild{
    border-radius: 4px 4px 0 0;
    background: #fff;
    border: none;
    transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
    width: 440px;
    margin: 5px;
    height: auto; 
}

.lighttheme .intake_form .patientIntakeFormheder .formFeild::placeholder{ 
    margin: 25px;
}


.lighttheme .intake_form .patientIntakeFormheder .form{
   display: flex;
   flex-wrap: wrap;
   justify-content: start;

}

.lighttheme .intake_form .patientIntakeFormheder .insurance-info {
    margin: 10px 0;
 }

 .lighttheme .intake_form .patientIntakeFormheder .insurance-info h3{
    font-family: "AvenirMedium";
    margin: 0;
    font-size: 20px;
    color: #0ac3a2;
    text-transform: uppercase;
 
 }

 .lighttheme .intake_form .patientIntakeFormheder .insurance-info h3 .bold{
   font-weight: bold;
   background:#12a0e5;
   padding: 3px;

}

 .lighttheme .intake_form .patientIntakeFormheder .practice-info {
    margin: 10px 0;
 }

 .lighttheme .intake_form .patientIntakeFormheder .practice-info h3{
    font-family: "AvenirMedium";
    margin: 0;
    font-size: 20px;
    color: #0ac3a2;
    text-transform: uppercase;
 
 }

 .lighttheme .intake_form .patientIntakeFormheder .appointment-sched {
    margin: 10px 0;
 }

 .lighttheme  .intake_form .patientIntakeFormheder .appointment-sched h3{
    font-family: "AvenirMedium";
    margin: 0;
    font-size: 20px;
    color: #0ac3a2;
    text-transform: uppercase;
 
 }

 .lighttheme .intake_form .symptom-section  {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
 }

 .lighttheme .intake_form .symptom-section .symptom-blocks {
    width: 49%;
    border: 1px solid #dadada;
    margin: .5%;
    box-sizing: border-box;
    padding: 10px;
 }

 .lighttheme .intake_form .symptom-section .symptom-blocks .heading {
    font-family: "AvenirMedium";
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    background: linear-gradient(45deg, #12a0e5, #64ceff);
    padding: 1px 14px;
 }

 .lighttheme .intake_form .symptom-section .symptom-blocks .heading h3{
   margin: 10px;
}

.lighttheme .intake_form .symptom-section .symptom-blocks .fields{
    margin: 10px;
    display: flex;
    justify-content: space-between;
}

.lighttheme .intake_form .symptom-section  .authNsign{
   width: 100%;
   border: 1px solid #dadada;
   margin: .5%;
   box-sizing: border-box;
   padding: 10px;
}

.lighttheme .intake_form .symptom-section  .authNsign .heading{
   font-family: avenir-medium;
   text-transform: uppercase;
   font-size: 24px;
   text-align: center;
   padding: 0 6px;
   background-color: #1dbce9;
   color: white;
}

.lighttheme .intake_form .symptom-section .buttonSection{
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: end;
}

.lighttheme .intake_form .symptom-section .buttonSection button:nth-child(1) {
   background: linear-gradient(45deg, #0676fe, #05a2f7);
   margin: 5px;
}

.lighttheme .intake_form .symptom-section .buttonSection button:nth-child(2) {
   background: linear-gradient(65deg, #0676fe, #05a2f7);
   margin: 5px;

}


/* {/*#############/*############################### */








/* {/*############################ Dark Theme ################}*/
.darktheme{
   background: #181818;
   margin: 0;
   padding: 20px;
}

.darktheme .intake_form{
   width: 1400px;
   margin: 20px auto;
   background: #181818;
   padding: 16px 10px 50px;
   border: 1px solid #3d3d3d;
   position: relative;
   border-radius: 4px;
   box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.darktheme .intake_form .heading{
   font-family: avenir-medium;
   text-transform: uppercase;
   font-size: 24px;
   padding: 0 6px;
   color: #181818;
}

.darktheme .intake_form .heading .bannerimg{
  width: 100%;
}

.darktheme .intake_form .patientIntakeFormheder{
   transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
   display: block;
   position: relative;
   padding: 16px;
   border-radius: 4px;
   background: rgb(52, 52, 52);
   color: rgba(255, 255, 255, 0.87);
}


.darktheme .intake_form .patientIntakeFormheder{
   transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
   display: block;
   position: relative;
   padding: 16px;
   border-radius: 4px;
   background: rgb(55, 55, 55);
   color: rgba(255, 255, 255, 0.87);
}

.darktheme .intake_form .patientIntakeFormheder .First-form{
  display: flex;
  flex-wrap: wrap;
}

.darktheme .intake_form .patientIntakeFormheder .formFeild{
   border-radius: 4px 4px 0 0;
   border: 1px solid #7c7c7c;;
   transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
   width: 440px;
   margin: 5px;
   height: auto;  border-radius: 0px!important;
}

.darktheme .intake_form .patientIntakeFormheder .formFeild label{
   color: white;
}

.darktheme .intake_form .patientIntakeFormheder .formFeild::placeholder{ 
   margin: 25px;
}


.lighttheme .intake_form .patientIntakeFormheder .form{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

}

.darktheme .intake_form .patientIntakeFormheder .insurance-info {
   margin: 10px 0;
}

.darktheme .intake_form .patientIntakeFormheder .insurance-info h3{
   font-family: avenir-medium;
   margin: 0;
   font-size: 20px;
   color: #0ac3a2;
   text-transform: uppercase;

}

.lighttheme .intake_form .patientIntakeFormheder .insurance-info  span{
   font-weight: bold;
   color: white;
   padding: 3px;
   border: 2px solid white;

}

.lighttheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field{
   display: flex;
}

.lighttheme  .intake_form .otherDetails-blocks  {
   width: 100%;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   margin: 10px 0;
}

.lighttheme .intake_form .otherDetails-blocks .heading{
   width: 100%;
   margin: 0px;
   padding: 0px;
}

.lighttheme .intake_form .otherDetails-blocks .heading h3{
   font-size: 20px;
    margin: 0px;
}


.lighttheme .intake_form .otherDetails-blocks .fields{
   width: 100%;
   display: block;
   cursor: pointer;
}

.lighttheme .intake_form .otherDetails-blocks .form-check-input{
   cursor: pointer;
}


.lighttheme .intake_form .symptom-section .TermsNConditions{
   width: 100%;
   border: 1px solid #505050;
   margin: .5%;
   box-sizing: border-box;
   padding: 10px;
   color: rgb(0, 0, 0);
   display: flex;
}

.lighttheme .intake_form .symptom-section .TermsNConditions .heading{
  margin: 0px;
}


.lighttheme .intake_form .symptom-section .TermsNConditions .heading h3{ 
  color: rgb(2, 2, 2);
  margin: 0px;
 }

 .lighttheme .intake_form .symptom-section .TermsNConditions .termsncondition .MuiFormControlLabel-root{
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
 }

 .lighttheme .intake_form .symptom-section .TermsNConditions .policy .MuiFormControlLabel-root{
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
 }

/* ------------------------ Dark Theme ---------------------------- */


.darktheme .intake_form .patientIntakeFormheder .insurance-info .insurance-field{
   display: flex;
}

.darktheme .intake_form .patientIntakeFormheder .practice-info {
   margin: 10px 0;
}

.darktheme .intake_form .patientIntakeFormheder .practice-info h3{
   /* font-family: avenir-medium; */
   margin: 0;
   font-size: 20px;
   color: #0ac3a2;
   text-transform: uppercase;

}

.darktheme .intake_form .patientIntakeFormheder .appointment-sched {
   margin: 10px 0;
}

.darktheme  .intake_form .patientIntakeFormheder .appointment-sched h3{
   /* font-family: avenir-medium; */
   margin: 0;
   font-size: 20px;
   color: #0ac3a2;
   text-transform: uppercase;

}

.darktheme  .intake_form .otherDetails-blocks  {
   width: 100%;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   padding: 5px;
   margin: 10px 0;
}

.darktheme .intake_form .otherDetails-blocks .heading{
   width: 100%;
   margin: 0px;
   padding: 0px;
}

.darktheme .intake_form .otherDetails-blocks .heading h3{
   color: white;
   font-size: 20px;
    margin: 0px;
}


.darktheme .intake_form .otherDetails-blocks .fields{
   width: 100%;
   display: block;
   cursor: pointer;
}

.darktheme .intake_form .otherDetails-blocks .form-check-input{
   cursor: pointer;
}



.darktheme .intake_form .symptom-section  {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
}

.darktheme .intake_form .symptom-section .symptom-blocks {
   width: 49%;
   border: 1px solid #505050;
   margin: .5%;
   box-sizing: border-box;
   padding: 10px;
}

.darktheme .intake_form .symptom-section .form-check-input{
   cursor: pointer;
}

.darktheme .intake_form .symptom-section .symptom-blocks .heading {
   font-family: "AvenirMedium";
   font-size: 18px;
   text-transform: uppercase;
   color: #fff;
   background: #181818;
   padding: 1px 14px;
}

.darktheme .intake_form .symptom-section .symptom-blocks .heading h3{
  font-family: avenir-medium;
  margin: 10px;
}

.darktheme .intake_form .symptom-section .symptom-blocks .fields{
   margin: 10px;
   display: flex;
   justify-content: space-between;
}

.darktheme .intake_form .symptom-section  .authNsign{
  width: 100%;
  border: 1px solid #dadada;
  margin: .5%;
  box-sizing: border-box;
  padding: 10px;
}

.darktheme .intake_form .symptom-section  .authNsign .heading{
  font-family: avenir-medium;
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  padding: 5px 6px;
  background-color: #181818;
  color: white;
}

.darktheme .intake_form .symptom-section  .authNsign .heading h3{
   margin: 0;
 }


.darktheme .intake_form .First-form .MuiInputBase-formControl{
   background: #373737;
   color: white; border-radius: 0px!important;
}

.darktheme .intake_form .symptom-section .buttonSection{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.darktheme .intake_form .symptom-section .buttonSection button:nth-child(1) {
  background: linear-gradient(45deg, #0676fe, #05a2f7);
  margin: 5px;
}

.darktheme .intake_form .symptom-section .buttonSection button:nth-child(2) {
  background: linear-gradient(65deg, #0676fe, #05a2f7);
  margin: 5px;

}

.darktheme .intake_form .MuiInputLabel-shrink{
   font-size: 19px;
   background: transparent;
   color: rgb(255, 255, 255);
   top: 10px;
   
}

.darktheme .intake_form .symptom-section .TermsNConditions{
   width: 100%;
   border: 1px solid #505050;
   margin: .5%;
   box-sizing: border-box;
   padding: 10px;
   color: white;
   display: flex;
}

.darktheme .intake_form .symptom-section .TermsNConditions .heading{
  margin: 0px;
}


.darktheme .intake_form .symptom-section .TermsNConditions .heading h3{ 
  color: white;
  margin: 0px;
 }

 .darktheme .intake_form .symptom-section .TermsNConditions .termsncondition .MuiFormControlLabel-root{
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
 }

 .darktheme .intake_form .symptom-section .TermsNConditions .policy .MuiFormControlLabel-root{
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
 }

.modalPop{
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
 }

 .modalPop .MuiBackdrop-root{
   z-index: -1 !important;
}

 .modalPop .modalContent{
    background-color: white;
    margin: 25px!important;
    /* min-height: 300px;
    max-height: 300px; */
    max-width: 600px;
    /* overflow-y: scroll; */  
 }

.modalPop .modalContent p{
    margin: 0px !important;
 }

 .termscond{
    cursor: pointer;
    text-decoration: underline;
 }


/* {/*################**################/*################/*################**################**/


/* {/*############################# responsive {/*################# */


@media screen and (max-width:671px){

   .intake_form .patientIntakeFormheder  .MuiInputBase-formControl{
       font-size: 50px;
   }
   .intake_form .patientIntakeFormheder  .MuiInputBase-formControl::placeholder{
      font-size: 50px;
  }


    .intake_form .patientIntakeFormheder .First-form{
      display: flex;
      flex-direction: column;
   }

   .intake_form .patientIntakeFormheder .First-form .formFeild{
      width: 100%;
   }


   /* ----- insurance ----- */

   .darktheme .intake_form  .insurance-info  {
      font-size: 45px;
      /* margin: 48px 0 !important; */
   }

   .darktheme .intake_form .patientIntakeFormheder .insurance-info h3{
      font-size: 55px;
   }

   .darktheme .intake_form .insurance-info .insurance-field {
      display: flex;
      flex-direction: column;
   }

   .darktheme .intake_form  .insurance-info .formFeild {
      width: 100%;
   }

   /* ---------------------- */


   /* ------------- otherDetails-blocks --------- */


   .darktheme .intake_form .otherDetails-blocks {
      display: flex;
      flex-direction: column;
      font-size: 45px;
      padding: 12px;
   }

   .darktheme .intake_form .otherDetails-blocks input{
      height: 36px;
      width: 36px;
   }

   .darktheme .intake_form .otherDetails-blocks .heading h3{
      font-size: 55px;

   }

   /* ------------------------------------------- */

   /* -------- symptom ------- */

   .darktheme .intake_form .symptom-section {
      display: flex;
      flex-direction: column;
   }

   .darktheme .intake_form .symptom-section .symptom-blocks{
      width: 100%;
      font-size: 45px;
   }

   .darktheme .intake_form .symptom-section .symptom-blocks .heading{
      width: 100%;
      font-size: 45px;
   }

   .darktheme .intake_form .symptom-section .symptom-blocks .fields input{
      height: 36px;
      width: 36px;
   }

   /* ------------------------- */
}

.lighttheme .intake_form .patientIntakeFormheder   fieldset{ border: solid 1px #e0e0e0!important;}